import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import {
  customerEditData,
  verifyBankAccount,
  triggerOtp,
  VerifyOtp,
  customerVerify
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import userprofile from "../../../images/userprofile.png";

class Customerdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEditMode: false,
      name: "",
      natureOfBusinness: "",
      email: "",
      mobNo: "",
      addrs: "",
      state: "",
      pin: "",
      src: "",
      membSince: "",
      accNo: "",
      IFSC: "",
      OTP: "",
      trackNumber: 0,
      userName: "",
      bankName: "",
      bankBranch: "",
      micr: "",
      accountVerified: "",
      chequeImage: "",
      kycImage: "",
    };
  }
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  componentDidMount() {
    this.customerViewDataEdit();
  }
  customerViewDataEdit = () => {
    var queryParams = this.props.computedMatch.params.customerid;
    if (queryParams) {
      this.props.customerVerify(queryParams, () => {
        this.setState({
          id: this.props.customerviewData._id,
          name: this.props?.customerviewData?.fullName
            ? this.props?.customerviewData?.fullName
            : "",
          natureOfBusinness: this.props?.customerviewData?.natureOfBusiness
            ? this.props?.customerviewData?.natureOfBusiness
            : "",
          email: this.props.customerviewData.email
            ? this.props.customerviewData.email
            : "",
          mobNo: this.props.customerviewData.phone
            ? this.props.customerviewData.phone
            : "",
          addrs: this.props.customerviewData.address
            ? this.props.customerviewData.address
            : "",
          state: this.props.customerviewData.state
            ? this.props.customerviewData.state
            : "",
          pin: this.props.customerviewData.pincode
            ? this.props.customerviewData.pincode
            : "",
          src: this.props?.customerviewData?.userAccountSource,
          membSince: this.props.customerviewData.memberSince
            ? moment(this.props.customerviewData.memberSince).format(
                "DD/MM/YYYY"
              )
            : "",
          IFSC: this.props?.customerviewData?.bank?.ifsc
            ? this.props?.customerviewData?.bank?.ifsc
            : "",
          accNo: this.props?.customerviewData?.bank?.accountNo
            ? this.props?.customerviewData?.bank?.accountNo
            : "",
          userName: this.props?.customerviewData?.bank?.username
            ? this.props?.customerviewData?.bank?.username
            : "",
          bankName: this.props?.customerviewData?.bank?.bankName
            ? this.props?.customerviewData?.bank?.bankName
            : "",
          bankBranch: this.props?.customerviewData?.bank?.city
            ? this.props?.customerviewData?.bank?.city
            : "",
          micr: this.props?.customerviewData?.bank?.micr
            ? this.props?.customerviewData?.bank?.micr
            : "",
          accountVerified: this.props?.customerviewData?.bank
            ?.accountVerificationStatus
            ? this.props?.customerviewData?.bank?.accountVerificationStatus
            : "",
          chequeImage: this.props?.customerviewData?.cancelledCheque.length>0
            ? this.props?.customerviewData?.cancelledCheque[0]
            : "",
          kycImage: this.props?.customerviewData?.identityProof?.aadharCard.length>0
          ? this.props?.customerviewData?.identityProof?.aadharCard
          :this.props?.customerviewData?.identityProof?.panCard.length>0
          ?this.props?.customerviewData?.identityProof?.panCard
          :"",
        });
      });
    }
  };
  onChangeEdit(e) {
    const { name, mobNo, addrs, state, email } = this.state;
    e.preventDefault();
    let payload = {
      fullName: name,
      PhoneNo: mobNo,
      address: addrs,
      state: state,
      email: email,
    };
    this.props.customerEditData(
      payload,
      this.props.customerviewData._id,
      (res) => {
        if (res && res.message === "Customer has been successfully updated")
          this.props.history.push("/Customers");
      }
    );
  }
  componentDidUpdate() {
    if (
      this.props.customerviewData._id !== this.state.id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.customerViewDataEdit();
      });
    }
  }

  verify = () => {
    console.log("verify trigger");
    let data = {
      userid: this.state.id,
      phone: this.state.mobNo.toString(),
      otp: this.state.OTP,
    };
    this.props.VerifyOtp(data, (res) => {
      if (res.response.type === "success") {
        this.props.history.push("/VerificationSuccess");
        NotificationManager.success(res.response.message, "Verified");
      } else {
        NotificationManager.error("Incorrect OTP", "Error");
      }
    });
  };

  sendOtp = (e) => {
    e.preventDefault();
    let data = {
      userid: this.state.id,
    };
    this.props.triggerOtp(data, (res) => {
      if (res.response.type === "success") {
        NotificationManager.success(
          "OTP send on user registered phone",
          "Sent"
        );
      } else {
        NotificationManager.error("OTP was not sent", "Error");
      }
    });
  };

  render() {
    return (
      <div className="mainParentSectionDiv">
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Customer Information</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.name}
                      onChange={(e) => this.onChange("name", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={7} md={7} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Nature Of Business</Label>
                    <Input
                      id="nametwo"
                      name="nametwo"
                      type="text"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.natureOfBusinness}
                      onChange={(e) =>
                        this.onChange("natureOfBusinness", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.email}
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={7} md={7} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Mobile Number</Label>
                    <Input
                      id="mobileno"
                      name="mobileno"
                      type="number"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.mobNo}
                      onChange={(e) => this.onChange("mobNo", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <br />
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Basic Information</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={12} md={12} className="mb-15">
                  <FormGroup>
                    <Label for="address">Address</Label>
                    <Input
                      id="address"
                      name="address"
                      type="text"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.addrs}
                      onChange={(e) => this.onChange("addrs", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input
                      id="state"
                      name="state"
                      type="text"
                      value={this.state.state}
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      onChange={(e) => this.onChange("state", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Pin</Label>
                    <Input
                      id="pin"
                      name="pin"
                      type="number"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.pin}
                      onChange={(e) => this.onChange("pin", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="source">Source</Label>
                    <Input
                      id="source"
                      name="source"
                      type="text"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.src}
                      onChange={(e) => this.onChange("src", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="member">Member Since</Label>
                    <Input
                      id="member"
                      name="member"
                      type="text"
                      disabled={
                        this.state.isEditMode !== "false" ? true : false
                      }
                      value={this.state.membSince}
                      onChange={(e) =>
                        this.onChange("membSince", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="pd-15 bg-white borderedDiv mt-3">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Account Information</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={7} md={7}>
                  <Row>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="accno">
                          Account Number<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="accno"
                          name="accno"
                          placeholder="Bank Acc no."
                          type="number"
                          disabled={
                            this.state.isEditMode !== "false" ? true : false
                          }
                          value={this.state.accNo}
                          onChange={(e) =>
                            this.onChange("accNo", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="accholdername">
                          IFSC Code<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="accholdername"
                          name="accholdername"
                          placeholder="IFSC Code"
                          type="text"
                          disabled={
                            this.state.isEditMode !== "false" ? true : false
                          }
                          value={this.state.IFSC}
                          onChange={(e) =>
                            this.onChange("IFSC", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} className="mb-15">
                      {this.state.accountVerified === "Verified" ? (
                        <>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              User Name :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.userName}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              Bank Name :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.bankName}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              Bank Branch :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.bankBranch}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              MICR :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.micr}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              Status :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.accountVerified}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                      <Col xs={12} sm={6} md={6} className="mb-15">
                        <FormGroup>
                          <Label for="OTP">
                            Enter OTP<span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="OTP"
                            name="OTP"
                            placeholder="OTP"
                            type="text"
                            value={this.state.OTP}
                            onChange={(e) =>
                              this.onChange("OTP", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="mt-4">
                        <Button onClick={this.sendOtp} className="mt-2">
                          Send OTP
                        </Button>
                      </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Button
                        className="btn lightBtn"
                        disabled={!this.state.OTP}
                        onClick={() => this.verify()}
                      >
                        Verify
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={5} md={5}>
                  <div>Cancelled Cheque preview </div>

                  <div className="custCancelledChequeImageDiv">
                    <img
                      style={{ overflow: "hidden" }}
                      src={this.state.chequeImage}
                      alt="Cancelled cheque"
                    />
                  </div>
                  <br />
                  <div>
                    <div>Kyc Display</div>
                    <div className="custCancelledChequeImageDiv">
                      <img
                        style={{ overflow: "hidden" }}
                        src={this.state.kycImage[0]}
                        alt="KYC Display"
                      />
                    </div>{" "}
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerviewData: state.rfc.customerVerifys,
  };
};
export default connect(mapStateToProps, {
  customerEditData,
  verifyBankAccount,
  triggerOtp,
  VerifyOtp,
  customerVerify,
})(Customerdetails);
