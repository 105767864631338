import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label } from "reactstrap";
import TableProfilePic from "../../../images/tableprofilepic.png";
import userprofile from "../../../images/userprofile.png";
import { connect } from "react-redux";
import { getCategoryList, categoryDeleteData } from "../../../store/actions";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";

class CategoryMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryToDeleteId: "",
      deleteConfirmModal: false,
      limit: 10,
      currentPage: 1,
    };
  }
  componentDidMount() {
    this.getAllCategoryData();
  }
  getAllCategoryData = (offset = 0) => {
    this.props.getCategoryList(offset, this.state.limit);
  };
  onCategoryDelete(id) {
    this.props.categoryDeleteData(id, (res) => {
      if (res) {
        if (res && res.message === "Category has been deleted") {
          this.getAllCategoryData(
            (this.state.currentPage - 1) * this.state.limit
          );
          NotificationManager.success(res.message, "Delete Successful");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      }
    });
  }
  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };
  handleChangePage = (name, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.getAllCategoryData(offset);
  };
  render() {
    const totPages = Math.ceil(
      this.props.categoryDataList.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>Sr.No</span>,
          field: "srNo",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Category Name</span>,
          field: "categoryName",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Date</span>,
          field: "date",
          sort: "asc",
          width: 300,
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props.categoryDataList.docs &&
      this.props.categoryDataList.docs.length > 0
    ) {
      this.props.categoryDataList.docs.forEach((ele, index) => {
        data.rows.push({
          srNo: index + 1,
          categoryName: (
            <div className="d-flex align-items-center">
              <img src={userprofile}></img>&nbsp;&nbsp;&nbsp;
              <p className="mb-0">{ele.categoryName}</p>
            </div>
          ),
          date: <div>{moment(ele.createdAt).format("DD/MM/YYYY")}</div>,
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link to={`/CategoryInfo/${ele.categoryId}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/CategoryInfo/${ele.categoryId}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              <Button
                className="btn lightBtn"
                onClick={() => {
                  this.setState({ categoryToDeleteId: ele.categoryId }, () => {
                    this.deleteConfirmModalToggle();
                  });
                }}
              >
                Delete
              </Button>
            </div>
          ),
        });
      });
    }
    return (
      <div className="dashInnerMainDiv custCreationDivSec sosDiv">
        <Modal
          isOpen={this.state.deleteConfirmModal}
          toggle={this.deleteConfirmModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.deleteConfirmModalToggle}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want to delete the Category?</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                this.onCategoryDelete(this.state.categoryToDeleteId);
                this.deleteConfirmModalToggle();
              }}
            >
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.deleteConfirmModalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Category List</h4>
            <div>
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      this.setState(
                        {
                          limit: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.getAllCategoryData();
                        }
                      );
                    }}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
            displayEntries={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props.categoryDataList?.length != 0
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props.categoryDataList.docs?.length
                  ? this.props.categoryDataList.docs.length
                  : "")}{" "}
              {} entries of {this.props.categoryDataList?.length}
            </p>
          </div>
          <div className="custPaginationDiv">
            <Pagination
              shape="rounded"
              count={totPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    categoryDataList: state.rfs.categoryList,
  };
};
export default connect(mapStateToProps, {
  getCategoryList,
  categoryDeleteData,
})(CategoryMain);
