import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { fetchproductSortData ,orderUpdate,} from "../../../store/actions";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

 class AddProducts extends Component {
  constructor(props) {
    super(props);
    this.state={
        currentPage:1,
        limit:5,
       
    }
  }

  componentDidMount() {
    this.listOfProductList();
  }
  listOfProductList = (offset = 0) => {
    this.props.fetchproductSortData(
      offset,
      this.state.limit,
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.listOfProductList(offset);
  };
  addProducts=(id,quantity)=>{
    let orderActions = "adminUpdatesOrder";
    let data = {
      addedProducts: [
          {
            productId:id,
            quantity: quantity
        }
      ],
      deletedProducts: [
      ],
      modifiedProducts: [],
    };
    this.props.orderUpdate(data, this.props.orderId, orderActions, (res) => {
        if (res && res.message === "Order has been updated") {
          NotificationManager.success(res.message, "Order Updated");
          this.props.OrderInfo()
          this.props.onToggle()
        } else {
          NotificationManager.error(res.message, "Error");
        }
    });
  }
 
  render() {
    const totPages = Math.ceil(
        this.props?.listOfProductData?.length / this.state.limit
      );
    var data = {};
    data = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Catergory</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Size/Count</span>,
          field: "count",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Rating</span>,
          field: "rating",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Date</span>,
          field: "date",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props.listOfProductData.docs &&
      this.props.listOfProductData.docs.length > 0
    ) {
      this.props?.listOfProductData?.docs.forEach((ele) => {
        data.rows.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={ele?.images[0] ? ele?.images[0] : ele?.sku?.skuImage[0]}
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "N/A"}
              </p>
            </div>
          ),
          category: ele.category?.categoryName
            ? ele.category?.categoryName
            : "N/A",
          count: ele.size,
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;<span>{ele.rate?ele.rate:""}</span>
            </div>
          ),
          rating:<p>
          <div className={` ratingStartDiv ratingStarDiv${ele.rating}`}>
            <svg
              width="13"
              height="12"
              viewBox="0 0 19 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
            </svg>{" "}
            &nbsp;
            <svg
              width="13"
              height="12"
              viewBox="0 0 19 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
            </svg>
            &nbsp;
            <svg
              width="13"
              height="12"
              viewBox="0 0 19 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
            </svg>
            &nbsp;
            <svg
              width="13"
              height="12"
              viewBox="0 0 19 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
            </svg>
            &nbsp;
            <svg
              width="13"
              height="12"
              viewBox="0 0 19 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
            </svg>
            &nbsp;
            <span className="fw-400 fs-12">
              {" "}
             ({ele.rating?ele.rating:""})
            </span>
          </div>
        </p>
        ,
          moq: ele.moq ? ele.moq : "N/A",
          date: ele.deliveryDate ? (
            <div>{moment(ele.deliveryDate).format("DD/MM/YYYY")}</div>
          ) : (
            "N/A"
          ),
          actions: (
            <div className="d-flex align-items-center tableBtns ">
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                   this.addProducts(ele._id,ele.availableQuantity)
                  }}
                >
                  Add
                </Button>
              </Link>
              &nbsp;&nbsp;
            </div>
          ),
        });
      });
    }
    return (
      <div  className="mainParentSectionDiv">
        <div className="addProductModal">
        <Modal
        isOpen={this.props.orderModal}
        toggle={this.props.onToggle}
        className={"addProductModalInner"}
        size="md"
      >
        <ModalHeader toggle={this.props.onToggle}>
          Add Product
        </ModalHeader>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props?.listOfProductData?.length != 0
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props?.listOfProductData?.docs?.length
                  ? this.props?.listOfProductData?.docs?.length
                  : "")}{" "}
              { } entries of {this.props.listOfProductData?.length}
            </p>
          </div>
          <div className="custPaginationDiv">
            <Pagination
              shape="rounded"
              count={totPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </div>
      </Modal>
      </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
    return {
      listOfProductData: state.rfu.productdata,
    };
  };
export default connect(mapStateToProps, {
    fetchproductSortData,orderUpdate
  })(AddProducts);