import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
import {FormGroup, Input, Button, Form, Label,Col,Row } from "reactstrap";
import { Link } from "react-router-dom";
import TableProfilePic from "../../../images/tableprofilepic.png";

export default class AdminMain extends Component {
    render() {
        var data = {};
        data = {
          columns: [
            {
                label: <span>ID</span>,
                field: "id",
                sort: "asc",
                width: 300,
              },
            {
              label: <span>Name</span>,
              field: "name",
              sort: "asc",
              width: 300,
            },
            {
                label: <span>Email ID</span>,
                field: "emailid",
                sort: "asc",
                width: 300,
              },
            {
              label: <span>Contact</span>,
              field: "contact",
              sort: "asc",
              width: 300,
            },
            {
              label: <span>Status</span>,
              field: "status",
              sort: "asc",
              width: 50,
            },
            {
              label: "Actions",
              field: "actions",
              sort: "asc",
              width: 100,
            },
          ],
          rows: [
            {id:'#2654',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,emailid:'abcd@gmail.com',contact:'+91 73678 63456',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Link to={"/AdminuserInfo"}><Button className="btn lightBtn">View</Button></Link>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
            {id:'#2654',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,emailid:'abcd@gmail.com',contact:'+91 73678 63456',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Link to={"/AdminuserInfo"}><Button className="btn lightBtn">View</Button></Link>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
            {id:'#2654',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,emailid:'abcd@gmail.com',contact:'+91 73678 63456',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Link to={"/AdminuserInfo"}><Button className="btn lightBtn">View</Button></Link>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
            {id:'#2654',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,emailid:'abcd@gmail.com',contact:'+91 73678 63456',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Link to={"/AdminuserInfo"}><Button className="btn lightBtn">View</Button></Link>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
            {id:'#2654',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,emailid:'abcd@gmail.com',contact:'+91 73678 63456',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Link to={"/AdminuserInfo"}><Button className="btn lightBtn">View</Button></Link>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>}
          ],
        };
        return (
            <div className="mainParentSectionDiv">
                <div className="dashInnerMainDiv custCreationDivSec sosDiv">
                    <div>
                        <div className="d-flex align-items-center justify-content-between w-100 dashInnerHeadDiv">
                            <h4>Create New Admin User</h4>
                        </div>
                        <div>
                        <Row>
                            <Col xs={12} sm={12} md={12} className="pd-15">
                            <div className="formDivCss">
                                <Form>
                                <Row>
                                    <Col xs={12} sm={2} md={2} className="mb-15">
                                    <FormGroup>
                                        <Label for="empid">Employee ID</Label>
                                        <Input
                                        id="empid"
                                        name="empid"
                                        placeholder="Type..."
                                        type="number"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={5} md={5} className="mb-15">
                                    <FormGroup>
                                        <Label for="name">Admin user name<span>*</span></Label>
                                        <Input
                                        id="name"
                                        name="name"
                                        placeholder="Type..."
                                        type="text"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={5} md={5} className="mb-15">
                                    <FormGroup>
                                        <Label for="dept">Department<span>*</span></Label>
                                        <Input
                                        id="name"
                                        name="name"
                                        placeholder="Type..."
                                        type="text"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={5} md={5} className="mb-15">
                                    <FormGroup>
                                        <Label for="conno">Contact no<span>*</span></Label>
                                        <Input
                                        id="conno"
                                        name="conno"
                                        placeholder="Type..."
                                        type="number"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={5} md={5} className="mb-15">
                                    <FormGroup>
                                        <Label for="emailid">Email ID<span>*</span></Label>
                                        <Input
                                        id="emailid"
                                        name="emailid"
                                        placeholder="Type..."
                                        type="text"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={2} md={2} className="mb-15 d-flex align-items-center justify-content-between">
                                        <div className="pdt-15">
                                            <button className="btn darkBtn">Verify</button>
                                        </div>
                                    </Col>
                                </Row>
                                </Form>
                            </div>
                            </Col>
                        </Row>
                        </div>
                    </div>
            <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <div className="d-flex align-items-center justify-content-between w-100">
                <h4>Admin Users</h4>
                <div>
                <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                    <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                    <Input
                        name="search"
                        placeholder="search placeholder"
                        type="search"
                        className="mb-0"
                    />
                    <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                        stroke="#5E5E5E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                    </svg>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center tableHeaderSelectDiv">
                    <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                    <Input id="exampleSelect" name="select" type="select">
                        <option>Latest</option>
                        <option>option2</option>
                        <option>option3</option>
                        <option>option4</option>
                        <option>option5</option>
                    </Input>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                    <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                    <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                    >
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                    </Input>
                    </FormGroup>
                </Form>
                </div>
            </div>
            </div>
            <div className="dataTableStyles">
            <MDBDataTable
                className="text-center text-capitalize"
                responsive
                hover
                data={data}
                paging={false}
                searching={false}
            />
            </div>
                </div>
            </div>
        )
    }
}
