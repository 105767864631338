import React, { Component } from 'react'
import {FormGroup, Input,Form, Label,Col,Row } from "reactstrap";
import userProfileImage from "../../../images/userProfileOne.png";
import { Link } from "react-router-dom";

export default class AdminuserInfo extends Component {
    render() {
        return (
            <div>
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>User Information</h4>
            <div>&nbsp;</div>
          </div>
          <Row>
            <Col xs={12} sm={3} md={3} className="pd-15 borderRight">
              <div className="d-flex justify-content-center">
                <img src={userProfileImage} alt="User Profile Image" />
              </div>
            </Col>
            <Col xs={12} sm={9} md={9} className="pd-15">
              <div className="formDivCss">
                <Form>
                <Row>
                            <Col xs={12} sm={12} md={12} className="pd-15">
                            <div className="formDivCss">
                                <Form>
                                <Row>
                                    <Col xs={12} sm={2} md={2} className="mb-15">
                                    <FormGroup>
                                        <Label for="empid">Employee ID</Label>
                                        <Input
                                        id="empid"
                                        name="empid"
                                        placeholder="Type..."
                                        type="number"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={5} md={5} className="mb-15">
                                    <FormGroup>
                                        <Label for="name">Admin user name</Label>
                                        <Input
                                        id="name"
                                        name="name"
                                        placeholder="Type..."
                                        type="text"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={5} md={5} className="mb-15">
                                    <FormGroup>
                                        <Label for="dept">Department</Label>
                                        <Input
                                        id="name"
                                        name="name"
                                        placeholder="Type..."
                                        type="text"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} className="mb-15">
                                    <FormGroup>
                                        <Label for="conno">Mobile Number</Label>
                                        <Input
                                        id="conno"
                                        name="conno"
                                        placeholder="Type..."
                                        type="number"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} className="mb-15">
                                    <FormGroup>
                                        <Label for="emailid">Email ID</Label>
                                        <Input
                                        id="emailid"
                                        name="emailid"
                                        placeholder="Type..."
                                        type="text"
                                        />
                                    </FormGroup>
                                    </Col>
                                </Row>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <button
                                        className="btn lightBtn"
                                    >
                                        Edit
                                    </button>
                                    &nbsp;&nbsp;
                                    <button className="btn lightBtn">Deactivate</button>
                                    &nbsp;&nbsp;
                                    <button className="btn lightBtn">Delete</button>
                                    </div>
                                    <Link to={"/AdminMain"}><button
                                    className="btn darkBtn">
                                    Back
                                    </button></Link>
                                    
                                </div>
                                </Form>
                            </div>
                            </Col>
                        </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
        )
    }
}
