import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, Row, Col } from "reactstrap";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import Producticon from "../../../images/productCategoryicon.png";
import Suppliericon from "../../../images/supplierIconTwo.png";
import Logisticsicon from "../../../images/logisticsIconThree.png";
import AccordionComponent from "./AccordionComponent";
import SelectComponent from "./SelectComponent";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import userprofile from "../../../images/userprofile.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  fetchproductSortData,
  fetchProductFilterSkuData,
  fetchCategoryData,
  fetchSellerData,
  fetchProductAllFilterData,
  Product_addCategory,
  Product_addSku,
  GetSkusList,
  productDeleteData,
  productEditData,
  fetchProductViewData,
  categoryBySku,
} from "../../../store/actions";
import moment from "moment";
import { Link } from "react-router-dom";
import Addbuyerpic from "../../../images/addbuyerpic.png";
import Pagination from "@material-ui/lab/Pagination";
import defaultfish from "../../../images/fish-57.png";
class ProductMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AccordionCollapse: false,
      toggleDiv: false,
      toggleCatDiv: false,
      toggleSkuDiv: false,
      categories: [],
      Seller: [],
      Size: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
      ],
      skucategories: [],
      sku: [],
      category: "",
      seller: "",
      size: "",
      minPrice: "",
      maxPrice: "",
      SelectedSku: "",
      addProduct_category: "",
      addProduct_categoryImg: undefined,
      // addProduct_categoryImg_name: "",
      categoryDisplayImg: undefined,
      addProduct_skuName: "",
      // addProduct_skuName_img_name: "",
      skuDisplayImg: undefined,
      addProduct_categoryType: "",
      addProduct_skuImg: undefined,
      deleteConfirmModal: false,
      editModal: false,
      currentPage: 1,
      limit: 10,
      productToDeleteId: "",
      productToEditId: "",
      totalQuantity: "",
      deliveryDate: "",
    };
  }

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
    });
  };
  toggleAddCatergory = () => {
    this.setState({
      toggleCatDiv: !this.state.toggleCatDiv,
      toggleSkuDiv: false,
    });
  };
  toggleAddSKU = () => {
    this.setState({
      toggleSkuDiv: !this.state.toggleSkuDiv,
      toggleCatDiv: false,
    });
  };

  onFilterData(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  filterProductData() {
    this.setState({
      toggleDiv: false,
    });
    this.listOfProductList();
  }

  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };
  editModalToggle = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };
  handleChange = () => {
    this.setState({
      toggleCatDiv: false,
      toggleSkuDiv: false,
      toggleDiv: false,
    });
  };

  addCategoryData = () => {
    let formData = new FormData();
    formData.append("categoryName", this.state.addProduct_category);

    let allFiles = Object.values(this.state.addProduct_categoryImg);
    for (let x of allFiles) {
      formData.append("file", x);
    }

    this.props.Product_addCategory(formData, (res) => {
      if (res.message === "Category has been created successfully") {
        NotificationManager.success(res.message, "Category Created");

        this.setState({
          addProduct_categoryImg: undefined,
          // addProduct_categoryImg_name: "",
          categoryDisplayImg: undefined,
          addProduct_category: "",
        });
        document.getElementById("namecategory").value = "";
      } else {
        NotificationManager.error(res.message, "Error");
      }
      this.toggleAddCatergory();
    });
  };
  addSkuData = () => {
    let formData = new FormData();
    formData.append("skuName", this.state.addProduct_skuName);
    formData.append("categoryId", this.state.addProduct_categoryType.value);
    // formData.append("skuimage", this.state.addProduct_skuImg)

    let allFiles = Object.values(this.state.addProduct_skuImg);
    for (let x of allFiles) {
      formData.append("file", x);
    }
    this.props.Product_addSku(formData, (res) => {
      if (res.message === "SKU has been created successfully") {
        console.log("inside successs");
        NotificationManager.success(res.message, "SKU Created");
        this.setState({
          addProduct_skuName: "",
          addProduct_categoryType: "",
          addProduct_skuImg: undefined,
          skuDisplayImg: undefined,
        });

        document.getElementById("namesku").value = "";
      } else {
        console.log("inside error");
        NotificationManager.error(res.message, "Error");
      }
      this.toggleAddSKU();
    });
  };
  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleChange);
  }
  componentDidMount() {
    this.listOfProductList();
    this.listOfSellerAndCategory();
  }
  componentDidUpdate = () => {
    let categoryOption = [];
    if (!this.state.skucategories.length) {
      if (this.props.categoryData.docs?.length) {
        this.props.categoryData.docs.forEach((item) => {
          categoryOption.push({ label: item.categoryName, value: item._id });
        });
        this.setState({
          skucategories: categoryOption,
        });
      }
    }
  };

  listOfProductList = (offset = 0) => {
    this.props.fetchproductSortData(
      offset,
      this.state.limit,
      this.state.maxPrice,
      this.state.minPrice,
      this.state.category.value ? this.state.category.value : "",
      this.state.size.value ? this.state.size.value : "",
      this.state.seller.value ? this.state.seller.value : "",
      this.state.SelectedSku.value ? this.state.SelectedSku.value : ""
    );
  };
  listOfSellerAndCategory = () => {
    this.props.fetchCategoryData((res) => {
      if (res.docs && res.docs.length > 0) {
        this.setState({
          categories: res.docs.map((ele) => {
            return { label: ele.categoryName, value: ele._id };
          }),
        });
      }
    });
    this.props.fetchSellerData((res) => {
      if (res.sellerList && res.sellerList.length > 0) {
        this.setState({
          Seller: res.sellerList.map((ele) => {
            return { label: ele.fullName, value: ele._id };
          }),
        });
      }
    });
  };
  productDeleteData(id) {
    this.props.productDeleteData(id, (res) => {
      if (res && res.message === "Product has been deleted") {
        this.listOfProductList((this.state.currentPage - 1) * this.state.limit);
        NotificationManager.success(res.message, "Delete Successful");
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  }
  productRequestEditData() {
    // let deliveryDate = {
    //   from: moment(this.state.deliveryDate[0]).format(
    //     "YYYY-MM-DD"
    //   ),
    //   to: moment(this.state.deliveryDate[1]).format("YYYY-MM-DD"),
    // };
    let deliveryDate = moment(this.state.deliveryDate).format("YYYY/MM/DD");

    let payload = {
      availableQuantity: this.state.totalQuantity,
      rate: this.state.price,
      deliveryDate: deliveryDate,
    };
    this.props.productEditData(
      payload,
      this.props.productViewData._id,
      (res) => {
        console.log(res);
        if (res && res.message === "Product has been successfully updated") {
          NotificationManager.success(res.message, "Product Updated!!");
          this.listOfProductList(
            (this.state.currentPage - 1) * this.state.limit
          );
          this.props.history.push("/product");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      }
    );
    this.editModalToggle();
  }
  productInfo = (productId) => {
    this.setState({
      isEditMode: true,
    });
    console.log(productId, "productId");
    if (productId) {
      this.props.fetchProductViewData(productId, (res) => {
        this.setState(
          {
            id: this.props.productViewData._id,
            totalQuantity: this.props.productViewData.availableQuantity,
            price: this.props.productViewData.rate,
            //deliveryDate: moment(this.props.productViewData.availableDeliveryDate.to).format("DD-MM-YYYY"),
            // deliveryDate: this.props.productViewData.availableDeliveryDate
            //   ? [
            //     moment(
            //       this.props.productViewData.availableDeliveryDate.from
            //     ).format("YYYY-MM-DD"),
            //     moment(
            //       this.props.productViewData.availableDeliveryDate.to
            //     ).format("YYYY-MM-DD"),
            //   ]
            //   : moment(),
            deliveryDate: this.props.productViewData.deliveryDate
              ? moment(this.props.productViewData.deliveryDate).format(
                  "YYYY-MM-DD"
                )
              : moment(),
          },
          () => {
            console.log(this.state.deliveryDate);
          }
        );
      });
    }
  };
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.listOfProductList(offset);
  };
  render() {
    const totPages = Math.ceil(
      this.props.listOfProductData.length / this.state.limit
    );

    let timingFunc;
    let inputCategory = React.createRef(null);
    const valueDebouncing = (val) => {
      if (timingFunc) {
        clearTimeout(timingFunc);
      }
      timingFunc = setTimeout(() => {
        this.setState({ addProduct_category: val });
      }, 500);
    };
    const getFIle = (file) => {
      this.setState({ addProduct_categoryImg: file });

      if (file != undefined) {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          this.setState({
            categoryDisplayImg: fileReader.result,
          });
        };
        fileReader.readAsDataURL(file[0]);
      }
    };

    const clearAll = (name) => {
      if (name === "category") {
        document.getElementById("namecategory").value = "";
        this.toggleAddCatergory();
      } else {
        document.getElementById("namesku").value = "";
        this.toggleAddSKU();
      }
    };

    //for sku
    let inputSku = React.createRef(null);
    let timingFunc_sku;
    const valueDebouncing_sku = (val) => {
      if (timingFunc_sku) {
        clearTimeout(timingFunc_sku);
      }
      timingFunc_sku = setTimeout(() => {
        this.setState({ addProduct_skuName: val });
      }, 500);
    };
    const getFIle_sku = (file) => {
      this.setState({ addProduct_skuImg: file });
      if (file != undefined) {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          // console.log(fileReader.result);
          this.setState({
            skuDisplayImg: fileReader.result,
          });
        };
        fileReader.readAsDataURL(file[0]);
      }
    };

    // const clearAllSku = () => {
    //   document.getElementById("namesku").value = "";
    //   this.toggleAddSKU();
    // };

    let { categories, Seller, Size, sku } = this.state;
    var data = {};
    data = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Catergory</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Size/Count</span>,
          field: "count",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Quantity Available</span>,
          field: "quantityAvailable",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Seller</span>,
          field: "seller",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Listing Date</span>,
          field: "date",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Delivery Date</span>,
          field: "deliveryDate",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props.listOfProductData.docs &&
      this.props.listOfProductData.docs.length > 0
    ) {
      this.props.listOfProductData.docs.forEach((ele) => {
        data.rows.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={ele?.images[0] ? ele?.images[0] : ele?.sku?.skuImage[0]}
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "N/A"}
              </p>
            </div>
          ),
          // ele.sku?.skuName ? ele.sku?.skuName : "N/A",
          category: ele.category?.categoryName
            ? ele.category?.categoryName
            : "N/A",
          count: ele.size,
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;<span>{ele.rate}</span>
            </div>
          ),
          quantityAvailable: ele.availableQuantity,
          seller: ele.seller ? ele.seller.fullName : "N/A",
          date: <div>{moment(ele.createdAt).format("DD/MM/YYYY")}</div>,
          deliveryDate: ele.deliveryDate ? (
            <div>{moment(ele.deliveryDate).format("DD/MM/YYYY")}</div>
          ) : (
            "N/A"
          ),
          // availableDeliveryDate: ele.availableDeliveryDate?.to ? (
          //   <div>
          //     {" "}
          //     {moment(ele.availableDeliveryDate?.from).format(
          //       "DD/MM/YYYY"
          //     )} - {moment(ele.availableDeliveryDate?.to).format("DD/MM/YYYY")}
          //   </div>
          // ) : (
          //   "N/A"
          // ),
          actions: (
            <div className="d-flex align-items-center tableBtns ">
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ productToEditId: ele._id }, () => {
                      this.editModalToggle();
                      this.productInfo(ele._id);
                    });
                  }}
                >
                  Edit
                </Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/ProductInfo/${ele._id}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>{" "}
              &nbsp;&nbsp;
              <Link to={`/ProductInfo/${ele._id}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ productToDeleteId: ele._id }, () => {
                      this.deleteConfirmModalToggle();
                    });
                  }}
                >
                  Delete
                </Button>
              </Link>
            </div>
          ),
        });
      });
    }
    document.body.addEventListener("click", this.handleChange);
    return (
      <div className="dashInnerMainDiv">
        <Modal
          isOpen={this.state.deleteConfirmModal}
          toggle={this.deleteConfirmModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.deleteConfirmModalToggle}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want to delete the product?</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                this.productDeleteData(this.state.productToDeleteId);
                this.deleteConfirmModalToggle();
              }}
            >
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.deleteConfirmModalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.editModal}
          toggle={this.editModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.editModalToggle}>Edit Product</ModalHeader>
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="price">Price</Label>
                    <Input
                      id="price"
                      name="price"
                      type="text"
                      value={this.state.price}
                      onChange={(e) => this.onChange("price", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="quantity">Total Quantity Available</Label>
                    <Input
                      id="quantity"
                      name="quantity"
                      type="text"
                      value={this.state.totalQuantity}
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      onChange={(e) =>
                        this.onChange("totalQuantity", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="date">Delivery Date</Label>
                    <Input
                      id="date"
                      name="date"
                      type="date"
                      value={this.state.deliveryDate}
                      onChange={(e) => {
                        this.setState({
                          deliveryDate: e.target.value,
                        });
                      }}
                      disabled={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn lightBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.productRequestEditData();
                }}
                disabled={this.state.isEditMode === "false" ? true : false}
              >
                Save
              </button>{" "}
              <Button color="secondary" onClick={this.editModalToggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <div>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <div className="d-flex align-items-center custCreationDiv ProductCategoryDiv">
                <img src={Producticon} alt="productIcon" />
                <div className="w-100">
                  <p className="mb-0">
                    <b>Product Category</b>
                  </p>
                  <div className="productMainDivStyle pos-rel">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleAddCatergory();
                      }}
                    >
                      Add category
                    </span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleAddSKU();
                        this.props.fetchCategoryData((res) => {
                          console.log(res);
                          if (res && res.length > 0) {
                            this.setState({
                              categories: res?.docs.map((ele) => {
                                return {
                                  label: ele.categoryName,
                                  value: ele._id,
                                };
                              }),
                            });
                            console.log(this.state.categories);
                          }
                        });
                      }}
                    >
                      Add SKU
                    </span>
                    <div
                      className={`logoutDropdown productMainFilterDropdown productMainCatPopupDropdown ${
                        this.state.toggleCatDiv ? "displayBlock" : "displayNone"
                      }`}
                    >
                      <div class="arrow-up"></div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Form>
                          <div>
                            <div className="inputDiv">
                              <Label for="namecategory">
                                Category Name
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                id="namecategory"
                                name="namecategory"
                                placeholder="Type..."
                                type="text"
                                onChange={(e) =>
                                  valueDebouncing(e.target.value)
                                }
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center">
                              <div className="productPopupStyleImg">
                                <img
                                  style={{ overflow: "hidden" }}
                                  src={
                                    this.state.categoryDisplayImg != undefined
                                      ? this.state.categoryDisplayImg
                                      : Addbuyerpic
                                  }
                                  alt="add catergory"
                                />
                              </div>

                              <div className="buyerBtn">
                                <p className="m-1">
                                  {/* {this.state.addProduct_categoryImg_name} */}
                                </p>
                                <div
                                  onClick={() => inputCategory.current?.click()}
                                  className="btn darkBtn"
                                >
                                  Add Image
                                </div>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  ref={inputCategory}
                                  hidden={true}
                                  type="file"
                                  className="btn darkBtn"
                                  onChange={(e) => getFIle(e.target.files)}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex align-items-center">
                            <Button
                              onClick={() => {
                                this.setState({
                                  addProduct_category: "",
                                  addProduct_categoryImg: undefined,
                                  // addProduct_categoryImg_name: "",
                                  categoryDisplayImg: undefined,
                                });
                                clearAll("category");
                              }}
                              className="btn lightBtn"
                            >
                              Clear all
                            </Button>
                            <Button
                              onClick={this.addCategoryData}
                              className="btn darkBtn"
                              disabled={
                                !(
                                  this.state.addProduct_category &&
                                  this.state.addProduct_categoryImg
                                )
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div
                      className={`logoutDropdown productMainFilterDropdown productMainCatPopupDropdown skuPopup ${
                        this.state.toggleSkuDiv ? "displayBlock" : "displayNone"
                      }`}
                    >
                      <div class="arrow-up"></div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Form>
                          <div>
                            <div className="inputDiv">
                              <Label for="namesku">Name</Label>
                              <span style={{ color: "red" }}>*</span>
                              <Input
                                id="namesku"
                                name="namesku"
                                placeholder="Type..."
                                type="text"
                                onChange={(e) => {
                                  valueDebouncing_sku(e.target.value.trim());
                                }}
                              />
                            </div>
                            <br />
                            <div className="inputDiv">
                              <Label>Select Category</Label>
                              <span style={{ color: "red" }}>*</span>
                              <SelectComponent
                                onChange={(data) => {
                                  this.setState({
                                    addProduct_categoryType: data,
                                  });
                                }}
                                options={this.state.categories}
                                value={
                                  this.state.addProduct_categoryType
                                    ? this.state.addProduct_categoryType
                                    : ""
                                }
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center">
                              <div className="productPopupStyleImg">
                                <img
                                  style={{ overflow: "hidden" }}
                                  src={
                                    this.state.skuDisplayImg != undefined
                                      ? this.state.skuDisplayImg
                                      : Addbuyerpic
                                  }
                                  alt="add catergory"
                                />
                              </div>
                              <div className="buyerBtn">
                                <p className="m-1">
                                  {" "}
                                  {/* {this.state.addProduct_skuName_img_name} */}
                                </p>
                                <div
                                  onClick={() => inputSku.current?.click()}
                                  className="btn darkBtn"
                                >
                                  Add Image
                                </div>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  ref={inputSku}
                                  hidden={true}
                                  type="file"
                                  className="btn darkBtn"
                                  onChange={(e) => getFIle_sku(e.target.files)}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex align-items-center">
                            <Button
                              className="btn lightBtn"
                              onClick={() => {
                                this.setState({
                                  addProduct_skuName: "",
                                  addProduct_categoryType: "",
                                  addProduct_skuImg: undefined,
                                  skuDisplayImg: undefined,
                                  // addProduct_skuName_img_name: "",
                                });
                                clearAll("sku");
                              }}
                            >
                              Clear all
                            </Button>
                            <Button
                              onClick={this.addSkuData}
                              className="btn darkBtn"
                              disabled={
                                !(
                                  this.state.addProduct_skuName &&
                                  this.state.addProduct_categoryType &&
                                  this.state.addProduct_skuImg
                                )
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Link style={{ textDecoration: "none" }} to={"/AddProduct"}>
                <div className="d-flex align-items-center custCreationDiv AddProductDiv">
                  <img src={Suppliericon} alt="supplierIcon" />
                  <div className="w-100">
                    <p className="mb-0">
                      <b>Add Product</b>
                      <br />
                      <span>(On Behalf Of Seller)</span>
                    </p>
                    <button className="rightBtnDiv">Add New</button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Product List</h4>
            <div>
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <div
                  className="pos-rel"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Button
                    className="btn lightBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.toggleFilter();
                    }}
                  >
                    Show Filters
                  </Button>
                  <div
                    className={`logoutDropdown productMainFilterDropdown ${
                      this.state.toggleDiv ? "displayBlock" : "displayNone"
                    }`}
                  >
                    <div class="arrow-up"></div>
                    <div>
                      <AccordionComponent AccordTitle={"Category"}>
                        <SelectComponent
                          options={categories}
                          onChange={(val) => {
                            if (val) {
                              this.onFilterData("category", val);
                              let payload = {
                                categoryId: val.value,
                              };
                              this.props.categoryBySku(payload, (res) => {
                                if (res.skus) {
                                  this.setState({
                                    sku: res.skus.map((ele) => {
                                      return {
                                        label: ele.skuName,
                                        value: ele._id,
                                      };
                                    }),
                                  });
                                }
                              });
                            } else {
                              this.onFilterData("category", val);
                              this.setState({ sku: [] });
                            }
                          }}
                          value={this.state.category ? this.state.category : ""}
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"SKU"}>
                        <SelectComponent
                          options={sku}
                          onChange={(val) => {
                            this.onFilterData("SelectedSku", val);
                          }}
                          value={
                            this.state.SelectedSku ? this.state.SelectedSku : ""
                          }
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Seller"}>
                        <SelectComponent
                          options={Seller}
                          onChange={(val) => {
                            this.onFilterData("seller", val);
                          }}
                          value={this.state.seller ? this.state.seller : ""}
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Size"}>
                        <SelectComponent
                          options={Size}
                          onChange={(val) => {
                            this.onFilterData("size", val);
                          }}
                          value={this.state.size ? this.state.size : ""}
                        />
                      </AccordionComponent>

                      <Form>
                        <div>
                          <p>Price</p>
                          <div className="d-flex align-items-center inputDiv">
                            <Label for="exampleInput">INR</Label>&nbsp;&nbsp;
                            <Input
                              id="exampleInput"
                              name="text"
                              placeholder="MINIMUM"
                              type="text"
                              onChange={(e) => {
                                this.onFilterData("minPrice", e.target.value);
                              }}
                            />
                          </div>
                          <br />
                          <div className="d-flex align-items-center inputDiv">
                            <Label for="exampleInput1">INR</Label>&nbsp;&nbsp;
                            <Input
                              id="exampleInput1"
                              name="text"
                              placeholder="MAXIMUM"
                              type="text"
                              onChange={(e) => {
                                this.onFilterData("maxPrice", e.target.value);
                              }}
                            />
                          </div>
                          <br />
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            outline
                            onClick={() => {
                              this.setState(
                                {
                                  category: "",
                                  seller: "",
                                  size: "",
                                  minPrice: "",
                                  maxPrice: "",
                                  SelectedSku: "",
                                  currentPage: 1,
                                  sku: [],
                                },
                                () => {
                                  this.listOfProductList();
                                  this.setState({ toggleDiv: false });
                                }
                              );
                            }}
                          >
                            Clear all
                          </Button>
                          <Button
                            outline
                            className="buttonDivStyle"
                            onClick={() => {
                              this.setState(
                                {
                                  currentPage: 1,
                                },
                                () => {
                                  this.filterProductData();
                                }
                              );
                            }}
                          >
                            Apply Filter
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                {/* <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                  <Input
                    name="search"
                    placeholder="search placeholder"
                    type="search"
                    className="mb-0"
                    onChange={(e) => {
                      this.props.fetchProductFilterSkuData(e.target.value);
                    }}
                  />
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                      stroke="#5E5E5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </FormGroup> */}
                {/* <FormGroup className="d-flex align-items-center tableHeaderSelectDiv">
                  <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                  <Input id="exampleSelect" name="select" type="select">
                    <option>Latest</option>
                    <option>option2</option>
                    <option>option3</option>
                    <option>option4</option>
                    <option>option5</option>
                  </Input>
                </FormGroup> */}
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      this.setState(
                        {
                          limit: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.listOfProductList();
                        }
                      );
                      // setTimeout(() => {
                      //   this.listOfProductList();
                      // }, 500);
                    }}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
            displayEntries={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props.listOfProductData?.length != 0
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props.listOfProductData.docs?.length
                  ? this.props.listOfProductData.docs.length
                  : "")}{" "}
              {} entries of {this.props.listOfProductData?.length}
            </p>
          </div>
          <div className="custPaginationDiv">
            <Pagination
              shape="rounded"
              count={totPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    listOfProductData: state.rfu.productdata,
    categoryData: state.rfu.categorydata,
    listOfsellerData: state.rfu.sellerData,
    addedProductCategory: state.rfu.added_product_category,
    addedProductSku: state.rfu.added_product_sku,
    allSkusList: state.rfu.allSkusData,
    productViewData: state.rfu.productviewdata,
  };
};

export default connect(mapStateToProps, {
  fetchProductFilterSkuData,
  fetchCategoryData,
  fetchproductSortData,
  fetchSellerData,
  fetchProductAllFilterData,
  Product_addCategory,
  Product_addSku,
  GetSkusList,
  productDeleteData,
  productEditData,
  categoryBySku,
  fetchProductViewData,
})(ProductMain);
