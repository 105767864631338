import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import DarkLogo from "../../logo.PNG";
import { userLogin } from "../../store/actions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      phone: "",
      password: "",
    };
  }

  valueStore = (type, e) => {
    let phoneCheck = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let emailCheck =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (type === "username") {
      if (e.target.value.match(phoneCheck)) {
        this.setState({ phone: e.target.value, email: "" });
      } else if (e.target.value.match(emailCheck)) {
        this.setState({ email: e.target.value, phone: "" });
      } else {
        const checkInteger = /^[0-9]+$/;
        if (checkInteger.test(e.target.value))
          this.setState({ email: "", phone: e.target.value });
        else this.setState({ email: e.target.value, phone: "" });
      }
    }
    if (type === "password") {
      this.setState({ password: e.target.value });
    }
  };

  loginAction = () => {
    if (this.state.phone) {
      this.props.userLogin(
        { phone: this.state.phone, password: this.state.password },
        this.props.history,
        (res) => {
          if (res.message === "Login successfull!!") {
            NotificationManager.success("User Logged In", "LogIn Successful");
          } else {
            NotificationManager.error(res.message, "Error");
          }
        }
      );
    } else {
      this.props.userLogin(
        { email: this.state.email, password: this.state.password },
        this.props.history,
        (res) => {
          if (res.message === "Login successfull!!") {
            NotificationManager.success("User Logged In", "LogIn Successful");
          } else {
            NotificationManager.error(res.message, "Error");
          }
        }
      );
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (
        (this.state.email !== "" && this.state.phone !== "") ||
        this.state.password !== ""
      ) {
        this.loginAction();
      }
    }
  };

  render() {
    return (
      <div>
        <Container fluid>
          <Row className="justify-content-md-center loginDivCss">
            <Col xs={12} sm={4} md={4} className="loginLogRight">
              <div>
                <img src={DarkLogo} alt="darkLogoImage" />
                <hr />
                <h2>Welcome,</h2>
                <p>Please Sign in</p>
                <div>
                  <Form>
                    <FormGroup>
                      <Input
                        name="email"
                        placeholder="Email / Phone"
                        type="text"
                        onKeyPress={this.handleKeyDown}
                        onChange={(e) => {
                          this.valueStore("username", e);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="exampleNumber"
                        name="password"
                        placeholder="Password"
                        type="password"
                        onChange={(e) => {
                          this.valueStore("password", e);
                        }}
                        onKeyPress={this.handleKeyDown}
                      />
                    </FormGroup>
                    <div className="d-flex align-items-center justify-content-between checkDivCss"></div>
                    <Button
                      onClick={() => {
                        this.loginAction();
                      }}
                      outline
                      className="buttonDivStyle"
                    >
                      Sign In
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-between footDivStyle">
                <p>© FreshR. All Rights Reserved.</p>
                <ul className="d-flex align-items-center">
                  <li>Terms of Use</li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loggedInUserData: state.rfu.loggedInUser,
  };
};

export default connect(mapStateToProps, { userLogin })(LoginPage);
