import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getApi,
  postApi,
  postLogin,
  deleteApi,
  patchApi,
  patchApiFormData,
  patchApiFormData2,
  postApiFormData,
  getApiForVerification,
  postApiVerification
} from "../helpers/auth_helper";
import { productDeleteData } from "./actions";
import { types } from "./actionTypes";
import { NotificationManager } from "react-notifications";

function* middlewarefetchfunction() {
  const userId = JSON.parse(localStorage.getItem("authUser"));
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/${userId._id || ""}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PROFILE_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("Failed to fetch profile data", e);
  }
}

function* customerViewFunction({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.CUSTOMER_VIEW_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    console.log("error occured", e);
  }
}
function* middlewareCustomerEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/updatecustomer?userId=${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareCustomerSearchData({ id }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?phone=${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.CUSTOMER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* workUserLogin({ payload: { data, history }, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/auth/login?userType=admin`;
    let response = yield call(postLogin, uri, data);
    callback(response);
    yield put({
      type: types.USER_LOGIN_SUCCESS,
      payload: response,
    });
    if (response && response.message == "Login successfull!!") {
      history.push("/Dashboard");
    }
  } catch (e) {
    console.log("failed to fetch dashboard data", e);
  }
}

function* middlewarefetchfunction_Dashboard() {
  // console.log(a);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/customer/count`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.DASHBOARD_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareCustomerSortData({
  offset,
  limit,
  searchCustomer,
  status,
  source,
  userName,
  sort,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?offset=${offset}&limit=${limit}&phone=${searchCustomer}&userAccountStatus=${status}&userAccountSource=${source}&userName=${userName}`;
    if (sort && sort === "Oldest") {
      uri = uri + `&sort={"createdAt":1}`;
    }
    let response = yield call(getApi, uri);
    yield put({
      type: types.CUSTOMER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareCustomerDeleteData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
    //   yield put({
    //     type: types.USER_VIEW_DATA_SUCCESS,
    //     payload: response,
    //   });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareUserRequestData({ offset, limit, userAccountSource }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/services?offset=${offset}&limit=${limit}&userAccountSource=${userAccountSource}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.USER_REQUEST_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareUserViewData({ id }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.USER_VIEW_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareUseRequestDeleteData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
    //   yield put({
    //     type: types.USER_VIEW_DATA_SUCCESS,
    //     payload: response,
    //   });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareUseRequestEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("error occured");
  }
}

function* middlewareProductSortData({
  offset,
  limit,
  maxPrice,
  minPrice,
  category,
  size,
  seller,
  SelectedSku,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?offset=${offset}&limit=${limit}&maxprice=${maxPrice}&minprice=${minPrice}&category=${category}&size=${size}&seller=${seller}&sku=${SelectedSku}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_DATA_SUCESSS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured", e);
  }
}
function* middlewareProductFilterData({ payload }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?offset=0&limit=${payload}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_DATA_SUCESSS,
      payload: response.docs,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareProductFilterSkuData({ id }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?sku=${id}`;
    let response = yield call(getApi, uri);
    console.log(response);
    yield put({
      type: types.PRODUCT_DATA_SUCESSS,
      payload: response.docs,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareProductViewData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_VIEW_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareCategoryData({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/categories`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.CATEGORY_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareUserCountData() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/count/countUserRequest`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.USER_COUNT_DATA_SUCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareSellerData({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/list/getlistseller`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SELLER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured", e);
  }
}
function* middlewareProductAllFilterData({
  category,
  seller,
  size,
  minPrice,
  maxPrice,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?maxprice=${maxPrice}&minprice=${minPrice}&category=${category}&seller=${seller}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_ALL_FILTER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("error occured");
  }
}

function* fetchSellerData() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/list/getlistseller`;
    let response = yield call(getApi, uri);
    yield put({ type: types.PRODUCT_SELLER_DATASUCCESS, payload: response });
  } catch (e) {
    console.log("failed to fetch dashboard data", e);
  }
}

function* workVerifyAccount({ payload, callback }) {
  try {
    console.log("verify account from saga", payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/bankDetails/verifybank`;
    let response = yield call(postApi, uri, payload);
    console.log(response);
    callback(response);
    yield put({ type: types.VERIFY_BANK_ACCOUNT_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to verify Bank Account", e);
  }
}

function* fetchProductData() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/categories`;
    let response = yield call(getApi, uri);
    // console.log(response);
    yield put({
      type: types.PRODUCT_PRODUCT_CATEGORYSUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("failed to fetch dashboard data", e);
  }
}
function* fetchEnum() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/enum/enum`;
    let response = yield call(getApi, uri);
    // console.log(response);
    yield put({
      type: types.ENUM_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("failed to fetch dashboard data", e);
  }
}
function* enumEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/enum/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("failed to fetch dashboard data", e);
  }
}
function* fetchskusbyid(actiondata) {
  try {
    // console.log(actiondata);
    let values = actiondata.value;
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/listbycategoryid`;
    let response = yield call(postApi, uri, values);
    console.log(response);
    yield put({ type: types.PRODUCT_GET_SKU_BYID_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to fetch dashboard data", e);
  }
}
function* createAProduct({ data, callback }) {
  try {
    console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/create`;
    let response = yield call(postApiFormData, uri, data);
    console.log(response);
    callback(response);
    yield put({ type: types.PRODUCT_CREATE_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to create product", e);
  }
}
function* ProductPostAddCategory({ data, callback }) {
  try {
    console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/create`;
    let response = yield call(postApiFormData, uri, data);
    console.log(response);
    callback(response);
    yield put({ type: types.PRODUCTS_ADD_CATEGORY_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to post category data", e);
  }
}
function* productFetchAllSkus() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/list`;
    let response = yield call(getApi, uri);
    console.log(response);
    yield put({ type: types.PRODUCTS_GET_ALL_SKUS_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to get skus list", e);
  }
}

function* ProductPostAddSku({ data, callback }) {
  try {
    console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/create`;
    let response = yield call(postApiFormData, uri, data);
    console.log(response);
    callback(response);
    yield put({ type: types.PRODUCTS_ADD_SKU_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to post Sku data", e);
  }
}
function* customerPostData({ data, callback }) {
  try {
    console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/create`;
    let response = yield call(postApi, uri, data);
    console.log(response);
    yield put({ type: types.CUSTOMER_ADDITION_SUCCESS, payload: response });
    callback(response);
  } catch (e) {
    console.log("failed to post Sku data", e);
  }
}
function* middlewareProductDeleteData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
    //   yield put({
    //     type: types.USER_VIEW_DATA_SUCCESS,
    //     payload: response,
    //   });
  } catch (e) {
    console.log("error occured");
  }
}
function* middlewareProductEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("error occured");
  }
}

function* workTriggerOtp({ payload, callback }) {
  try {
    console.log(payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/thirdparty/verification/sendkycotp`;
    let response = yield call(postApiVerification, uri, payload);
    console.log(response);
    callback(response);
    yield put({ type: types.TRIGGER_OTP_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to trigger otp", e);
  }
}

function* workVerifyOtp({ payload, callback }) {
  try {
    console.log(payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/thirdparty/verification/verifykycotp`;
    let response = yield call(postApiVerification, uri, payload);
    console.log(response);
    callback(response);
    yield put({ type: types.VERIFY_OTP_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to Verify otp", e);
  }
}
function* workTriggerKycForm({ payload, callback }) {
  try {
    console.log(payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/thirdparty/verification/kyc`;
    let response = yield call(postApi, uri, payload);
    console.log(response);
    callback(response);
    yield put({ type: types.TRIGGER_KYC_FORM_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to Trigger KYC form link sms", e);
  }
}
function* WorkSkuList({ offset, limit }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/list?offset=${offset}&limit=${limit}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.SKU_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("Failed to fetch SKU Category List", e);
  }
}
function* WorkSkuDeleteData({ id, callback }) {
  console.log(id);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    console.log("Failed to Delete SKU Data", e);
  }
}
function* WorkSkuEditData({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/${id}`;
    let response = yield call(patchApiFormData, uri, payload);
    callback(response);
  } catch (e) {
    console.log("Failed to Edit SKU Data", e);
  }
}
function* WorkSkuViewData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SKU_VIEW_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("Failed to fetch SKU Category View Data", e);
  }
}
function* WorkCategoryBySkuData({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/listbycategoryid`;
    let response = yield call(postApi, uri, payload);
    callback(response);
    // yield put({
    //   type: types.SKU_CATEGORY_VIEW_DATA_SUCCESS,
    //   payload: response,
    // });
  } catch (e) {
    console.log("Failed to fetch Category by SKU Data", e);
  }
}
function* WorkCategoryData({ offset, limit }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/categories?offset=${offset}&limit=${limit}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.CATEGORYS_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("Failed to fetch Category Data", e);
  }
}
function* WorkCategoryDeleteData({ id, callback }) {
  console.log(id);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    console.log("Failed to Delete Category Data", e);
  }
}
function* WorkCategoryViewData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.CATEGORY_VIEW_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("Failed to fetch Category View Data", e);
  }
}
function* WorkCategoryEditData({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}`;
    let response = yield call(patchApiFormData, uri, payload);
    callback(response);
  } catch (e) {
    console.log("Failed to Edit Category Data", e);
  }
}

function* workUploadCancelledCheque({ data, userId, callback }) {
  try {
    console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/docs-upload?userId=${userId}`;
    let response = yield call(patchApiFormData, uri, data);
    console.log(response);
    callback(response);
    yield put({
      type: types.UPLOAD_CANCELLED_CHEQUE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log("failed to upload cancelled cheque image", e);
  }
}

function* workUploadKycDocuments({ data, userId, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/docs-upload?userId=${userId}`;
    let response = yield call(patchApiFormData, uri, data);
    console.log(response);
    callback(response);
    yield put({ type: types.UPLOAD_KYC_DOCUMENT_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to upload KYC document", e);
  }
}

function* getSosData({ data, callback }) {
  try {
    console.log(data);
    // let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?offset=${data.offset}&limit=${data.limit}`;
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/sos?offset=${data.offset}&limit=${data.limit}&search={"fullName":"${data.buyer}","sosId":"${data.sosId}"}`;
    // let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/sos?offset=${data.offset}&limit=${data.limit}&search={"fullName":"${data.buyer}"}`
    if (data.sort) {
      uri =
        uri +
        `&sort={"${data.sort.split(" ")[0]}":"${data.sort.split(" ")[1]}"}`;
    }
    // &sodId=${data.sosId}&buyer=${data.buyer}
    let response = yield call(getApi, uri);
    // console.log(response);
    callback(response);
    // yield put({ type: types.SOS_REQUEST_SUCCESS, payload: response });
  } catch (e) {
    console.log("failed to fetch SOS Data", e);
  }
}

function* updateSos({ data, payload, callback }) {
  console.log("works");
  console.log(data);
  console.log(payload);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/${data}`;
    let response = yield call(patchApi, uri, payload);
    yield put({
      type: types.SOS_UPDATE_REQUEST_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    console.log("error occured");
  }
}
// deleteSos

function* GetOrderData({ data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/orders?offset=${data.offset}&limit=${data.limit}&paymentStatus=${data.paymentStatus}&orderStatus=${data.orderStatus}&orderId=${data.orderId}&buyerId=${data.buyerName}`;
    if (data.sort) {
      uri =
        uri +
        `&sort={"${data.sort.split(" ")[0]}":"${data.sort.split(" ")[1]}"}`;
    }
    let response = yield call(getApi, uri);
    yield put({
      type: types.ORDERS_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    console.log("failed to fetch SOS Data", e);
  }
}
function* middlewareOrderViewDaata({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.ORDER_VIEW_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    console.log("failed to fetch order View");
  }
}
function* middlewareOrderCancel({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/cancle-order/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    console.log("failed to cancel order ");
  }
}
function* middlewareBuyerSearch({ offset, limit, userName, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?offset=${offset}&limit=${limit}&userType=buyer`;
    if (userName) {
      uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?userType=buyer&userName=${userName}`;
    }
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    console.log("failed to Buyer Search ");
  }
}
function* middlewareOrderUpdate({ data, orderId, orderActions, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/updateOrder/${orderId}/${orderActions}`;
    // let uri = `${process.env.REACT_APP_BACKEND_URL}/order/${orderId}/${orderActions}`;
    let response = yield call(patchApi, uri, data);
    callback(response);
  } catch (e) {
    console.log("failed to order Update ");
  }
}
function* middlewareAddTransport({ id, data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/addTransport/${id}`;
    let response = yield call(patchApi, uri, data);
    callback(response);
  } catch (e) {
    console.log("failed to add transport ");
  }
}
function* middlewareAddDiscount({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/discount/initiateDiscount/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("failed to add 'discount' ");
  }
}
function* middlewarePaymentLink({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/initiatepayment/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    console.log("Failed to fetch payment link", e);
  }
}
function* middlewarePaymentIntiate({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/refunds/refund/`;
    let response = yield call(postApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("Failed to fetch payment intiate", e);
  }
}
function* middlewareResendPayment({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/resendpaymentLink`;
    let response = yield call(postApi, uri, payload);
    callback(response);
  } catch (e) {
    console.log("Failed to fetch resend payment", e);
  }
}
function* customerViewVerifyFunction({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/one/${id}`;
    let response = yield call(getApiForVerification, uri);
    yield put({
      type: types.CUSTOMER_VERIFICATION_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    console.log("error occured", e);
  }
}
function* watchfetchrequest() {
  yield takeEvery(types.PROFILE_DATA, middlewarefetchfunction);
}

function* watchCustomerViewRequest() {
  yield takeEvery(types.CUSTOMER_VIEW_DATA, customerViewFunction);
}
function* watchCustomerEditData() {
  yield takeEvery(types.CUSTOMER_EDIT_DATA, middlewareCustomerEditData);
}

function* watchfetchrequestDashboard() {
  yield takeEvery(types.DASHBOARD_DATA, middlewarefetchfunction_Dashboard);
}
function* watchCustomerSortData() {
  yield takeEvery(types.CUSTOMER_SORT_DATA, middlewareCustomerSortData);
}
function* watchUserRequestData() {
  yield takeEvery(types.USER_REQUEST_DATA, middlewareUserRequestData);
}
function* watchUserViewData() {
  yield takeEvery(types.USER_VIEW_DATA, middlewareUserViewData);
}
function* watchUserRequestDeleteData() {
  yield takeEvery(
    types.USER_REQUEST_DELETE_DATA,
    middlewareUseRequestDeleteData
  );
}
function* watchUserRequestEditData() {
  yield takeEvery(types.USER_REQUEST_EDIT_DATA, middlewareUseRequestEditData);
}

function* watchUserLogin() {
  yield takeEvery(types.USER_LOGIN, workUserLogin);
}

function* watchProductSortData() {
  yield takeEvery(types.PRODUCT_SORT_DATA, middlewareProductSortData);
}
function* watchProductFilterData() {
  yield takeEvery(types.PRODUCT_FILTER_DATA, middlewareProductFilterData);
}
function* watchProductFilterSkuData() {
  yield takeEvery(
    types.PRODUCT_FILTER_DATA_BY_SKU,
    middlewareProductFilterSkuData
  );
}
function* watchProductViewData() {
  yield takeEvery(types.PRODUCT_VIEW_DATA, middlewareProductViewData);
}
function* watchCategoryData() {
  yield takeEvery(types.CATEGORY_DATA, middlewareCategoryData);
}
function* watchUserCountData() {
  yield takeEvery(types.USER_COUNT_DATA, middlewareUserCountData);
}
function* watchCustomerDeleteData() {
  yield takeEvery(types.CUSTOMER_DELETE_DATA, middlewareCustomerDeleteData);
}
function* watchSellerData() {
  yield takeEvery(types.SELLER_DATA, middlewareSellerData);
}
function* watchProductAllFilterData() {
  yield takeEvery(
    types.PRODUCT_ALL_FILTER_DATA,
    middlewareProductAllFilterData
  );
}

function* watchFetchSellerData() {
  yield takeEvery(types.PRODUCT_SELLER_DATA, fetchSellerData);
}
function* watchFetchProductCateogry() {
  yield takeEvery(types.PRODUCT_PRODUCT_CATEGORY, fetchProductData);
}
function* watchFetchSkuByID() {
  yield takeEvery(types.PRODUCT_GET_SKU_BYID, fetchskusbyid);
}
function* watchProductCreate() {
  yield takeEvery(types.PRODUCT_CREATE, createAProduct);
}
function* watchProductAddCategory() {
  yield takeEvery(types.PRODUCTS_ADD_CATEGORY, ProductPostAddCategory);
}
function* watchProductGetSkus() {
  yield takeEvery(types.PRODUCTS_GET_ALL_SKUS, productFetchAllSkus);
}
function* watchProductAddSku() {
  yield takeEvery(types.PRODUCTS_ADD_SKU, ProductPostAddSku);
}
function* watchCustomerAddition() {
  yield takeEvery(types.CUSTOMER_ADDITION, customerPostData);
}
function* watchProductDeleteData() {
  yield takeEvery(types.PRODUCT_DELETE_DATA, middlewareProductDeleteData);
}
function* watchProductEditData() {
  yield takeEvery(types.PRODUCT_EDIT_DATA, middlewareProductEditData);
}
function* watchVerifyAccount() {
  yield takeEvery(types.VERIFY_BANK_ACCOUNT, workVerifyAccount);
}
function* watchTriggerOtp() {
  yield takeEvery(types.TRIGGER_OTP, workTriggerOtp);
}
function* watchVerifyOtp() {
  yield takeEvery(types.VERIFY_OTP, workVerifyOtp);
}
function* watchTriggerKycForm() {
  yield takeEvery(types.TRIGGER_KYC_FORM, workTriggerKycForm);
}
function* watchSkuList() {
  yield takeEvery(types.SKU_DATA, WorkSkuList);
}
function* watchSkuDeleteData() {
  yield takeEvery(types.SKU_DELETE_DATA, WorkSkuDeleteData);
}
function* watchSkuEditData() {
  yield takeEvery(types.SKU_EDIT_DATA, WorkSkuEditData);
}
function* watchSkuViewData() {
  yield takeEvery(types.SKU_VIEW_DATA, WorkSkuViewData);
}
function* watchCategoryBySkuData() {
  yield takeEvery(types.CATEGORY_BY_SKU_DATA, WorkCategoryBySkuData);
}
function* watchCategorysData() {
  yield takeEvery(types.CATEGORYS_DATA, WorkCategoryData);
}
function* watchCategoryDeleteData() {
  yield takeEvery(types.CATEGORY_DELETE_DATA, WorkCategoryDeleteData);
}
function* watchCategoryViewData() {
  yield takeEvery(types.CATEGORY_VIEW_DATA, WorkCategoryViewData);
}
function* watchCategoryEditData() {
  yield takeEvery(types.CATEGORY_EDIT_DATA, WorkCategoryEditData);
}
function* watchUploadCancelledCheque() {
  yield takeEvery(types.UPLOAD_CANCELLED_CHEQUE, workUploadCancelledCheque);
}
function* watchUploadKycDocument() {
  yield takeEvery(types.UPLOAD_KYC_DOCUMENT, workUploadKycDocuments);
}
function* watchSosData() {
  yield takeEvery(types.SOS_REQUEST, getSosData);
}
function* watchSosDelete() {
  yield takeEvery(types.SOS_UPDATE_REQUEST, updateSos);
}
function* watchOrderData() {
  yield takeEvery(types.ORDERS_DATA, GetOrderData);
}
function* watchOrderViewData() {
  yield takeEvery(types.ORDER_VIEW_DATA, middlewareOrderViewDaata);
}
function* watchOrderCancel() {
  yield takeEvery(types.ORDER_CANCEL, middlewareOrderCancel);
}
function* watchBuyerSearch() {
  yield takeEvery(types.BUYER_SEARCH, middlewareBuyerSearch);
}
function* watchOrderUpdate() {
  yield takeEvery(types.ORDER_UPDATE, middlewareOrderUpdate);
}
function* watchAddTransport() {
  yield takeEvery(types.ADD_TRANSPORT, middlewareAddTransport);
}
function* watchAddDiscount() {
  yield takeEvery(types.ORDER_DISCOUNT, middlewareAddDiscount);
}
function* watchIssuePaymentLink() {
  yield takeEvery(types.ISSUE_PAYMENT_LINK, middlewarePaymentLink);
}
function* watchPaymentIntiate() {
  yield takeEvery(types.INTIATE_REFUND, middlewarePaymentIntiate);
}
function* watchResendPayment() {
  yield takeEvery(types.RESEND_PAYMENT, middlewareResendPayment);
}
function* watchFetchEnum() {
  yield takeEvery(types.ENUM, fetchEnum);
}
function* watchFetchEnumEdit() {
  yield takeEvery(types.ENUM_EDIT, enumEditData);
}
function* watchCustomerVerifyequest() {
  yield takeEvery(types.CUSTOMER_VERIFICATION, customerViewVerifyFunction);
}

export default function* rootSaga() {
  yield all([
    fork(watchfetchrequest),
    fork(watchfetchrequestDashboard),
    fork(watchCustomerViewRequest),
    fork(watchCustomerSortData),
    fork(watchUserRequestData),
    fork(watchUserViewData),
    fork(watchUserRequestDeleteData),
    fork(watchUserRequestEditData),
    fork(watchUserLogin),
    fork(watchProductSortData),
    fork(watchProductFilterData),
    fork(watchProductFilterSkuData),
    fork(watchCustomerEditData),
    fork(watchCategoryData),
    fork(watchUserCountData),
    fork(watchCustomerDeleteData),
    fork(watchSellerData),
    fork(watchFetchSellerData),
    fork(watchFetchProductCateogry),
    fork(watchFetchSkuByID),
    fork(watchProductCreate),
    fork(watchProductAddCategory),
    fork(watchProductGetSkus),
    fork(watchProductAddSku),
    fork(watchCustomerAddition),
    fork(watchProductDeleteData),
    fork(watchProductViewData),
    fork(watchProductEditData),
    fork(watchVerifyAccount),
    fork(watchTriggerOtp),
    fork(watchVerifyOtp),
    fork(watchTriggerKycForm),
    fork(watchSkuList),
    fork(watchSkuDeleteData),
    fork(watchSkuEditData),
    fork(watchSkuViewData),
    fork(watchCategoryBySkuData),
    fork(watchCategorysData),
    fork(watchCategoryDeleteData),
    fork(watchCategoryViewData),
    fork(watchCategoryEditData),
    fork(watchUploadCancelledCheque),
    fork(watchUploadKycDocument),
    fork(watchSosData),
    fork(watchSosDelete),
    fork(watchOrderData),
    fork(watchOrderViewData),
    fork(watchOrderCancel),
    fork(watchBuyerSearch),
    fork(watchOrderUpdate),
    fork(watchAddTransport),
    fork(watchAddDiscount),
    fork(watchIssuePaymentLink),
    fork(watchPaymentIntiate),
    fork(watchResendPayment),
    fork(watchFetchEnum),
    fork(watchFetchEnumEdit),
    fork(watchCustomerVerifyequest),
  ]);
}
