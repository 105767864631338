import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import {
  customerViewData,
  customerEditData,
  verifyBankAccount,
  triggerKycForm,
  uploadCancelledCheque,
  uploadKycDocuments,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SelectComponent from "../../Product/common/SelectComponent";
import { stateList } from "../../../common/StateList";
import { userTypeList } from "../../../common/userTypeList";

class Customerdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEditMode: "false",
      name: "",
      natureOfBusinness: "",
      email: "",
      mobNo: "",
      addrs: "",
      state: "",
      pin: "",
      src: "",
      memberSince: "",
      accNo: "",
      IFSC: "",
      sendVerifyModal: false,
      bankDetailsVerified: {},
      trackNumber: 0,
      userType: "",
      businessType: [
        { label: "Private limited company", value: "Private limited company" },
        { label: "Public limited company", value: "Public limited company" },
        { label: "Sole proprierter", value: "Sole proprierter" },
        { label: "Partnership", value: "Partnership" },
      ],
      displayImage: "",
      chequeImage: "",
      chequeImageName: "",
      kycDocumentType: "",
      kycDisplayImage: [],
      bankVerificationDetails: null,
      userName: "",
      bankName: "",
      bankBranch: "",
      micr: "",
      submitKycDisable: true,
      accountVerified: "",
      nameEdit: undefined,
      natureOfBusinnessEdit: undefined,
      emailEdit: undefined,
      mobNoEdit: undefined,
      userTypeEdit: undefined,
      addrsEdit: undefined,
      stateEdit: undefined,
      pinEdit: undefined,
      addressProofImage: [],
      formDataIdentity: [],
      formDataAddress: [],
    };
  }
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  componentDidMount() {
    this.customerViewDataEdit();
  }
  customerViewDataEdit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    var queryParams = this.props.computedMatch.params.customerid;
    if (queryParams) {
      this.props.customerViewData(queryParams, (res) => {
        const blankArrayImage = [];
        const blankArrayImageAddress = [];
        const panImageArray = [];
        const imageArray =
          this.props?.customerviewData?.identityProof?.aadharCard.length > 0
            ? this.props?.customerviewData?.identityProof?.aadharCard
            : [];
        const panImage =
          this.props?.customerviewData?.identityProof?.panCard.length > 0
            ? this.props?.customerviewData?.identityProof?.panCard
            : [];
        const ImageAddressArray = this.props?.customerviewData?.addressProof;
        if (imageArray?.length > 0) {
          imageArray.forEach((ele) => {
            blankArrayImage.push({
              disable: true,
              selectedFile: ele,
            });
          });
        }
        if (panImage.length > 0) {
          panImage.forEach((ele) => {
            panImageArray.push({
              disable: true,
              selectedFile: ele,
            });
          });
        }
        if (ImageAddressArray.length > 0) {
          ImageAddressArray.forEach((ele) => {
            blankArrayImageAddress.push({
              disable: true,
              selectedFile: ele,
            });
          });
        }
        this.setState({
          id: this.props.customerviewData?._id,
          isEditMode: myParam ? myParam : "false",
          kycDisplayImage: [...panImageArray, ...blankArrayImage],
          addressProofImage: blankArrayImageAddress,
          name: this.props.customerviewData?.fullName,
          email: this.props.customerviewData.email
            ? this.props.customerviewData.email
            : "",
          mobNo: this.props.customerviewData.phone
            ? this.props.customerviewData.phone
            : "",
          addrs: this.props.customerviewData.address
            ? this.props.customerviewData.address
            : "",
          state: this.props.customerviewData.state
            ? {
                value: this.props.customerviewData.state,
                label: this.props.customerviewData.state,
              }
            : "",
          pin: this.props.customerviewData.pincode
            ? this.props.customerviewData.pincode
            : "",
          src: this.props.customerviewData.userAccountSource,
          memberSince: this.props?.customerviewData?.memberSince
            ? moment(this.props.customerviewData.memberSince).format(
                "DD/MM/YYYY"
              )
            : "",
          userType: this.props.customerviewData.userType
            ? {
                value: this.props.customerviewData.userType,
                label: this.props.customerviewData.userType,
              }
            : "",
          accNo: this.props.customerviewData.bank?.accountNo,
          IFSC: this.props.customerviewData.bank?.ifsc,
          natureOfBusinness: this.props.customerviewData.natureOfBusiness
            ? {
                value: this.props.customerviewData.natureOfBusiness,
                label: this.props.customerviewData.natureOfBusiness,
              }
            : "",
          displayImage:
            this.props?.customerviewData?.cancelledCheque.length > 0
              ? this.props?.customerviewData?.cancelledCheque[0]
              : "",
          // ? this.props?.customerviewData?.cancelledCheck[0]
          // : "",
          bankVerificationDetails: this.props?.customerviewData?.bank
            ? this.props?.customerviewData?.bank
            : null,
          userName: this.props?.customerviewData?.bank?.username
            ? this.props?.customerviewData?.bank?.username
            : "",
          bankName: this.props?.customerviewData?.bank?.bankName
            ? this.props?.customerviewData?.bank?.bankName
            : "",
          bankBranch: this.props?.customerviewData?.bank?.city
            ? this.props?.customerviewData?.bank?.city
            : "",
          micr: this.props?.customerviewData?.bank?.micr
            ? this.props?.customerviewData?.bank?.micr
            : "",
          submitKycDisable: !(
            this.props?.customerviewData?.bankDetails?.type &&
            this.props?.customerviewData?.bankDetails?.images[0]
          ),
          accountVerified: this.props?.customerviewData?.bank
            ?.accountVerificationStatus
            ? this.props?.customerviewData?.bank?.accountVerificationStatus
            : "",
          nameEdit: undefined,
          natureOfBusinnessEdit: undefined,
          emailEdit: undefined,
          mobNoEdit: undefined,
          userTypeEdit: undefined,
          addrsEdit: undefined,
          stateEdit: undefined,
          pinEdit: undefined,
        });
      });
    }
  };
  onChangeEdit(e) {
    const {
      nameEdit,
      natureOfBusinnessEdit,
      emailEdit,
      mobNoEdit,
      userTypeEdit,
      addrsEdit,
      stateEdit,
      pinEdit,
    } = this.state;
    e.preventDefault();

    let payload = {
      fullName: nameEdit ? nameEdit : "",
      phone: mobNoEdit ? mobNoEdit : "",
      address: addrsEdit ? addrsEdit : "",
      state: stateEdit?.value ? stateEdit?.value : "",
      email: emailEdit ? emailEdit : "",
      natureOfBusiness: natureOfBusinnessEdit?.value
        ? natureOfBusinnessEdit?.value
        : "",
      pincode: pinEdit ? pinEdit : "",
      userType: userTypeEdit?.value ? userTypeEdit?.value : "",
    };
    for (const [key, value] of Object.entries(payload)) {
      if (!value) {
        delete payload[key];
      }
    }
    this.props.customerEditData(
      payload,
      this.props.customerviewData._id,
      (res) => {
        if (
          res &&
          res.message === "Customer Details have been updated successfully"
        ) {
          NotificationManager.success(res.message, "Customer Updated!!");
          this.customerViewDataEdit();
        } else {
          NotificationManager.error(res.message, "Error");
        }
      }
    );
  }
  componentDidUpdate() {
    if (
      this.props.customerviewData._id !== this.state.id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.customerViewDataEdit();
      });
    }
  }

  componentWillUnmount = () => {
    this.setState({
      id: "",
      name: "",
      natureOfBusinness: "",
      email: "",
      mobNo: "",
      addrs: "",
      state: "",
      pin: "",
      src: "",
      membSince: "",
      accNo: "",
      IFSC: "",
    });
  };

  verify = () => {
    let data = {
      userId: this.state.id,
      bankAccount: this.state.accNo,
      ifsc: this.state.IFSC,
    };
    let responseDataStore = {};
    this.props.verifyBankAccount(data, (res) => {
      if (res.value) {
        NotificationManager.success(res.message, "Bank Account Verified");
        responseDataStore.accountNo = res.value.accountNo;
        responseDataStore.accountVerificationStatus =
          res.value.accountVerificationStatus;
        responseDataStore.bankName = res.value.bankName;
        responseDataStore.branch = res.value.branch;
        responseDataStore.city = res.value.city;
        responseDataStore.micr = res.value.micr;
        responseDataStore.refId = res.value.refId;
        responseDataStore.username = res.value.username;
        responseDataStore.utr = res.value.utr;
        responseDataStore.createdAt = res.value.createdAt;
        this.setState({ bankDetailsVerified: responseDataStore }, () => {
          this.setState(
            { sendVerifyModal: !this.state.sendVerifyModal },
            () => {
              this.customerViewDataEdit();
            }
          );
        });
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  };
  toggleSendVerifyModal = () => {
    this.setState({
      sendVerifyModal: !this.state.sendVerifyModal,
    });
  };

  triggerKycFormSms = () => {
    let data = {
      userid: this.state.id,
    };
    this.props.triggerKycForm(data, (res) => {
      if (res && res?.value?.result?.type === "success") {
        NotificationManager.success(res.message, "KYC SMS sent to user");
        this.props.history.push("/Customers");
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  };

  displayImageConverter = (file, callback) => {
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        callback(reader.result);
      };
    }
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    return (
      <div className="productPopupStyleImg">
        <img
          style={{ overflow: "hidden" }}
          src={this.state.displayImage}
          alt="Cancelled cheque"
        />
      </div>
    );
  };
  submitKyc = () => {
    let formData = new FormData();
    // formData.append("file", this.state.kycImage);
    // formData.append("type", this.state.kycDocumentType);
    this.state.formDataIdentity.forEach((ele) => {
      formData.append(this.state.kycDocumentType, ele);
    });
    this.state.formDataAddress.forEach((ele) => {
      formData.append("addressProof", ele);
    });

    this.props.uploadKycDocuments(formData, this.state.id, (res) => {
      if (
        res &&
        res.message === "Customer documents have been uploaded successfully"
      ) {
        this.triggerKycFormSms();
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  };
  toBase64(file) {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({ name: file.name, selectedFile: reader.result });
      reader.onerror = (error) => reject(error);
    });
  }

  getKycDocuments = async (files, name) => {
    const filePathsPromises = [];
    Object.values(files).forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    const mappedFiles = filePaths.map((base64File) => ({
      selectedFile: base64File.selectedFile,
      name: base64File.name,
    }));
    if (name === "identityProof") {
      this.setState({
        kycDisplayImage: [...this.state.kycDisplayImage, ...mappedFiles],
      });
    } else {
      this.setState({
        addressProofImage: [...this.state.addressProofImage, ...mappedFiles],
      });
    }
  };
  removeImage = (index) => {
    let filteredDisplayImage = this.state.kycDisplayImage
      ? this.state.kycDisplayImage
      : [];
    let formDataFilterIdentity = this.state.formDataIdentity
      ? this.state.formDataIdentity
      : [];
    filteredDisplayImage.splice(index, 1);
    formDataFilterIdentity.splice(index, 1);
    this.setState({
      kycDisplayImage: filteredDisplayImage,
      formDataIdentity: formDataFilterIdentity,
    });
  };
  removeImageAddress = (index) => {
    let filteredDisplayImageAddress = this.state.addressProofImage
      ? this.state.addressProofImage
      : [];
    let formDataFilterAddress = this.state.formDataAddress
      ? this.state.formDataAddress
      : [];
    filteredDisplayImageAddress.splice(index, 1);
    formDataFilterAddress.splice(index, 1);
    this.setState({
      addressProofImage: filteredDisplayImageAddress,
      formDataAddress: formDataFilterAddress,
    });
  };
  render() {
    let { businessType } = this.state;
    var userType_localStorage = JSON.parse(
      localStorage?.getItem("authUser")
    )?.userType;
    let cancelledChequeImage = React.createRef(null);
    const getFIle = (file) => {
      this.setState({ chequeImage: file[0] }, () => {
        this.displayImageConverter(this.state.chequeImage, (img) => {
          this.setState({ displayImage: img });
        });
        let formData = new FormData();
        formData.append("cancelledCheque", this.state.chequeImage);
        this.props.uploadCancelledCheque(formData, this.state.id, (res) => {});
      });
    };
    return (
      <div className="mainParentSectionDiv">
        <Modal
          isOpen={this.state.sendVerifyModal}
          toggle={this.toggleSendVerifyModal}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.toggleSendVerifyModal}>Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col>Account Number :</Col>
              <Col>{this.state.bankDetailsVerified.accountNo}</Col>
            </Row>
            <Row>
              <Col>Status :</Col>
              <Col>
                {this.state.bankDetailsVerified.accountVerificationStatus}
              </Col>
            </Row>
            <Row>
              <Col>Bank Name :</Col>
              <Col>{this.state.bankDetailsVerified.bankName}</Col>
            </Row>
            <Row>
              <Col>City :</Col>
              <Col>{this.state.bankDetailsVerified.branch}</Col>
            </Row>
            <Row>
              <Col>MICR :</Col>
              <Col>{this.state.bankDetailsVerified.micr}</Col>
            </Row>
            <Row>
              <Col>Ref ID :</Col>
              <Col>{this.state.bankDetailsVerified.refId}</Col>
            </Row>
            <Row>
              <Col>User Name :</Col>
              <Col>{this.state.bankDetailsVerified.username}</Col>
            </Row>
            <Row>
              <Col>UTR :</Col>
              <Col>{this.state.bankDetailsVerified.utr}</Col>
            </Row>
            <Row>
              <Col>Created At :</Col>
              <Col>
                {moment(this.state.bankDetailsVerified.createdAt).format(
                  "DD-MM-YYYY"
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <Button color="secondary" onClick={this.toggleSendVerifyModal}>
                OK
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Customer Information</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label for="fullName">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      type="name"
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      value={
                        this.state?.nameEdit
                          ? this.state?.nameEdit
                          : this.state?.nameEdit === undefined
                          ? this.state.name
                          : ""
                      }
                      onChange={(e) =>
                        this.onChange("nameEdit", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={7} md={7} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Nature Of Business</Label>
                    <SelectComponent
                      value={
                        this.state?.natureOfBusinnessEdit
                          ? this.state?.natureOfBusinnessEdit
                          : this.state?.natureOfBusinnessEdit === undefined
                          ? this.state.natureOfBusinness
                          : ""
                      }
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      onChange={(e) =>
                        this.onChange("natureOfBusinnessEdit", e)
                      }
                      options={businessType}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      value={
                        this.state?.emailEdit
                          ? this.state?.emailEdit
                          : this.state?.emailEdit === undefined
                          ? this.state?.email
                          : ""
                      }
                      onChange={(e) =>
                        this.onChange("emailEdit", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Mobile Number</Label>
                    <Input
                      id="mobileno"
                      name="mobileno"
                      type="text"
                      maxLength={10}
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      value={
                        this.state?.mobNoEdit
                          ? this.state?.mobNoEdit
                          : this.state?.mobNoEdit === undefined
                          ? this.state.mobNo
                          : ""
                      }
                      onChange={(e) => {
                        let regularExpressionNumber = /^[0-9]*$/;
                        if (regularExpressionNumber.test(e.target.value)) {
                          this.onChange("mobNoEdit", e.target.value);
                        } else {
                          this.onChange("mobNoEdit", "");
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">User Type</Label>

                    <SelectComponent
                      disabled={this.state.isEditMode == "false" ? true : false}
                      // defaultValue={this.props?.customerviewData?.userType}
                      value={
                        this.state?.userTypeEdit
                          ? this.state?.userTypeEdit
                          : this.state?.userTypeEdit === undefined
                          ? this.state.userType
                          : ""
                      }
                      onChange={(e) => this.onChange("userTypeEdit", e)}
                      options={userTypeList}
                      userType={userType_localStorage}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <br />
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Basic Information</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={6} md={6} className="mb-15">
                  <FormGroup>
                    <Label for="address">Address</Label>
                    <Input
                      id="address"
                      name="address"
                      type="text"
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      value={
                        this.state?.addrsEdit
                          ? this.state?.addrsEdit
                          : this.state?.addrsEdit === undefined
                          ? this.state.addrs
                          : ""
                      }
                      onChange={(e) =>
                        this.onChange("addrsEdit", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={6} className="mb-15">
                  <FormGroup>
                    <Label for="state">State</Label>

                    <SelectComponent
                      disabled={this.state.isEditMode == "false" ? true : false}
                      value={
                        this.state?.stateEdit
                          ? this.state?.stateEdit
                          : this.state?.stateEdit === undefined
                          ? this.state.state
                          : ""
                      }
                      onChange={(e) => this.onChange("stateEdit", e)}
                      options={stateList}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="exampleEmail">Pin</Label>
                    <Input
                      id="pin"
                      name="pin"
                      type="text"
                      maxLength={6}
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      value={
                        this.state?.pinEdit
                          ? this.state?.pinEdit
                          : this.state?.pinEdit === undefined
                          ? this.state.pin
                          : ""
                      }
                      onChange={(e) => {
                        let regularExpressionNumber = /^[0-9]*$/;
                        if (regularExpressionNumber.test(e.target.value)) {
                          this.onChange("pinEdit", e.target.value);
                        } else {
                          this.onChange("pinEdit", "");
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="source">Source</Label>
                    <Input
                      id="source"
                      name="source"
                      type="text"
                      disabled={true}
                      value={this.state.src}
                      onChange={(e) => this.onChange("src", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="member">Member Since</Label>
                    <Input
                      id="member"
                      name="member"
                      type="text"
                      disabled={true}
                      value={this.state.memberSince}
                      onChange={(e) =>
                        this.onChange("memberSince", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="btn lightBtn"
                  onClick={() => this.props.history.push("/Customers")}
                >
                  Back
                </button>
                <div>
                  <button
                    className="btn darkBtn"
                    disabled={this.state.isEditMode === "false" ? true : false}
                    onClick={(e) => {
                      this.onChangeEdit(e);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="pd-15 bg-white borderedDiv mt-3">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Account Information</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={10} md={10}>
                  <Row>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="accno">
                          Account Number<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="accno"
                          name="accno"
                          placeholder="Bank Acc no."
                          type="text"
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          value={this.state.accNo}
                          onChange={(e) =>
                            this.onChange("accNo", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="accholdername">
                          IFSC Code<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="accholdername"
                          name="accholdername"
                          placeholder="IFSC Code"
                          type="text"
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          value={this.state.IFSC}
                          onChange={(e) =>
                            this.onChange("IFSC", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    {this.state.accountVerified === "Verified" ? (
                      <Col xs={12} sm={12} md={12} className="mb-15 pdrl-15">
                        <div className="custTableListingDivStyle pdrl-15">
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              User Name :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.userName}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              Bank Name :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.bankName}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              Bank Branch :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.bankBranch}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              MICR :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.micr}
                            </Col>
                          </Row>
                          <Row className="pd-10 borderedPlainBottomDiv ">
                            <Col xs={12} sm={6} md={6}>
                              Status :
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                              {this.state.accountVerified}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {this.state.bankVerificationDetails ? null : (
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Button
                          className="btn lightBtn"
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onClick={() => this.verify()}
                        >
                          Verify
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
                {this.state.bankVerificationDetails ? (
                  <Col xs={12} sm={2} md={2}>
                    <div className="d-flex align-items-center">
                      <div className="ml-3">
                        <Label for="accholdername">Cancelled Cheque</Label>
                        <p className="m-0">{this.state.chequeImageName}</p>
                        <div
                          onClick={() => cancelledChequeImage.current?.click()}
                          className="btn darkBtn"
                        >
                          Upload
                        </div>
                        <input
                          multiple
                          accept=".jpg, .jpeg, .png"
                          ref={cancelledChequeImage}
                          hidden={true}
                          type="file"
                          className="btn darkBtn"
                          onChange={(e) => getFIle(e.target.files)}
                        />
                      </div>
                    </div>
                    <br />
                    {this.state.displayImage !== "" ? (
                      <div>
                        <div>Preview Image</div>
                        <div className="custCancelledChequeImageDiv">
                          <img
                            style={{ overflow: "hidden" }}
                            src={this.state.displayImage}
                            alt="Cancelled cheque"
                          />
                        </div>
                      </div>
                    ) : null}
                  </Col>
                ) : null}
              </Row>
            </Form>
          </div>
        </div>
        <div className="pd-15 bg-white borderedDiv mt-3">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Kyc Document Upload & Verification</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label for="kycdocumenttype">
                      Identity Proof<span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      id="kycdocumenttype"
                      name="kycdocumenttype"
                      placeholder="Kyc Document type"
                      type="select"
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      value={this.state.kycDocumentType}
                      onChange={(e) =>
                        this.onChange("kycDocumentType", e.target.value)
                      }
                    >
                      <option value=""></option>
                      <option value="aadharCard">Aadhar Card</option>
                      <option value="panCard">Pan Card</option>{" "}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label>
                      Choose file<span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      id="choosefile"
                      name="choosefile"
                      placeholder="Choose file"
                      multiple
                      type="file"
                      accept=".png, .jpg,.jpeg ,.doc,.docx,.Mp4,.xml,.pdf,.dotm,.wps,.rtf,.odt,.htm,.html,.WEBM,.dotx"
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      onChange={(e) =>
                        this.setState(
                          {
                            formDataIdentity: Object.values(e.target.files),
                          },
                          () => {
                            this.getKycDocuments(
                              e.target.files,
                              "identityProof"
                            );
                          }
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={2} md={2} className="mb-15"></Col>
                {this.state.kycDisplayImage &&
                this.state.kycDisplayImage.length > 0
                  ? this.state.kycDisplayImage.map((ele, index) => (
                      <Col xs={12} sm={2} md={2} className="mb-15">
                        <div>
                          {!ele.disable ? (
                            <a
                              class="remove-image"
                              onClick={() => this.removeImage(index)}
                              style={{
                                display: "inline",
                                cursor: "pointer",
                                float: "right",
                              }}
                            >
                              X
                            </a>
                          ) : (
                            ""
                          )}
                          <div className="productImageDivStyle">
                            <img src={ele.selectedFile} alt={ele.name} />
                          </div>
                        </div>
                      </Col>
                    ))
                  : ""}
              </Row>
              <Row>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label for="kycdocumenttype">
                      Address Proof<span style={{ color: "red" }}>*</span>
                    </Label>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={5} md={5} className="mb-15">
                  <FormGroup>
                    <Label>
                      Choose file<span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      id="choosefiles"
                      multiple
                      name="choosefiles"
                      placeholder="Choose file"
                      type="file"
                      accept=".png, .jpg,.jpeg ,.doc,.docx,.Mp4,.xml,.pdf,.dotm,.wps,.rtf,.odt,.htm,.html,.WEBM,.dotx"
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                      onChange={(e) =>
                        this.setState(
                          {
                            formDataAddress: Object.values(e.target.files),
                          },
                          () => {
                            this.getKycDocuments(
                              e.target.files,
                              "addressProof"
                            );
                          }
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={2} md={2} className="mb-15">
                  <br />
                  <div className="mt-8">
                    <div>
                      <Button
                        className="btn lightBtn"
                        disabled={
                          this.props?.customerviewData?.userAccountStatus ===
                            "Completed" ||
                          this.props?.customerviewData?.userAccountStatus ===
                            "Awaiting User Confirmation"
                            ? true
                            : !(
                                (this.state.kycDisplayImage.length > 0 &&
                                  this.state.kycDocumentType) ||
                                this.state.addressProofImage.length > 0
                              )
                        }
                        onClick={() => this.submitKyc()}
                      >
                        Submit for Kyc
                      </Button>
                    </div>
                  </div>
                </Col>
                {this.state.addressProofImage &&
                this.state.addressProofImage.length > 0
                  ? this.state.addressProofImage.map((ele, index) => (
                      <Col xs={12} sm={2} md={2} className="mb-15">
                        <div>
                          {!ele.disable ? (
                            <a
                              class="remove-image"
                              onClick={() => this.removeImageAddress(index)}
                              style={{
                                display: "inline",
                                cursor: "pointer",
                                float: "right",
                              }}
                            >
                              X
                            </a>
                          ) : (
                            ""
                          )}
                          <div className="productImageDivStyle">
                            <img src={ele.selectedFile} alt={ele.name} />
                          </div>
                        </div>
                      </Col>
                    ))
                  : ""}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerviewData: state.rfc.customerView,
  };
};
export default connect(mapStateToProps, {
  customerViewData,
  customerEditData,
  verifyBankAccount,
  triggerKycForm,
  uploadCancelledCheque,
  uploadKycDocuments,
})(Customerdetails);
