import { NotificationManager } from 'react-notifications';

var QRCode = require('qrcode')

export const GenerateQr = ({ data }) => {

    function GenerateQrCode() {
        
        let dataToEncode = `${process.env.REACT_APP_BASE_URL}/confirmOrderDelivery/${data}`;
        
        QRCode.toDataURL(dataToEncode, { scale: 25 }, function (err, res) {
            if (!err) {
                let imgEl = document.createElement("a")
                imgEl.href = res;
                imgEl.download = "qrCode.png"
                imgEl.click()
            } else NotificationManager.error(err?.toString()?.split(" at")[0], "Error");
        })
    }

    return (
        <>
            <button
                className="btn darkBtn mt-3"
                onClick={(e) => {
                    e.preventDefault();
                    GenerateQrCode()
                }
                }
            >
                Generate Qr Code
            </button>
        </>
    )
}



