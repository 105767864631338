import React, { Component } from "react";
import { Row, Col, Progress, Button } from "reactstrap";
import Bluechart from "../../../images/bluechart.png";
import Redchart from "../../../images/redchart.png";
import Greenchart from "../../../images/greenchart.png";
import { connect } from "react-redux";
import { fetchDashboardData, fetchUserCountData } from "../../../store/actions";
import { Link } from "react-router-dom";
class Customers extends Component {
  componentDidMount = () => {
    this.props.fetchDashboardData();
    this.props.fetchUserCountData();
  };

  checkitout = (data) => {
    // console.log(data);
    if (data != null && data != undefined) {
      return data;
    }
  };
  render() {
    return (
      <div className="dashInnerMainDiv">
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <h4>Customers</h4>
          <div>
            <svg
              width="24"
              height="6"
              viewBox="0 0 24 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6Z"
                fill="#5E5E5E"
              />
              <path
                d="M12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6Z"
                fill="#5E5E5E"
              />
              <path
                d="M21 6C22.6569 6 24 4.65685 24 3C24 1.34315 22.6569 0 21 0C19.3431 0 18 1.34315 18 3C18 4.65685 19.3431 6 21 6Z"
                fill="#5E5E5E"
              />
            </svg>
          </div>
        </div>
        <div>
          <Row>
            <Col xs={12} sm={7} md={7}>
              <div className="dashInnerDivs">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 p-head">New user requests</p>
                  <Link to={"/Customers"}>
                    <Button>View all</Button>
                  </Link>
                </div>
                <br />
                <div className="d-flex align-items-center">
                  <div>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.26"
                        d="M34 0H4C1.79086 0 0 1.79086 0 4V34C0 36.2091 1.79086 38 4 38H34C36.2091 38 38 36.2091 38 34V4C38 1.79086 36.2091 0 34 0Z"
                        fill="#980033"
                      />
                      <path
                        d="M23.367 8.00098C23.4997 8.04858 23.6299 8.10301 23.757 8.164C23.94 8.24912 24.0974 8.38086 24.2134 8.54602C24.3294 8.71118 24.4 8.90395 24.418 9.10498C24.458 9.74198 24.428 10.384 24.428 11.034H13.022C13.022 10.381 12.981 9.734 13.035 9.091C13.0611 8.84702 13.1614 8.61688 13.3223 8.43164C13.4832 8.2464 13.6971 8.11495 13.935 8.05499C13.981 8.03899 14.025 8.02001 14.07 8.00201L23.367 8.00098Z"
                        fill="#980033"
                      />
                      <path
                        d="M14.074 29.927C13.9162 29.8663 13.7626 29.7954 13.614 29.715C13.4372 29.613 13.2893 29.4677 13.1843 29.2927C13.0793 29.1177 13.0207 28.9189 13.014 28.715C12.992 28.115 13.0089 27.515 13.0089 26.903H24.432C24.432 27.548 24.47 28.1909 24.42 28.8259C24.3973 29.0594 24.306 29.2811 24.1576 29.4628C24.0091 29.6445 23.8103 29.7781 23.586 29.8469L23.37 29.9299L14.074 29.927ZM18.7279 27.7269H17.7279C17.6404 27.7236 17.5532 27.7379 17.4714 27.7689C17.3895 27.8 17.3147 27.8472 17.2514 27.9077C17.1882 27.9683 17.1377 28.0409 17.1031 28.1213C17.0685 28.2018 17.0504 28.2884 17.05 28.376C17.0508 28.4636 17.0693 28.5502 17.1042 28.6305C17.1392 28.7108 17.1899 28.7833 17.2535 28.8436C17.317 28.904 17.392 28.951 17.474 28.9818C17.5561 29.0126 17.6435 29.0266 17.731 29.0229C18.393 29.0283 19.055 29.0283 19.717 29.0229C19.8045 29.0268 19.8919 29.0133 19.9741 28.983C20.0563 28.9528 20.1316 28.9065 20.1958 28.8468C20.2599 28.7871 20.3115 28.7153 20.3476 28.6355C20.3837 28.5557 20.4035 28.4695 20.406 28.382C20.4064 28.2926 20.3885 28.204 20.3533 28.1218C20.3181 28.0396 20.2665 27.9656 20.2015 27.9042C20.1365 27.8428 20.0597 27.7954 19.9756 27.7649C19.8916 27.7344 19.8022 27.7215 19.713 27.7269C19.383 27.7209 19.0559 27.725 18.7279 27.725V27.7269Z"
                        fill="#980033"
                      />
                      <path
                        d="M13.018 12.3569H24.435V25.5689H13.018V12.3569Z"
                        fill="#980033"
                      />
                    </svg>
                  </div>
                  <div className="progressDiv">
                    <p className="mb-0">Mobile App</p>
                    <Progress value={50} />
                    <p className="mb-0">
                      {this.props.customerCountData.user?.countMobile}
                    </p>
                  </div>
                </div>
                <br />
                <div className="d-flex align-items-center">
                  <div>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.26"
                        d="M34 0H4C1.79086 0 0 1.79086 0 4V34C0 36.2091 1.79086 38 4 38H34C36.2091 38 38 36.2091 38 34V4C38 1.79086 36.2091 0 34 0Z"
                        fill="#980033"
                      />
                      <path
                        d="M10.088 8.10693H28.073C28.084 8.11493 28.0951 8.12892 28.1071 8.13092C28.6622 8.22235 29.1633 8.51708 29.5131 8.95776C29.8628 9.39845 30.0361 9.95357 29.999 10.515C29.965 11.815 29.991 13.1149 29.991 14.4149V14.64H8.177C8.177 13.117 8.15903 11.611 8.18603 10.106C8.2002 9.82421 8.27375 9.54858 8.40198 9.2973C8.5302 9.04602 8.71023 8.82476 8.93005 8.64795C9.26346 8.37159 9.66211 8.18531 10.088 8.10693ZM25.629 11.369C25.6245 11.5127 25.6484 11.6559 25.6993 11.7904C25.7503 11.9249 25.8273 12.048 25.926 12.1526C26.0247 12.2572 26.1431 12.3413 26.2744 12.4001C26.4057 12.4588 26.5473 12.4911 26.691 12.4949C26.9811 12.5019 27.2621 12.394 27.473 12.1948C27.6839 11.9955 27.8076 11.7209 27.817 11.4309C27.8224 11.2854 27.7988 11.1402 27.7476 11.0039C27.6964 10.8676 27.6186 10.7428 27.5188 10.6367C27.419 10.5307 27.299 10.4455 27.166 10.3861C27.033 10.3268 26.8896 10.2944 26.744 10.291C26.4538 10.2875 26.1739 10.3988 25.9655 10.6007C25.757 10.8026 25.6368 11.0788 25.631 11.369H25.629ZM22.348 12.4949C22.6389 12.496 22.9186 12.3824 23.1263 12.1788C23.3341 11.9751 23.4533 11.6978 23.458 11.4069C23.4599 11.1157 23.3462 10.8356 23.1418 10.6281C22.9375 10.4206 22.6593 10.3026 22.368 10.2999C22.077 10.2973 21.7967 10.41 21.5886 10.6136C21.3806 10.8171 21.2617 11.0949 21.2581 11.3859C21.2575 11.6769 21.3716 11.9565 21.5756 12.1641C21.7796 12.3716 22.057 12.4905 22.348 12.4949ZM19.08 11.4059C19.0816 11.1154 18.9682 10.836 18.7645 10.6288C18.5609 10.4216 18.2836 10.3034 17.993 10.2999C17.8493 10.299 17.7068 10.3264 17.5737 10.3806C17.4406 10.4348 17.3196 10.5147 17.2174 10.6158C17.1152 10.7168 17.0339 10.8371 16.9783 10.9695C16.9226 11.102 16.8937 11.2442 16.8931 11.3879C16.8912 11.6785 17.0043 11.9579 17.2078 12.1653C17.4112 12.3727 17.6885 12.4912 17.979 12.4949C18.2692 12.4955 18.5479 12.3813 18.7543 12.1774C18.9607 11.9734 19.0782 11.6961 19.0811 11.4059H19.08Z"
                        fill="#980033"
                      />
                      <path
                        d="M8.17102 16.8508H29.989V17.0709C29.989 20.5935 29.989 24.1165 29.989 27.6398C30.0092 28.1446 29.8544 28.6409 29.5508 29.0447C29.2472 29.4485 28.8135 29.735 28.323 29.8558C28.1228 29.9028 27.9176 29.9256 27.7119 29.9238C21.9586 29.9272 16.2057 29.9272 10.453 29.9238C10.1506 29.935 9.84908 29.8837 9.56738 29.7731C9.28569 29.6625 9.02979 29.495 8.8158 29.281C8.6018 29.067 8.43422 28.8112 8.32361 28.5295C8.213 28.2478 8.16176 27.9463 8.17297 27.6439C8.17297 24.1212 8.17297 20.5982 8.17297 17.0748L8.17102 16.8508Z"
                        fill="#980033"
                      />
                    </svg>
                  </div>
                  <div className="progressDiv">
                    <p className="mb-0">Website</p>
                    <Progress value={80} className="bg-new" />
                    <p className="mb-0">
                      {this.props.customerCountData.user?.countWeb}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={5} md={5}>
              <div className="dashInnerDivs">
                <div className="d-flex align-items-center">
                  <img src={Bluechart} alt="blue chartimage" />
                  <div>
                    <p className="mb-0">Buyers</p>
                    <p className="mb-0">
                      {this.checkitout(this.props.dashboardData?.countBuyer)}
                    </p>
                  </div>
                </div>
                <br />
                <div className="d-flex align-items-center">
                  <img src={Greenchart} alt="blue chartimage" />
                  <div>
                    <p className="mb-0">Seller</p>
                    <p className="mb-0">
                      {this.checkitout(this.props.dashboardData?.countSeller)}
                    </p>
                  </div>
                </div>
                <br />
                <div className="d-flex align-items-center">
                  <img src={Redchart} alt="blue chartimage" />
                  <div>
                    <p className="mb-0">Transporters</p>
                    <p className="mb-0">
                      {this.checkitout(this.props.dashboardData?.countLogistic)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    dashboardData: state.rfu.dashboarddata,
    customerCountData: state.rfu.userCount,
  };
};

export default connect(mapStateToProps, {
  fetchDashboardData,
  fetchUserCountData,
})(Customers);
// export default Customers
