export const userTypeList = [
    {
        value: "seller",
        label: "Seller",
    },
    {
        value: "buyer",
        label: "Buyer",
    },
    {
        value: "transporter",
        label: "Transporter",
    },
]