import { types } from "./actionTypes";

export const userLogin = (data, history, callback) => ({
  type: types.USER_LOGIN,
  payload: { data, history },
  callback,
});
export const logoutUser = () => ({
  type: types.USER_LOGOUT,
});

export const fetchprofiledata = () => ({
  type: types.PROFILE_DATA,
});

export const customerViewData = (id, callback) => {
  return {
    type: types.CUSTOMER_VIEW_DATA,
    id,
    callback,
  };
};
// export const customerSearchdata = (id) => {
//   return {
//     type: types.CUSTOMER_SEARCH_DATA,
//     id,
//   };
// };
export const customerEditData = (payload, id, callback) => {
  return {
    type: types.CUSTOMER_EDIT_DATA,
    payload,
    id,
    callback,
  };
};

export const sortCustomerData = (
  offset,
  limit,
  searchCustomer,
  status,
  source,
  userName,
  sort
) => {
  return {
    type: types.CUSTOMER_SORT_DATA,
    offset,
    limit,
    searchCustomer,
    status,
    source,
    userName,
    sort,
  };
};
export const userRequestData = (offset, limit, userAccountSource) => {
  return {
    type: types.USER_REQUEST_DATA,
    offset,
    limit,
    userAccountSource,
  };
};
export const userViewData = (id) => {
  return {
    type: types.USER_VIEW_DATA,
    id,
  };
};
export const userRequestDeleteData = (id, callback) => {
  return {
    type: types.USER_REQUEST_DELETE_DATA,
    id,
    callback,
  };
};
export const userRequestEditData = (payload, id, callback) => {
  return {
    type: types.USER_REQUEST_EDIT_DATA,
    payload,
    id,
    callback,
  };
};
// export const userFilterData = (id) => {
//   return {
//     type: types.USER_REQUEST_FILTER_DATA,
//     id,
//   };
// };
export const fetchDashboardData = () => ({
  type: types.DASHBOARD_DATA,
});

export const fetchproductSortData = (
  offset,
  limit,
  maxPrice,
  minPrice,
  category,
  size,
  seller,
  SelectedSku
) => {
  return {
    type: types.PRODUCT_SORT_DATA,
    offset,
    limit,
    maxPrice,
    minPrice,
    category,
    size,
    seller,
    SelectedSku,
  };
};
export const fetchProductFilterData = (payload) => {
  return {
    type: types.PRODUCT_FILTER_DATA,
    payload,
  };
};
export const fetchProductFilterSkuData = (id) => {
  return {
    type: types.PRODUCT_FILTER_DATA_BY_SKU,
    id,
  };
};

export const fetchProductViewData = (id, callback) => {
  return {
    type: types.PRODUCT_VIEW_DATA,
    id,
    callback,
  };
};
export const fetchCategoryData = (callback) => {
  return {
    type: types.CATEGORY_DATA,
    callback,
  };
};
export const fetchUserCountData = () => ({
  type: types.USER_COUNT_DATA,
});

export const customerDeleteData = (id, callback) => {
  return {
    type: types.CUSTOMER_DELETE_DATA,
    id,
    callback,
  };
};
export const fetchSellerData = (callback) => {
  return { type: types.SELLER_DATA, callback };
};

export const fetchProductAllFilterData = (
  category,
  seller,
  size,
  minPrice,
  maxPrice
) => {
  return {
    type: types.PRODUCT_ALL_FILTER_DATA,
    category,
    seller,
    size,
    minPrice,
    maxPrice,
  };
};

export const fetchSellerData_ashish = () => ({
  type: types.PRODUCT_SELLER_DATA,
});

export const fetchProductData = () => ({
  type: types.PRODUCT_PRODUCT_CATEGORY,
});
export const fetchSkusById = (id) => ({
  type: types.PRODUCT_GET_SKU_BYID,
  value: { categoryId: id },
});

export const AddProductApi = (data, callback) => {
  return {
    type: types.PRODUCT_CREATE,
    data,
    callback,
  };
};

export const Product_addCategory = (data, callback) => ({
  type: types.PRODUCTS_ADD_CATEGORY,
  data,
  callback,
});
export const GetSkusList = () => ({
  type: types.PRODUCTS_GET_ALL_SKUS,
});
export const Product_addSku = (data, callback) => ({
  type: types.PRODUCTS_ADD_SKU,
  data,
  callback,
});
export const addCustomers = (data, callback) => ({
  type: types.CUSTOMER_ADDITION,
  data,
  callback,
});
export const productDeleteData = (id, callback) => {
  return {
    type: types.PRODUCT_DELETE_DATA,
    id,
    callback,
  };
};
export const productEditData = (payload, id, callback) => {
  return {
    type: types.PRODUCT_EDIT_DATA,
    payload,
    id,
    callback,
  };
};

export const verifyBankAccount = (payload, callback) => {
  return {
    type: types.VERIFY_BANK_ACCOUNT,
    payload,
    callback,
  };
};

export const triggerOtp = (payload, callback) => {
  return {
    type: types.TRIGGER_OTP,
    payload,
    callback,
  };
};

export const VerifyOtp = (payload, callback) => {
  return {
    type: types.VERIFY_OTP,
    payload,
    callback,
  };
};

export const triggerKycForm = (payload, callback) => {
  return {
    type: types.TRIGGER_KYC_FORM,
    payload,
    callback,
  };
};
export const getSkuList = (offset, limit) => {
  return {
    type: types.SKU_DATA,
    offset,
    limit,
  };
};

export const skuDeleteData = (id, callback) => {
  return {
    type: types.SKU_DELETE_DATA,
    id,
    callback,
  };
};

export const skuEditData = (id, payload, callback) => {
  return {
    type: types.SKU_EDIT_DATA,
    id,
    payload,
    callback,
  };
};
export const skuViewData = (id, callback) => {
  return {
    type: types.SKU_VIEW_DATA,
    id,
    callback,
  };
};

export const categoryBySku = (payload, callback) => {
  return {
    type: types.CATEGORY_BY_SKU_DATA,
    payload,
    callback,
  };
};

export const getCategoryList = (offset, limit) => {
  return {
    type: types.CATEGORYS_DATA,
    offset,
    limit,
  };
};

export const categoryDeleteData = (id, callback) => {
  return {
    type: types.CATEGORY_DELETE_DATA,
    id,
    callback,
  };
};

export const categoryViewData = (id, callback) => {
  return {
    type: types.CATEGORY_VIEW_DATA,
    id,
    callback,
  };
};
export const categoryEditData = (id, payload, callback) => {
  return {
    type: types.CATEGORY_EDIT_DATA,
    id,
    payload,
    callback,
  };
};

export const uploadCancelledCheque = (data, userId, callback) => ({
  type: types.UPLOAD_CANCELLED_CHEQUE,
  data,
  userId,
  callback,
});

export const uploadKycDocuments = (data, userId, callback) => ({
  type: types.UPLOAD_KYC_DOCUMENT,
  data,
  userId,
  callback,
});

export const getSosRequestData = (data, callback) => ({
  type: types.SOS_REQUEST,
  data: data,
  callback: callback,
});

export const UpdateSosRequestData = (data, payload, callback) => {
  return { type: types.SOS_UPDATE_REQUEST, data, payload, callback };
};

export const getOrdersData = (data, callback) => ({
  type: types.ORDERS_DATA,
  data: data,
  callback: callback,
});

export const fetchOrderViewData = (id, callback) => {
  return {
    type: types.ORDER_VIEW_DATA,
    id,
    callback,
  };
};
export const orderCancel = (id, callback) => {
  return {
    type: types.ORDER_CANCEL,
    id,
    callback,
  };
};

export const buyerSearch = (offset, limit, userName, callback) => {
  return {
    type: types.BUYER_SEARCH,
    offset,
    limit,
    userName,
    callback,
  };
};
export const orderUpdate = (data, orderId, orderActions, callback) => {
  return {
    type: types.ORDER_UPDATE,
    data,
    orderId,
    orderActions,
    callback,
  };
};
export const addTransport = (id, data, callback) => {
  return {
    type: types.ADD_TRANSPORT,
    id,
    data,
    callback,
  };
};

export const issuePaymentLink = (id, callback) => {
  return {
    type: types.ISSUE_PAYMENT_LINK,
    id,
    callback,
  };
};
export const intiateRefund = (payload, callback) => {
  return {
    type: types.INTIATE_REFUND,
    payload,
    callback,
  };
};
export const resendPayment = (payload, callback) => {
  return {
    type: types.RESEND_PAYMENT,
    payload,
    callback,
  };
};
export const fetchEnum = () => {
  return {
    type: types.ENUM,
  };
};
export const enumEditData = (payload, id, callback) => {
  return {
    type: types.ENUM_EDIT,
    payload,
    id,
    callback,
  };
};
export const orderDiscount = (payload, id, callback) => {
  return {
    type: types.ORDER_DISCOUNT,
    payload,
    id,
    callback,
  };
};
export const customerVerify = (id, callback) => {
  return {
    type: types.CUSTOMER_VERIFICATION,
    id,
    callback,
  };
};
