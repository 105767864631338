import React from "react";
import { Redirect } from "react-router-dom";
import Product from "../pages/Product/index";
import Profile from "../pages/Profile/Profile";
import LoginPage from "../pages/Login/LoginPage";
import SignupPage from "../pages/Login/SignupPage";
import Dashboard from "../pages/Dashboard/index";
import Inventory from "../pages/Inventory/index";
import Customers from "../pages/Customers/index";
import Orders from "../pages/Orders/index";
import confirmOrderDelivery from "../pages/Orders/common/confirmOrderDelivery";
import SOSRequests from "../pages/SOSRequests/index";
import Customerdetails from "../pages/Customers/common/Customerdetails";
import UserRequests from "../pages/Customers/common/UserRequests";
import Userinformation from "../pages/Customers/common/UserInformation";
import AddProduct from "../pages/Product/common/AddProduct";
import AdminMain from "../pages/AdminDashboard/common/AdminMain";
import AdminuserInfo from "../pages/AdminDashboard/common/AdminuserInfo";
import ProductInformation from "../pages/Product/common/ProductInformation";
import Buyerform from "../pages/Customers/common/Buyerform";
import CustomerDetailsVerify from "../pages/Customers/common/CustomerDetailsVerify";
import SKU from "../pages/SKUCategory/index";
import SkuInformation from "../pages/SKUCategory/Common/SkuInformation";
import Category from "../pages/Category/index";
import CategoryInformation from "../pages/Category/common/CategoryInformation";
import VerificationSuccess from "../pages/Customers/common/VerificationSuccess";
import Ordersdetails from "../pages/Orders/common/Orderdetails"
import Settings from "../pages/Settings"

const publicPages = [
  { path: "/login", exact: true, component: LoginPage },
  { path: "/signup", exact: true, component: SignupPage },
  {
    path: "/Verification/:customerid",
    exact: true,
    component: CustomerDetailsVerify,
  },
  { path: "/confirmOrderDelivery/:orderid", exact: true, component: confirmOrderDelivery },
  {
    path: "/VerificationSuccess",
    exact: true,
    component: VerificationSuccess,
  },
];

const appRoutePages = [
  { path: "/product", exact: true, component: Product },
  { path: "/Dashboard", exact: true, component: Dashboard },
  { path: "/Customers", exact: true, component: Customers },
  { path: "/Orders", exact: true, component: Orders },
  { path: "/Order/:orderid", exact: true, component: Ordersdetails },
  { path: "/Inventory", exact: true, component: Inventory },
  { path: "/SOSRequests", exact: true, component: SOSRequests },
  { path: "/Profile", exact: true, component: Profile },
  {
    path: "/Customerdetails/:customerid",
    exact: true,
    component: Customerdetails,
  },

  { path: "/UserRequests", exact: true, component: UserRequests },
  { path: "/Buyerform", exact: true, component: Buyerform },
  { path: "/UserInfo/:userid", exact: true, component: Userinformation },
  {
    path: "/ProductInfo/:productid",
    exact: true,
    component: ProductInformation,
  },
  { path: "/AdminMain", exact: true, component: AdminMain },
  { path: "/AdminuserInfo", exact: true, component: AdminuserInfo },
  { path: "/AddProduct", exact: true, component: AddProduct },
  { path: "/Sku", exact: true, component: SKU },
  { path: "/SkuInfo/:skuid", exact: true, component: SkuInformation },
  { path: "/Category", exact: true, component: Category },
  { path: "/CategoryInfo/:catid", exact: true, component: CategoryInformation },
  { path: "/Settings", exact: true, component: Settings },

  ////always it should at bottom
  { path: "/", exact: true, component: () => <Redirect to="/Dashboard" /> },
];

export { appRoutePages, publicPages };
