import React, {Component} from 'react'
import { connect } from "react-redux";
import {Container,Row,Col} from 'reactstrap'
// import { withRouter } from "react-router-dom";

import Header from "./Header";
import MiniHeader from "./MiniHeader";
import SubHeader from "./SubHeader";
// import Footer from "./footer";

class Layout extends Component {
    constructor(props) {
      super(props);

      this.state = {
        sidebarDiv: false
      };
    }

    toggleSidebarMenu = () => {
      this.setState({
        sidebarDiv: !this.state.sidebarDiv
      });
    };

    render() {
        return (
          <React.Fragment>
            <div id="preloader">
              <div id="status">
                <div className="spinner">
                  <i className="ri-loader-line spin-icon"></i>
                </div>
              </div>
            </div>
    
            <div id="layout-wrapper">
              <Container fluid>
                <Row>
                  <Col xs={12} sm={2} md={2} className={`pd-0 ${this.state.sidebarDiv ? "width-8" : ""}`}>
                  <div className="pos-rel headerSidebar">
                    <div className={this.state.sidebarDiv ? "displayNone" : "displayBlock"}>
                      <Header/>
                    </div>
                    <div className={this.state.sidebarDiv ? "displayBlock" : "displayNone"}>
                      <MiniHeader/>
                    </div>
                    <div className="svgStyleDiv" onClick={() => this.toggleSidebarMenu()}>
                      <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.state.sidebarDiv ? "displayBlock" : "displayNone"} style={{transform: 'rotate(180deg)'}}>
                      <path d="M9.71008 1.54095L2.79187 8.45892L9.71008 15.3769" stroke="#980033" stroke-width="3" stroke-linecap="round"/>
                      </svg>
                      <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.state.sidebarDiv ? "displayNone" : "displayBlock"}>
                      <path d="M9.71008 1.54095L2.79187 8.45892L9.71008 15.3769" stroke="#980033" stroke-width="3" stroke-linecap="round"/>
                      </svg>
                    </div>
                  </div>
              </Col>
              <Col xs={12} sm={10} md={10} className={this.state.sidebarDiv ? "width-92" : ""}>
                  <div className="main-content">
                    {this.props.children}
                    {/* <Footer /> */}
                  </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        );
      }
    }

export default Layout