import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { fetchProductViewData, productEditData } from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
class ProductInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEditMode: false,
      name: "",
      category: "",
      seller: "",
      psku: "",
      size: "",
      totalQuantity: "",
      moq: "",
      price: "",
      pin: "",
      sellerRating: "",
      stateRanking: "",
      skuRanking: "",
      remark: "",
      highlight: "",
      deliveryDate: "",
      seedingDate: "",
      nettingDate: "",
      harvestDate: "",
      trackNumber: 0,
      images: [],
      loading: false,
      productImg: "",
      productImageBlob: [],
    };
  }

  componentDidMount() {
    this.productInfo();
  }

  productInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
    });

    var queryParams = this.props.computedMatch.params.productid;
    if (queryParams) {
      this.props.fetchProductViewData(queryParams, (res) => {
        this.setState(
          {
            id: this.props.productViewData._id,
            name: this.props.productViewData.name,
            category: this.props.productViewData.category?.categoryName,
            seller: this.props.productViewData.seller?.fullName,
            psku: this.props.productViewData?.sku?.skuName || "",
            size: this.props.productViewData.size,
            totalQuantity: this.props.productViewData.availableQuantity,
            moq: this.props.productViewData.moq,
            price: this.props.productViewData.rate,
            pin: this.props?.productViewData?.pinCode
              ? this.props?.productViewData?.pinCode
              : "N/A",
            sellerRating: this.props.productViewData.supplierrating,
            stateRanking: this.props.productViewData.stateRanking,
            productRanking: this.props.productViewData.rating,
            skuRanking: this.props.productViewData.skuRanking,
            remark: this.props.productViewData.remark,
            highlight: this.props.productViewData.highlights,
            deliveryDate: this.props.productViewData.deliveryDate
              ? moment(this.props.productViewData.deliveryDate).format(
                  "YYYY-MM-DD"
                )
              : "",
            images: this.props.productViewData.images
              ? this.props.productViewData.images
              : [],
          },
          () => {}
        );
      });
    }
  };
  componentDidUpdate() {
    if (
      this.props.productViewData._id &&
      this.state.id !== this.props.productViewData._id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.productInfo();
      });
    }
  }
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  productRequestEditData() {
    let deliveryDate = this.state.deliveryDate
      ? moment(this.state.deliveryDate).format("YYYY/MM/DD")
      : "";

    this.setState({ loading: true });
    let images = this.state.images;
    let payload = {
      name: this.state.name,
      categoryName: this.state.category,
      availableQuantity: this.state.totalQuantity,
      pinCode: this.state.pin,
      size: this.state.size,
      moq: this.state.moq,
      rate: this.state.price,
      rating: this.state.rating,
      supplierrating: this.state.sellerRating,
      deliveryDate: deliveryDate,
      images: images,
    };
    this.props.productEditData(
      payload,
      this.props.productViewData._id,
      (res) => {
        this.setState({ loading: false });
        if (res && res.message === "Product has been successfully updated") {
          NotificationManager.success(res.message, "Product Updated!!");
          this.props.history.push("/product");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      }
    );
  }
  removeImage = (ele) => {
    let updatedImages = this.state.images.filter((item) => item !== ele);
    this.setState({ images: updatedImages });
  };

  getFile = (file) => {
    if (file != undefined) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        this.setState({
          images: [...this.state.images, fileReader.result],
        });
      };
      fileReader.readAsDataURL(file[0]);
    }
  };

  render() {
    return (
      <div className="mainParentSectionDiv">
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Product Information</h4>
            <div>&nbsp;</div>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="sku">Product SKU</Label>
                        <Input
                          id="sku"
                          name="sku"
                          placeholder="#4 of 10 in ROHU"
                          type="text"
                          value={this.state.psku}
                          disabled={true}
                          onChange={(e) =>
                            this.onChange("psku", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="category">Category</Label>
                        <Input
                          id="category"
                          name="category"
                          placeholder="Fish"
                          value={this.state.category}
                          type="text"
                          disabled={true}
                          onChange={(e) =>
                            this.onChange("category", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="seller">Seller</Label>
                        <Input
                          id="seller"
                          name="seller"
                          type="text"
                          value={this.state.seller}
                          disabled={true}
                          onChange={(e) =>
                            this.onChange("seller", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="size">Size/Count</Label>
                        <Input
                          id="size"
                          name="size"
                          type="text"
                          value={this.state.size}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.onChange("size", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="quantity">Total Quantity Available</Label>
                        <Input
                          id="quantity"
                          name="quantity"
                          type="text"
                          value={this.state.totalQuantity}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.onChange("totalQuantity", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup className="dateRangePickerDiv">
                        <Label for="delieverydate">
                          Available for delivery
                        </Label>
                        <Input
                          id="date"
                          name="date"
                          type="date"
                          value={this.state.deliveryDate}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) => {
                            this.setState({
                              deliveryDate: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="moq">Moq</Label>
                        <Input
                          id="moq"
                          name="moq"
                          type="text"
                          value={this.state.moq}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) => this.onChange("moq", e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="price">Price</Label>
                        <Input
                          id="price"
                          name="price"
                          type="text"
                          value={this.state.price}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.onChange("price", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="pin">Pick Up PIN</Label>
                        <Input
                          id="pin"
                          name="pin"
                          type="text"
                          value={this.state.pin}
                          maxlength="6"
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) => {
                            let regularExpressionNumber = /^[0-9]*$/;
                            if (regularExpressionNumber.test(e.target.value)) {
                              this.onChange("pin", e.target.value);
                            } else {
                              this.onChange("pin", "");
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="rating">Seller Rating</Label>
                        <Input
                          id="rating"
                          name="rating"
                          type="select"
                          value={this.state.sellerRating}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.onChange("sellerRating", e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="ranking">State Ranking</Label>
                        <Input
                          id="ranking"
                          name="ranking"
                          type="text"
                          value={this.state.stateRanking}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) => {
                            let regularExpressionNumber = /^[0-9]*$/;
                            if (regularExpressionNumber.test(e.target.value)) {
                              this.onChange("stateRanking", e.target.value);
                            } else {
                              this.onChange("stateRanking", "");
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="categoryrank">SKU Ranking</Label>
                        <Input
                          id="categoryrank"
                          name="categoryrank"
                          type="text"
                          value={this.state.skuRanking}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) => {
                            let regularExpressionNumber = /^[0-9]*$/;
                            if (regularExpressionNumber.test(e.target.value)) {
                              this.onChange("skuRanking", e.target.value);
                            } else {
                              this.onChange("skuRanking", "");
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Row>
                      {/* {this.state.images.length > 0 && ( */}
                      <>
                        <Label for="productimage">Product Image</Label>
                        <Row>
                          <Col xs={12} sm={4} md={4} className="mb-15">
                            <Input
                              id="image"
                              name="image"
                              className="add-product-image"
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              onChange={(e) => {
                                this.onChange("productImg", e.target.files);
                                const productImageBase64 = [];
                                const allFiles = Object.values(e.target.files);
                                for (let ele of allFiles) {
                                  productImageBase64.push(
                                    URL.createObjectURL(ele)
                                  );
                                }
                                this.getFile(e.target.files);
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                      {/* )} */}
                      {this.state.images.length > 0 &&
                        this.state.images.map((ele) => {
                          return (
                            <Col xs={12} sm={2} md={2} className="mb-15">
                              <div>
                                <a
                                  class="remove-image"
                                  onClick={() => this.removeImage(ele)}
                                  style={{
                                    display: "inline",
                                    cursor: "pointer",
                                    float: "right",
                                  }}
                                >
                                  X
                                </a>
                                <div className="productImageDivStyle">
                                  <img src={ele} alt="productImage" />
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="remarks">Addition Remarks</Label>
                        <Input
                          id="remarks"
                          name="remarks"
                          type="text"
                          value={this.state.remark}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.onChange("remark", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={8} md={8} className="mb-15">
                      <FormGroup>
                        <Label for="highlight">Highlights</Label>
                        <Input
                          id="highlight"
                          name="highlight"
                          type="text"
                          value={this.state.highlight}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.onChange("highlight", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-between">
                    <button
                      className="btn lightBtn"
                      onClick={() => {
                        if (
                          this.props?.location?.state?.navigatePage ===
                          "inventory"
                        ) {
                          this.props.history.push("/inventory");
                        } else {
                          this.props.history.push("/product");
                        }
                      }}
                    >
                      Back
                    </button>
                    <div>
                      <button
                        className="btn darkBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          this.productRequestEditData();
                        }}
                        disabled={
                          this.state.isEditMode === "false" ? true : false
                        }
                      >
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        {this.state.loading && <span>Saving...</span>}
                        {!this.state.loading && <span> Save</span>}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    productViewData: state.rfu.productviewdata,
  };
};
export default connect(mapStateToProps, {
  fetchProductViewData,
  productEditData,
})(ProductInformation);
