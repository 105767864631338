import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import Addbuyerpic from "../../../images/addbuyerpic.png";
import { connect } from "react-redux";
import { addCustomers } from "../../../store/actions";
import SelectComponent from "../../Product/common/SelectComponent";
import { stateList } from "../../../common/StateList";
import { NotificationManager } from "react-notifications";

class Buyerform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      userType: "",
      phone: "",
      email: "",
      address: "",
      state: "",
      pincode: "",
      businessType: [
        { label: "Private limited company", value: "Private limited compan" },
        { label: "Public limited company", value: "Public limited company" },
        { label: "Sole proprierter", value: "Sole proprierter" },
        { label: "Partnership", value: "Partnership" },
      ],
      natureOfBusiness: "",
    };
  }
  onEventChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  componentDidMount() {
    if (this.props?.location?.state?.userType) {
      this.setState({
        userType: this.props.location.state.userType,
      });
    }
  }
  render() {
    let { businessType } = this.state;
    const createTheCustomer = (e) => {
      e.preventDefault();
      let {
        fullname,
        userType,
        phone,
        email,
        address,
        state,
        pincode,
        natureOfBusiness,
      } = this.state;
      let obj = {
        fullName: fullname,
        userType: userType,
        phone: phone,
        email: email,
        address: address,
        state: state,
        pincode: pincode,
        natureOfBusiness: natureOfBusiness,
      };
      this.props.addCustomers(obj, (res) => {
        console.log("CUSTOMER ADDITION", res);
        if (res.statusCode === 200) {
          NotificationManager.success(
            res.message,
            "Customer Added Successfully"
          );
          this.props.history.push(
            `/Customerdetails/${res.userid}?isEditMode=${true}`
          );
        } else {
          NotificationManager.error(res.message, "Error");
        }
      });
    };
    return (
      <div>
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Basic Details</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                {/* <Col xs={12} sm={3} md={3} className="mb-15">
                  <div className="d-flex align-items-center">
                    <img src={Addbuyerpic} alt="add buyer picture" />
                    <div className="buyerBtn">
                      <p>Profile</p>
                      <button className="btn darkBtn">Add photo</button>
                    </div>
                  </div>
                </Col> */}
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="businessname">
                      Individual/Business Name <span>*</span>
                    </Label>
                    <Input
                      id="businessname"
                      name="businessname"
                      placeholder="Type..."
                      type="text"
                      onChange={(e) =>
                        this.onEventChange("fullname", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="businessnature">
                      Nature of Business <span>*</span>
                    </Label>
                    <SelectComponent
                      disabled={false}
                      options={businessType}
                      onChange={(e) =>
                        this.onEventChange("natureOfBusiness", e.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="emailAdd">
                      Email Address <span>*</span>
                    </Label>
                    <Input
                      id="emailAdd"
                      name="emailAdd"
                      placeholder="Type..."
                      type="email"
                      onChange={(e) =>
                        this.onEventChange("email", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="add">
                      Address <span>*</span>
                    </Label>
                    <Input
                      id="add"
                      name="add"
                      placeholder="Type..."
                      type="text"
                      onChange={(e) =>
                        this.onEventChange("address", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="contactno">
                      Contact no <span>*</span>
                    </Label>
                    <Input
                      id="contactno"
                      name="contactno"
                      placeholder="Type..."
                      type="text"
                      value={this.state.phone}
                      maxLength={10}
                      onChange={(e) => {
                        let regularExpressionNumber = /^[0-9]*$/;
                        if (regularExpressionNumber.test(e.target.value)) {
                          this.onEventChange("phone", e.target.value);
                        } else {
                          this.onEventChange("phone", "");
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="state">
                      State <span>*</span>
                    </Label>
                    <SelectComponent
                      disabled={false}
                      defaultValue={this.props?.customerviewData?.state}
                      onChange={(e) => this.onEventChange("state", e.value)}
                      options={stateList}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} className="mb-15">
                  <FormGroup>
                    <Label for="pinno">
                      PIN <span>*</span>
                    </Label>
                    <Input
                      id="pinno"
                      name="pinno"
                      placeholder="Type..."
                      type="text"
                      maxlength="6"
                      value={this.state.pincode}
                      onChange={(e) => {
                        let regularExpressionNumber = /^[0-9]*$/;
                        if (regularExpressionNumber.test(e.target.value)) {
                          this.onEventChange("pincode", e.target.value);
                        } else {
                          this.onEventChange("pincode", "");
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="mt-1" for="selctUsrType">
                        select user type <span>*</span>
                      </Label>
                      <Input
                        className="m-2"
                        type="radio"
                        for="buyer"
                        value="buyer"
                        name="type"
                        checked={this.state.userType === "buyer"}
                        onChange={(e) =>
                          this.onEventChange("userType", e.target.value)
                        }
                      ></Input>
                      <label className="mt-1" for="buyer">
                        Buyer
                      </label>
                      <Input
                        className="m-2"
                        type="radio"
                        for="seller"
                        value="seller"
                        name="type"
                        checked={this.state.userType === "seller"}
                        onChange={(e) =>
                          this.onEventChange("userType", e.target.value)
                        }
                      ></Input>
                      <label for="seller">Seller</label>
                      <Input
                        className="m-2"
                        type="radio"
                        for="transporter"
                        value="transporter"
                        name="type"
                        checked={this.state.userType === "transporter"}
                        onChange={(e) =>
                          this.onEventChange("userType", e.target.value)
                        }
                      ></Input>
                      <label className="mt-1" for="transporter">
                        Transporter
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <button
                  onClick={createTheCustomer}
                  style={{ width: "100px", margin: "auto", marginRight: 0 }}
                  class="btn darkBtn"
                  disabled={
                    !(
                      this.state.fullname &&
                      this.state.email &&
                      this.state.address &&
                      this.state.phone &&
                      this.state.phone.length === 10 &&
                      this.state.pincode &&
                      this.state.pincode.length === 6 &&
                      this.state.userType &&
                      this.state.natureOfBusiness &&
                      this.state.state
                    )
                  }
                >
                  submit
                </button>
              </Row>
            </Form>
          </div>

          {/* <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Account Details</h4>
            <div>&nbsp;</div>
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                <Col xs={12} sm={6} md={6} className="mb-15">
                  <FormGroup>
                    <Label for="ifsc">IFSC</Label>
                    <Input
                      id="ifsc"
                      name="ifsc"
                      placeholder="Type..."
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <FormGroup>
                    <Label for="accnum">Account Number</Label>
                    <Input
                      id="accnum"
                      name="accnum"
                      placeholder="Type..."
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>

          <div>
            <button className="btn darkBtn">Verify</button>
          </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    addedNewCustomer: state.rfc.addedNewCustomer,
  };
};
export default connect(mapStateToProps, { addCustomers })(Buyerform);
