import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, Progress } from "reactstrap";
import TableProfilePic from "../../../images/tableprofilepic.png";
import TableProfilePicOne from "../../../images/joey.png";
import { connect } from "react-redux";
import { fetchProductdata, fetchproductSortData } from "../../../store/actions";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { Link } from "react-router-dom";
import AccordionComponent from "../../Product/common/AccordionComponent";
import SelectComponent from "../../Product/common/SelectComponent";
import {
  fetchProductFilterSkuData,
  fetchCategoryData,
  fetchSellerData,
  fetchProductAllFilterData,
  Product_addCategory,
  Product_addSku,
  GetSkusList,
  productDeleteData,
  categoryBySku,
} from "../../../store/actions";
import defaultfish from "../../../images/fish-57.png";
class Inventorymain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDiv: false,
      limit: 10,
      currentPage: 1,
      AccordionCollapse: false,
      toggleCatDiv: false,
      toggleSkuDiv: false,
      categories: [],
      Seller: [],
      Size: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
      ],
      skucategories: [],
      sku: [],
      SelectedSku: "",
      category: "",
      seller: "",
      size: "",
      minPrice: "",
      maxPrice: "",
      addProduct_category: "",
      addProduct_categoryImg: undefined,
      addProduct_categoryImg_name: "",
      addProduct_skuName: "",
      addProduct_skuName_img_name: "",
      addProduct_categoryType: "",
      addProduct_skuImg: undefined,
      deleteConfirmModal: false,
      productToDeleteId: "",
    };
  }

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
    });
  };

  onFilterData(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }

  componentDidMount() {
    this.listOfProductList();
    this.listOfSellerAndCategory();
  }
  listOfProductList = (offset = 0) => {
    this.props.fetchproductSortData(
      offset,
      this.state.limit,
      this.state.maxPrice,
      this.state.minPrice,
      this.state.category.value ? this.state.category.value : "",
      this.state.size.value ? this.state.size.value : "",
      this.state.seller.value ? this.state.seller.value : "",
      this.state.SelectedSku.value ? this.state.SelectedSku.value : ""
    );
  };

  filterProductData() {
    this.setState({
      toggleDiv: false,
    });
    this.listOfProductList();
  }

  listOfSellerAndCategory = () => {
    this.props.fetchCategoryData((res) => {
      if (res.docs && res.docs.length > 0) {
        this.setState({
          categories: res.docs.map((ele) => {
            return { label: ele.categoryName, value: ele._id };
          }),
        });
      }
    });
    this.props.fetchSellerData((res) => {
      if (res.sellerList && res.sellerList.length > 0) {
        this.setState({
          Seller: res.sellerList.map((ele) => {
            return { label: ele.fullName, value: ele._id };
          }),
        });
      }
    });
  };
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.listOfProductList(offset);
  };
  handleChange = () => {
    this.setState({
      toggleDiv: false,
    });
  };
  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleChange);
  }
  render() {
    const totPages = Math.ceil(
      this.props.listOfProductInventory.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Category</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Size/count</span>,
          field: "size",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Quantity available</span>,
          field: "quantity",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Seller</span>,
          field: "seller",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Delivery date</span>,
          field: "deliveryDate",
          sort: "asc",
          width: 50,
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props.listOfProductInventory.docs &&
      this.props.listOfProductInventory.docs.length > 0
    ) {
      this.props.listOfProductInventory.docs.forEach((ele) => {
        data.rows.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={ele.images[0] ? ele.images[0] : ele?.sku?.skuImage[0]}
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "N/A"}
              </p>
            </div>
          ),
          //  ele.sku?.skuName ? ele.sku?.skuName : "N/A",
          category: ele.category?.categoryName
            ? ele.category?.categoryName
            : "N/A",
          size: ele.size,
          price: <span>{ele.rate}</span>,
          quantity: ele.availableQuantity,
          seller: ele.seller ? ele.seller.fullName : "N/A",
          deliveryDate: ele.deliveryDate ? (
            <div> {moment(ele.deliveryDate).format("DD/MM/YYYY")}</div>
          ) : (
            "N/A"
          ),
          // availableDeliveryDate: ele.availableDeliveryDate?.to ? (
          //   <div>
          //     {" "}
          //     {moment(ele.availableDeliveryDate?.from).format(
          //       "DD/MM/YYYY"
          //     )} - {moment(ele.availableDeliveryDate?.to).format("DD/MM/YYYY")}
          //   </div>
          // ) : (
          //   "N/A"
          // ),
          image: <img src={TableProfilePic}></img>,
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link
                to={{
                  pathname: `/ProductInfo/${ele._id}?isEditMode=${false}`,
                  state: { navigatePage: "inventory" },
                }}
              >
                <Button className="btn lightBtn">View</Button>
              </Link>
            </div>
          ),
        });
      });
    }
    document.body.addEventListener("click", this.handleChange);
    let { categories, Seller, Size } = this.state;
    return (
      <div className="dashInnerMainDiv">
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Inventory List</h4>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <div className="pos-rel">
                  <Button
                    className="btn lightBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.toggleFilter();
                    }}
                  >
                    Show Filters
                  </Button>
                  <div
                    className={`logoutDropdown productMainFilterDropdown ${
                      this.state.toggleDiv ? "displayBlock" : "displayNone"
                    }`}
                  >
                    <div class="arrow-up"></div>
                    <div>
                      <AccordionComponent AccordTitle={"Category"}>
                        <SelectComponent
                          options={categories}
                          onChange={(val) => {
                            if (val) {
                              this.onFilterData("category", val);
                              let payload = {
                                categoryId: val.value,
                              };
                              this.props.categoryBySku(payload, (res) => {
                                if (res.skus) {
                                  this.setState({
                                    sku: res.skus.map((ele) => {
                                      return {
                                        label: ele.skuName,
                                        value: ele._id,
                                      };
                                    }),
                                  });
                                }
                              });
                            } else {
                              this.onFilterData("category", val);
                              this.setState({ sku: [] });
                            }
                          }}
                          value={this.state.category ? this.state.category : ""}
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"SKU"}>
                        <SelectComponent
                          options={this.state.sku}
                          onChange={(val) => {
                            this.onFilterData("SelectedSku", val);
                          }}
                          value={
                            this.state.SelectedSku ? this.state.SelectedSku : ""
                          }
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Seller"}>
                        <SelectComponent
                          options={Seller}
                          onChange={(val) => {
                            this.onFilterData("seller", val);
                          }}
                          value={this.state.seller ? this.state.seller : ""}
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Size"}>
                        <SelectComponent
                          options={Size}
                          onChange={(val) => {
                            this.onFilterData("size", val);
                          }}
                          value={this.state.size ? this.state.size : ""}
                        />
                      </AccordionComponent>

                      <Form>
                        <div>
                          <p>Price</p>
                          <div className="d-flex align-items-center inputDiv">
                            <Label for="exampleInput">INR</Label>&nbsp;&nbsp;
                            <Input
                              id="exampleInput"
                              name="text"
                              placeholder="MINIMUM"
                              type="text"
                              onChange={(e) => {
                                this.onFilterData("minPrice", e.target.value);
                              }}
                            />
                          </div>
                          <br />
                          <div className="d-flex align-items-center inputDiv">
                            <Label for="exampleInput1">INR</Label>&nbsp;&nbsp;
                            <Input
                              id="exampleInput1"
                              name="text"
                              placeholder="MAXIMUM"
                              type="text"
                              onChange={(e) => {
                                this.onFilterData("maxPrice", e.target.value);
                              }}
                            />
                          </div>
                          <br />
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            outline
                            onClick={() => {
                              this.setState(
                                {
                                  category: "",
                                  seller: "",
                                  size: "",
                                  minPrice: "",
                                  maxPrice: "",
                                  SelectedSku: "",
                                  currentPage: 1,
                                  sku: [],
                                },
                                () => {
                                  this.listOfProductList();
                                  this.setState({ toggleDiv: false });
                                }
                              );
                            }}
                          >
                            Clear all
                          </Button>
                          <Button
                            outline
                            className="buttonDivStyle"
                            onClick={() => {
                              this.setState({ currentPage: 1 }, () => {
                                this.filterProductData();
                              });
                            }}
                          >
                            Apply Filter
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      this.setState(
                        {
                          limit: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.listOfProductList();
                        }
                      );
                      // setTimeout(() => {
                      //   this.listOfProductList();
                      // }, 500);
                    }}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
            displayEntries={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props.listOfProductInventory?.length != 0
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props.listOfProductInventory.docs?.length
                  ? this.props.listOfProductInventory.docs.length
                  : "")}{" "}
              {} entries of {this.props.listOfProductInventory?.length}
            </p>
          </div>
          <div className="custPaginationDiv">
            <Pagination
              shape="rounded"
              count={totPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    listOfProductInventory: state.rfu.productdata,
  };
};
export default connect(mapStateToProps, {
  fetchProductFilterSkuData,
  fetchCategoryData,
  fetchproductSortData,
  fetchSellerData,
  fetchProductAllFilterData,
  Product_addCategory,
  Product_addSku,
  GetSkusList,
  productDeleteData,
  categoryBySku,
})(Inventorymain);
