import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { enumEditData, fetchEnum } from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import { lazySlidesOnRight } from "react-slick/lib/utils/innerSliderUtils";

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalProductValue: "",
      commission: "",
      totalCost: "",
      packagingCost: "",
      editModal: false,
      enumToEditId: "",
    };
  }
  componentDidMount() {
    this.props.fetchEnum();
  }

  editModalToggle = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  enumRequestEditData() {
    let payload = {
      EnumValue: this.state.EnumValue,
    };
    this.props.enumEditData(payload, this.state.enumToEditId, (res) => {
      if (res && res.message === "Enum has been successfully updated") {
        NotificationManager.success(res.message, "Enum Updated!!");
        this.props.fetchEnum();
        this.props.history.push("/Settings");
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
    this.editModalToggle();
  }
  render() {
    var data = {};
    data = {
      columns: [
        {
          label: <span>Config Name</span>,
          field: "enumname",
          sort: "asc",
          width: 200,
        },
        {
          label: <span>Config Value</span>,
          field: "enumvalue",
          sort: "asc",
          width: 200,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    this.props.EnumData?.allEnums?.map((el) => {
      data.rows.push({
        enumname: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.EnumName ? el.EnumName : ""}</span>
          </div>
        ),
        enumvalue: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.EnumValue ? el.EnumValue : ""}</span>
          </div>
        ),
        actions: (
          <div className="d-flex align-items-center tableBtns ">
            <Link>
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ enumToEditId: el._id }, () => {
                    this.editModalToggle();
                    //this.props.fetchEnum();
                  });
                  this.setState({ EnumName: el.EnumName });
                }}
              >
                Edit
              </Button>
            </Link>
          </div>
        ),
      });
    });
    return (
      <div className="dashInnerMainDiv custCreationDivSec sosDiv">
        <Modal
          isOpen={this.state.editModal}
          toggle={this.editModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.editModalToggle}>{this.state.EnumName}</ModalHeader>
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12} sm={12} md={12} className="mb-15">
                  <FormGroup>
                    <Label for="Value">Value</Label>
                    <Input
                      id="enumvalue"
                      name="enumvalue"
                      type="text"
                      value={this.state.EnumValue}
                      onChange={(e) =>
                        this.onChange("EnumValue", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn lightBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.enumRequestEditData();
                }}
                disabled={this.state.isEditMode === "false" ? true : false}
              >
                Save
              </button>{" "}
              <Button color="secondary" onClick={this.editModalToggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Additional Cost Settings</h4>
            <div></div>
          </div>
        </div>
        <div className="dataTableStyles">
          {/* <Row>
            <Col xs={12} sm={4} md={4} className="mb-15">
              <div className="colordercostDiv">
                <div className="d-flex justify-content-between align-items-center ">
                  <div>Packaging Cost</div>
                  <p className="mb-0">
                    {this.props.EnumData?.allEnums[0]?.EnumValue
                      ? this.props.EnumData?.allEnums[0]?.EnumValue
                      : ""}{" "}
                    Rs
                  </p>
                </div>
                <p className="commision">(@ x %)</p>
              </div>
            </Col>
            <Col xs={12} sm={4} md={4} className="mb-15 ">
              <div className="colordercostDiv">
                <div className="d-flex  justify-content-between align-items-center">
                  <div>Transport Cost</div>
                  <p className="mb-0">
                    {this.props.EnumData?.allEnums[1]?.EnumValue
                      ? this.props.EnumData?.allEnums[1]?.EnumValue
                      : ""}{" "}
                    Rs
                  </p>
                </div>
                <p className="commision">(@ Rs 18 per km)</p>
              </div>
            </Col>
            <Col xs={12} sm={4} md={4} className="mb-15 ">
              <div className="colordercostDiv">
                <div className="d-flex  justify-content-between align-items-center">
                  <div>Freshr Commission</div>
                  <p className="mb-0">
                    {this.props.EnumData?.allEnums[2]?.EnumValue
                      ? this.props.EnumData?.allEnums[2]?.EnumValue
                      : ""}{" "}
                    Rs
                  </p>
                </div>
                <p className="commision">(@ x %)</p>
              </div>
            </Col>
          </Row> */}
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
            displayEntries={false}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    EnumData: state.rfe.EnumData,
  };
};
export default connect(mapStateToProps, {
  fetchEnum,
  enumEditData,
})(Setting);
