import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  // customerSearchdata,
  skuEditData,
  skuViewData,
} from "../../../store/actions";
import { checkNavigable } from "react-slick/lib/utils/innerSliderUtils";
class SkuInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      categoryId: "",
      id: "",
      trackNumber: 0,
      skuName: "",
    };
  }

  componentDidMount() {
    this.skuViewDataEdit();
  }
  skuViewDataEdit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
    });
    var queryParams = this.props.computedMatch.params.skuid;
    if (queryParams) {
      this.props.skuViewData(queryParams, (res) => {
        this.setState({
          categoryId: res.categoryId,
          id: res._id,
          skuName: res.skuName,
        });
      });
    }
  };
  skuEditData = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("skuName", this.state.skuName);
    formData.append("categoryId", this.state.categoryId);
 
    
    let payload = formData
    this.props.skuEditData(
      this.props.computedMatch.params.skuid,
      payload,
      (res) => {
        if (res && res.message === "SKU has been successfully updated") {
          NotificationManager.success(res.message, "SKU Updated!!");
          this.props.history.push("/Sku");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      }
    );
  };
  componentDidUpdate() {
    if (
      this.props.skuData._id !== this.state.id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.skuViewDataEdit();
      });
    }
  }
  render() {
    return (
      <div>
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>SKU Information</h4>
            <div>&nbsp;</div>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="sku">SKU Name</Label>
                        <Input
                          id="sku"
                          name="sku"
                          placeholder="#4 of 10 in ROHU"
                          type="text"
                          value={this.state.skuName}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.setState({
                              skuName: e.target.value.trimStart(),
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                   
                  </Row>
                  <div className="d-flex align-items-center justify-content-between">
                  <button
                      className="btn lightBtn"
                      onClick={() => this.props.history.push("/Sku")}
                    >
                      Back
                    </button>
                    <div>
                      <button
                        className="btn darkBtn"
                        onClick={(e) => this.skuEditData(e)}
                        disabled={
                          this.state.isEditMode === "false" ? true : false
                        }
                      >
                        Save
                      </button>
                    </div>
                   
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    skuData: state.rfs.skuView,
  };
};
export default connect(mapStateToProps, {
  skuEditData,
  skuViewData,
})(SkuInformation);
