import { combineReducers } from "redux";
import { types } from "./actionTypes";
import { NotificationManager } from "react-notifications";
let initialstates = {
  loggedInUser: "",
  user: null,
  dashboarddata: null,
  userRequestData: [],
  userRequestView: {},
  productdata: {},
  productviewdata: {},
  categorydata: [],
  userCount: {},
  sellerData: {},
  added_product_category: "",
  added_product_sku: "",
  allSkusData: [],
};
let customerinitialstates = {
  customer: [],
  customerView: {},
  addedNewCustomer: "",
  customerVerifys: {},
};
let initialUnitState = {
  sellerData: [],
  productCategoryData: [],
  skusDataById: [],
  productCreatedInfo: "",
};
let initialSkuState = {
  skuList: [],
  skuView: {},
  categoryList: [],
  categoryView: {},
};
let initialSosData = {
  SosData: undefined,
  sosUpdate: undefined,
};
let initialOrderData = {
  orderData: undefined,
  orderView: undefined,
};
let reducerforuser = (state = initialstates, actions) => {
  switch (actions.type) {
    case types.PROFILE_DATA_SUCCESS:
      return { ...state, user: actions.payload };
    case types.DASHBOARD_DATA_SUCCESS:
      return { ...state, dashboarddata: actions.payload };
    case types.USER_LOGIN_SUCCESS:
      if (actions.payload.user && actions.payload.token) {
        localStorage.setItem("authUser", JSON.stringify(actions.payload.user));
        localStorage.setItem("token", JSON.stringify(actions.payload.token));
      }
      return { ...state, loggedInUser: actions.payload };
    case types.USER_LOGOUT:
      localStorage.removeItem("authUser");
      NotificationManager.success("User Logged Out", "Logout Successful");
      return { ...state, loggedInUser: "" };
    case types.USER_REQUEST_DATA_SUCCESS:
      return { ...state, userRequestData: actions.payload };
    case types.USER_VIEW_DATA_SUCCESS:
      return { ...state, userRequestView: actions.payload };
    case types.PRODUCT_DATA_SUCESSS:
      return { ...state, productdata: actions.payload };
    case types.PRODUCT_VIEW_DATA_SUCCESS:
      return { ...state, productviewdata: actions.payload };
    case types.CATEGORY_DATA_SUCCESS:
      return { ...state, categorydata: actions.payload };
    case types.USER_COUNT_DATA_SUCESS:
      return { ...state, userCount: actions.payload };
    case types.SELLER_DATA_SUCCESS:
      return { ...state, sellerData: actions.payload };
    case types.PRODUCTS_ADD_CATEGORY_SUCCESS:
      return { ...state, added_product_category: actions.payload };
    case types.PRODUCTS_ADD_SKU_SUCCESS:
      return { ...state, added_product_sku: actions.payload };
    case types.PRODUCTS_GET_ALL_SKUS_SUCCESS:
      return { ...state, allSkusData: actions.payload };

    case types.PRODUCT_ALL_FILTER_DATA_SUCCESS:
      return { ...state, productdata: actions.payload };
    default:
      return state;
  }
};

let reducerForCustomer = (state = customerinitialstates, actions) => {
  switch (actions.type) {
    case types.CUSTOMER_DATA_SUCCESS:
      return { ...state, customer: actions.payload };
    case types.CUSTOMER_VIEW_DATA_SUCCESS:
      return { ...state, customerView: actions.payload };
    case types.CUSTOMER_ADDITION_SUCCESS:
      return { ...state, addedNewCustomer: actions.payload };
    case types.CUSTOMER_VERIFICATION_SUCCESS:
      return { ...state, customerVerifys: actions.payload };
    default:
      return state;
  }
};

let reducerForUnits = (state = initialUnitState, action) => {
  switch (action.type) {
    case types.PRODUCT_SELLER_DATASUCCESS:
      return { ...state, sellerData: action.payload };
    case types.PRODUCT_PRODUCT_CATEGORYSUCCESS:
      return { ...state, productCategoryData: action.payload };
    case types.PRODUCT_GET_SKU_BYID_SUCCESS:
      return { ...state, skusDataById: action.payload };
    case types.PRODUCT_CREATE_SUCCESS:
      return { ...state, productCreatedInfo: action.payload };
    default:
      return state;
  }
};
let reducerForSku = (state = initialSkuState, action) => {
  switch (action.type) {
    case types.SKU_DATA_SUCCESS:
      return { ...state, skuList: action.payload };
    case types.SKU_VIEW_DATA_SUCCESS:
      return { ...state, skuView: action.payload };
    case types.CATEGORYS_DATA_SUCCESS:
      return { ...state, categoryList: action.payload };
    case types.CATEGORY_VIEW_DATA_SUCCESS:
      return { ...state, categoryView: action.payload };
    default:
      return state;
  }
};

//using callbacks, but incase we need to use store
const reducerForSos = (state = initialSosData, action) => {
  switch (action.type) {
    case types.SOS_REQUEST_SUCCESS:
      return { ...state, SosData: action.payload };
    case types.SOS_UPDATE_REQUEST_SUCCESS:
      return { ...state, sosUpdate: action.payload };
    default:
      return state;
  }
};

//using callbacks, but incase we need to use store
const reducerForOrders = (state = initialOrderData, action) => {
  switch (action.type) {
    case types.ORDERS_DATA_SUCCESS:
      return { ...state, orderData: action.payload };
    case types.ORDER_VIEW_DATA_SUCCESS:
      return { ...state, orderView: action.payload };
    case types.ORDER_DISCOUNT_SUCCESS:
      return { ...state, orderView: action.payload };
    default:
      return state;
  }
};
const reducerForEnum = (state = initialOrderData, action) => {
  switch (action.type) {
    case types.ENUM_SUCCESS:
      return { ...state, EnumData: action.payload };
    case types.ENUM_EDIT_SUCCESS:
      return { ...state, EnumData: action.payload };
    default:
      return state;
  }
};
const rootReducer = combineReducers({
  rfu: reducerforuser,
  rfc: reducerForCustomer,
  rfun: reducerForUnits,
  rfs: reducerForSku,
  rfso: reducerForSos,
  rfo: reducerForOrders,
  rfe: reducerForEnum,
});

export default rootReducer;
