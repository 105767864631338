export const types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGOUT: "USER_LOGOUT",
  PROFILE_DATA: "PROFILE_DATA",
  PROFILE_DATA_SUCCESS: "PROFILE_DATA_SUCCESS",
  CUSTOMER_DATA_SUCCESS: "CUSTOMER_DATA_SUCCESS",
  CUSTOMER_DELETE_DATA: " CUSTOMER_DELETE_DATA",
  CUSTOMER_VIEW_DATA_SUCCESS: " CUSTOMER_VIEW_DATA_SUCCESS",
  CUSTOMER_VIEW_DATA: " CUSTOMER_VIEW_DATA",
  CUSTOMER_SORT_DATA: "CUSTOMER_SORT_DATA",
  CUSTOMER_EDIT_DATA: "CUSTOMER_EDIT_DATA",
  CUSTOMER_SEARCH_DATA: "CUSTOMER_SEARCH_DATA",
  DASHBOARD_DATA: "DASHBOARD_DATA",
  DASHBOARD_DATA_SUCCESS: "DASHBOARD_DATA_SUCCESS",
  USER_REQUEST_DATA: "USER_REQUEST_DATA",
  USER_REQUEST_DATA_SUCCESS: "USER_REQUEST_SUCCESS",
  USER_VIEW_DATA: "USER_VIEW_DATA",
  USER_VIEW_DATA_SUCCESS: "USER_VIEW_DATA_SUCCESS",
  USER_REQUEST_DELETE_DATA: "USER_REQUEST_DELETE_DATA",
  USER_REQUEST_EDIT_DATA: " USER_REQUEST_EDIT_DATA",
  USER_REQUEST_FILTER_DATA: "USER_REQUEST_FILTER_DATA",
  USER_REQUEST_FILTER_DATA_SUCCESS: "USER_REQUEST_FILTER_DATA_SUCCESS",
  PRODUCT_DATA_SUCESSS: "PRODUCT_DATA_SUCCESS",
  PRODUCT_SORT_DATA: " PRODUCT_SORT_DATA",
  PRODUCT_FILTER_DATA: "PRODUT_FILTER_DATA",
  PRODUCT_FILTER_DATA_BY_SKU: "PRODUCT_FILTER_DATA_BY_SKU",
  PRODUCT_VIEW_DATA: "PRODUCT_VIEW_DATA",
  PRODUCT_VIEW_DATA_SUCCESS: "PRODUCT_VIEW_DATA_SUCCESS",
  CATEGORY_DATA: "CATEGORY_DATA",
  CATEGORY_DATA_SUCCESS: "CATEGORY_DATA_SUCCESS",
  USER_COUNT_DATA: "USER_COUNT_DATA",
  USER_COUNT_DATA_SUCESS: "USER_COUNT_DATA_SUCCESS",
  SELLER_DATA_SUCCESS: "SELLER_DATA_SUCCESS",
  SELLER_DATA: "SELLER_DATA",
  PRODUCT_ALL_FILTER_DATA: "PRODUCT_ALL_FILTER_DATA",
  PRODUCT_ALL_FILTER_DATA_SUCCESS: "PRODUCT_ALL_FILTER_DATA_SUCCESS",

  PRODUCT_SELLER_DATA: "PRODUCT_SELLERDATA",
  // USER_COUNT_DATA_SUCESS: "USER_COUNT_DATA_SUCCESS",
  PRODUCT_SELLER_DATASUCCESS: "PRODUCT_SELLERDATASUCCESS",
  // SELLER_DATA_SUCCESS:"SELLER_DATA_SUCCESS",
  PRODUCT_PRODUCT_CATEGORY: "PRODUCT_PRODUCTCATEGORY",
  // SELLER_DATA:"SELLER_DATA",
  PRODUCT_PRODUCT_CATEGORYSUCCESS: "PRODUCT_PRODUCT_CATEGORYSUCCESS",
  // PRODUCT_ALL_FILTER_DATA:"PRODUCT_ALL_FILTER_DATA",
  PRODUCT_GET_SKU_BYID: "PRODUCT_GET_SKU_BYID",
  PRODUCT_GET_SKU_BYID_SUCCESS: "PRODUCT_GET_SKU_BYID_SUCCESS",
  PRODUCT_CREATE: "PRODUCT_CREATE",
  PRODUCT_CREATE_SUCCESS: "PRODUCT_CREATE_SUCCESS",
  PRODUCTS_ADD_CATEGORY: "PRODUCTS_ADD_CATEGORY",
  PRODUCTS_ADD_CATEGORY_SUCCESS: "PRODUCTS_ADD_CATEGORY_SUCCESS",
  PRODUCTS_ADD_SKU: "PRODUCTS_ADD_SKU",
  PRODUCTS_ADD_SKU_SUCCESS: "PRODUCTS_ADD_SKU_SUCCESS",
  PRODUCTS_GET_ALL_SKUS: "PRODUCTS_GET_ALL_SKUS",
  PRODUCTS_GET_ALL_SKUS_SUCCESS: "PRODUCTS_GET_ALL_SKUS_SUCCESS",
  CUSTOMER_ADDITION: "CUSTOMER_ADDITION",
  CUSTOMER_ADDITION_SUCCESS: "CUSTOMER_ADDITION_SUCCESS",
  PRODUCT_DELETE_DATA: "PRODUCT_DELETE_DATA",
  PRODUCT_EDIT_DATA: "PRODUCT_EDIT_DATA",
  VERIFY_BANK_ACCOUNT: "VERIFY_BANK_ACCOUNT",
  VERIFY_BANK_ACCOUNT_SUCCESS: "VERIFY_BANK_ACCOUNT_SUCCESS",
  TRIGGER_OTP: "TRIGGER_OTP",
  TRIGGER_OTP_SUCCESS: "TRIGGER_OTP_SUCCESS",
  VERIFY_OTP: "VERIFY_OTP",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  TRIGGER_KYC_FORM: "TRIGGER_KYC_FORM",
  TRIGGER_KYC_FORM_SUCCESS: "TRIGGER_KYC_FORM_SUCCESS",
  SKU_DATA: " SKU_DATA",
  SKU_DATA_SUCCESS: "SKU_DATA_SUCCESS",
  SKU_DELETE_DATA: "SKU_DELETE_DATA",
  SKU_EDIT_DATA: "SKU_EDIT_DATA",
  SKU_VIEW_DATA: "SKU_CATEGORY_VIEW_DATA",
  SKU_VIEW_DATA_SUCCESS: "SKU_CATEGORY_VIEW_DATA_SUCCESS",
  CATEGORY_BY_SKU_DATA: "CATEGORY_BY_SKU_DATA",
  CATEGORYS_DATA: "CATEGORYS_DATA",
  CATEGORYS_DATA_SUCCESS: "CATEGORYS_DATA_SUCCESS",
  CATEGORY_DELETE_DATA: "CATEGORY_DELETE_DATA",
  CATEGORY_VIEW_DATA: "CATEGORY_VIEW_DATA",
  CATEGORY_VIEW_DATA_SUCCESS: "CATEGORY_VIEW_DATA_SUCCESS",
  CATEGORY_EDIT_DATA: "CATEGORY_EDIT_DATA",
  UPLOAD_CANCELLED_CHEQUE: "UPLOAD_CANCELLED_CHEQUE",
  UPLOAD_CANCELLED_CHEQUE_SUCCESS: "UPLOAD_CANCELLED_CHEQUE_SUCCESS",
  UPLOAD_KYC_DOCUMENT: "UPLOAD_KYC_DOCUMENT",
  UPLOAD_KYC_DOCUMENT_SUCCESS: "UPLOAD_KYC_DOCUMENT_SUCCESS",
  SOS_REQUEST: "SOS_REQUEST",
  SOS_REQUEST_SUCCESS: "SOS_REQUEST_SUCCESS",
  SOS_UPDATE_REQUEST: "SOS_UPDATE_REQUEST",
  SOS_UPDATE_REQUEST_SUCCESS: "SOS_UPDATE_REQUEST_SUCCESS",
  ORDERS_DATA: "ORDERS_DATA",
  ORDERS_DATA_SUCCESS: "ORDERS_DATA_SUCCESS",
  ORDER_VIEW_DATA:"ORDER_VIEW_DATA",
  ORDER_VIEW_DATA_SUCCESS:"ORDER_VIEW_DATA_SUCCESS",
  ORDER_CANCEL:"ORDER_CANCEL",
  BUYER_SEARCH:"BUYER_SEARCH",
  ORDER_UPDATE:"ORDER_UPDATE",
  ADD_TRANSPORT:"ADD_TRANSPORT",
  ISSUE_PAYMENT_LINK:"ISSUE_PAYMENT_LINK",
  INTIATE_REFUND:"INTIATE_REFUND",
  RESEND_PAYMENT:"RESEND_PAYMENT",
  ENUM:"ENUM",
  ENUM_SUCCESS:"ENUM_SUCCESS",
  ENUM_EDIT:"ENUM_EDIT",
  ENUM__EDIT_SUCCESS:"ENUM_EDIT_SUCCESS",
  ORDER_DISCOUNT:"ORDER_DISCOUNT",
  ORDER_DISCOUNT_SUCCESS:"ORDER_DISCOUNT_SUCCESS",
  CUSTOMER_VERIFICATION:"CUSTOMER_VERIFICATION",
  CUSTOMER_VERIFICATION_SUCCESS:"CUSTOMER_VERIFICATION_SUCCESS"
};
