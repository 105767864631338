import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label } from "reactstrap";
import TableProfilePic from "../../../images/tableprofilepic.png";
import TableProfilePicOne from "../../../images/joey.png";
import { connect } from "react-redux";
import { getOrdersData, buyerSearch } from "../../../store/actions";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import SelectComponent from "../../Product/common/SelectComponent";

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      buyer: "",
      OrderData: [],
      limit: 10,
      sort: "",
      currentPage: 1,
      limit: 10,
      orderId: "",
      paymentStatus: "",
      orderStatus: "",
      buyerName: "",
      searchBuyerName: "",
      buyerDataList: [],
      selectedBuyer: "",
    };
  }
  fetchOrdersData = (offset = 0) => {
    let DataToSent = {
      offset: offset,
      limit: this.state.limit,
      sort: this.state.sort,
      orderId: this.state.orderId ? this.state.orderId : "",
      buyerName: this.state.buyerName.value ? this.state.buyerName.value : "",
      paymentStatus: this.state.paymentStatus ? this.state.paymentStatus : "",
      orderStatus: this.state.orderStatus ? this.state.orderStatus : "",
    };
    this.props.getOrdersData(DataToSent, (data) => {
      console.log("DATA", data);
      this.setState({ OrderData: data });
    });
  };
  componentDidMount() {
    this.fetchOrdersData();
    this.fetchBuyerSearch();
  }

  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.fetchOrdersData(offset);
  };
  fetchBuyerSearch = () => {
    this.props.buyerSearch(
      0,
      10,
      this.state.searchBuyerName ? this.state.searchBuyerName : "",
      (res) => {
        if (res) {
          this.setState({
            buyerDataList: res?.docs.map((ele) => {
              return { label: ele.fullName, value: ele._id };
            }),
          });
        }
      }
    );
  };
  render() {
    const totalPages = Math.ceil(
      this.props?.orderData?.allOrders?.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>Order Id</span>,
          field: "orderId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Buyer</span>,
          field: "buyer",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Order Status</span>,
          field: "orderStatus",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Payment Status</span>,
          field: "payment",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Actions</span>,
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.state.OrderData?.allOrders?.docs?.map((el) => {
      data.rows.push({
        orderId: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.orderId ? el.orderId : ""}</span>
          </div>
        ),
        buyer: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el?.buyer?.buyerId?.fullName ?? ""}</span>
          </div>
        ),
        orderStatus: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.orderStatus ? el.orderStatus : ""}</span>
          </div>
        ),
        payment: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.paymentStatus ? el.paymentStatus : ""}</span>
          </div>
        ),

        actions: (
          <div className="d-flex align-items-center tableBtns">
            <Link to={`/order/${el._id}?isEditMode=${false}`}>
              <Button className="btn lightBtn">View</Button>
            </Link>
            &nbsp;&nbsp;
            <Link to={`/order/${el._id}?isEditMode=${true}`}>
              <Button className="btn lightBtn">Edit</Button>
            </Link>
            &nbsp;&nbsp;
          </div>
        ),
      });
    });

    let debounceThis;
    const debouncing = (e, stateName) => {
      if (debounceThis) {
        clearTimeout(debounceThis);
        debounceThis = null;
      }
      debounceThis = setTimeout(() => {
        if (stateName === "orderId") {
          this.setState(
            { [`${stateName}`]: e.target.value.trim(), currentPage: 1 },
            () => this.fetchOrdersData()
          );
        } else {
          this.setState({ [`${stateName}`]: e }, () => this.fetchBuyerSearch());
        }
      }, 1000);
    };
    return (
      <div className="dashInnerMainDiv custCreationDivSec sosDiv">
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Orders List</h4>
            <div>
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv debounceInput">
                  <Input
                    name="search"
                    placeholder="Order ID"
                    type="text"
                    className="mb-0"
                    onChange={(e) => debouncing(e, "orderId")}
                    autoComplete="off"
                  />
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                      stroke="#5E5E5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectWidth sortOrder text-capitalize">
                  <SelectComponent
                    options={this.state.buyerDataList}
                    value={this.state.buyerName ? this.state.buyerName : ""}
                    onChange={(e) => {
                      if (e) {
                        this.setState({ buyerName: e, currentPage: 1 }, () => {
                          this.fetchOrdersData();
                        });
                      } else {
                        this.setState(
                          {
                            buyerName: e,
                            currentPage: 1,
                          },
                          () => {
                            this.fetchOrdersData();
                          }
                        );
                      }
                    }}
                    onInputChange={(e) => {
                      debouncing(e.trimStart(), "searchBuyerName");
                    }}
                    placeholder="Buyer"
                  />
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect" className="orderLabel">
                    Order Status
                  </Label>
                  &nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      let value;
                      if (e.target.value === "Placed") value = "Placed";
                      else if (e.target.value === "Updated") value = "Updated";
                      else if (e.target.value === "Cancelled")
                        value = "Cancelled";
                      else if (e.target.value === "All") value = "";
                      else if (e.target.value === "Finalized")
                        value = "Finalized";
                      else if (e.target.value === "In Transit")
                        value = "In Transit";
                      else if (e.target.value === "Delivered")
                        value = "Delivered";
                      this.setState(
                        {
                          orderStatus: value,
                          currentPage: 1,
                        },
                        () => {
                          this.fetchOrdersData();
                        }
                      );
                    }}
                  >
                    <option>All</option>
                    <option>Placed</option>
                    <option>Updated</option>
                    <option>Cancelled</option>
                    <option>Finalized</option>
                    <option>In Transit</option>
                    <option>Delivered</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo sortOrder">
                  <Label for="exampleSelect" className="orderLabel">
                    Payment Status
                  </Label>
                  &nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      let value;
                      if (e.target.value === "Pending") value = "Pending";
                      else if (e.target.value === "UnSuccessfull")
                        value = "UnSuccessfull";
                      else if (e.target.value === "Cancelled")
                        value = "Cancelled";
                      else if (e.target.value === "All") value = "";
                      else if (e.target.value === "Partially Paid")
                        value = "Partially Paid";
                      else if (e.target.value === "Fully Paid")
                        value = "Fully Paid";
                      else if (e.target.value === "Issued") value = "Issued";
                      else if (e.target.value === "Expired") value = "Expired";

                      this.setState(
                        {
                          paymentStatus: value,
                          currentPage: 1,
                        },
                        () => {
                          this.fetchOrdersData();
                        }
                      );
                    }}
                  >
                    <option>All</option>
                    <option>Pending</option>
                    <option>UnSuccessfull</option>
                    <option>Partially Paid</option>
                    <option>Fully Paid</option>
                    <option>Cancelled</option>
                    <option>Issued</option>
                    <option>Expired</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv sortOrder">
                  <Label className="orderLabel">Sort by</Label>&nbsp;
                  <Input
                    onChange={(e) =>
                      this.setState(
                        { sort: e.target.value, currentPage: 1 },
                        () => this.fetchOrdersData()
                      )
                    }
                    name="select"
                    type="select"
                  >
                    <option value="orderId desc">Order Id Descending</option>
                    <option value="orderId asc">Order Id Ascending</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label>Show</Label>&nbsp;&nbsp;
                  <Input
                    onChange={(e) => {
                      this.setState(
                        {
                          limit: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.fetchOrdersData();
                        }
                      );
                    }}
                    name="select"
                    type="select"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
            displayEntries={false}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props?.orderData?.allOrders?.length
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props?.orderData?.allOrders?.docs?.length
                  ? this.props?.orderData?.allOrders?.docs?.length
                  : "")}{" "}
              {} entries of {this.props.orderData?.allOrders?.length}
            </p>
          </div>
          <div className="custPaginationDiv">
            <Pagination
              shape="rounded"
              count={totalPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  orderData: state.rfo.orderData,
  // SosDeleteRes: state.rfso.sosDelete
});
export default connect(mapStateToProps, { getOrdersData, buyerSearch })(
  OrdersPage
);
