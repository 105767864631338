import React, { Component, createRef } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import detailsslidesmallimage from "../../../images/detailsslidesmallimage.png";
import vector from "../../../images/Edit icon.svg";
import { connect } from "react-redux";
import {
  fetchOrderViewData,
  orderCancel,
  orderUpdate,
  addTransport,
  issuePaymentLink,
  intiateRefund,
  resendPayment,
  orderDiscount,
} from "../../../store/actions";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import bagIcons from "../../../images/Order bag icon.svg";
import deleteIcons from "../../../images/Trash icon.svg";
import AddProducts from "./AddProducts";
import { GenerateQr } from "../../../common/components/GenerateQr";
class Orderdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      trackNumber: 0,
      deliveryDate: "",
      isEditMode: false,
      createdAt: "",
      price: "",
      orderedProductList: [],
      totalOrderedCost: "",
      showModal: false,
      showDiscountModal: false,
      totalProductValue: "",
      commission: "",
      totalCost: "",
      packagingCost: "",
      addProducts: false,
      deleteConfirmModal: false,
      orderId: "",
      orderStatus: "",
      paymentStatus: "",
      productToDeleteId: "",
      vehicleNumber: "",
      driverName: "",
      mobileNumber: "",
      liveTrackingLink: "",
      isVehicleValid: false,
      isMobileValid: false,
      netDifference: "",
      payLinkId: "",
      rates: [],
      amount: 0,
      orderCancelPopUp: false,
    };
    this.orderQrCanvas = createRef(null);
  }

  componentDidMount() {
    this.OrderInfo();
  }
  OrderInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
    });
    var queryParams = this.props.computedMatch.params.orderid;
    if (queryParams) {
      this.props.fetchOrderViewData(queryParams, (res) => {
        const arrProductList = [];
        if (
          arrProductList.length === 0 &&
          this.props?.orderViewData?.singleOrder?.orderedProductList
        ) {
          for (
            let i = 0;
            i <
            this.props?.orderViewData?.singleOrder?.orderedProductList.length;
            i++
          ) {
            let data = {
              ...this.props?.orderViewData?.singleOrder?.orderedProductList[i],
            };
            data["weight" + i] = true;
            data["rate" + i] = true;
            arrProductList.push(data);
          }
        }
        this.setState({
          id: this.props?.orderViewData?.singleOrder?._id,
          deliveryDate: this.props?.orderViewData?.singleOrder?.deliveryDate
            ? moment(
              this.props?.orderViewData?.singleOrder?.deliveryDate
            ).format("ll")
            : "N/A",
          createdAt: this.props?.orderViewData?.singleOrder?.createdAt
            ? moment(this.props?.orderViewData?.singleOrder?.createdAt).format(
              "L"
            )
            : "N/A",
          orderedProductList: arrProductList.length > 0 ? arrProductList : [],
          totalOrderedCost:
            this.props?.orderViewData?.singleOrder?.totalOrderedCost,
          totalProductValue:
            this.props?.orderViewData?.singleOrder?.totalProductValue,
          commission: this.props?.orderViewData?.singleOrder?.commission
            ? this.props?.orderViewData?.singleOrder?.commission
            : "",
          totalCost: this.props?.orderViewData?.singleOrder?.totalCost
            ? this.props?.orderViewData?.singleOrder?.totalCost
            : "",
          packagingCost: this.props?.orderViewData?.singleOrder?.packagingCost
            ? this.props?.orderViewData?.singleOrder?.packagingCost
            : "",
          transportCost: this.props?.orderViewData?.singleOrder?.transportCost
            ? this.props?.orderViewData?.singleOrder?.transportCost
            : "",
          orderId: this.props?.orderViewData?.singleOrder?.orderId
            ? this.props?.orderViewData?.singleOrder?.orderId
            : "",
          orderStatus: this.props?.orderViewData?.singleOrder?.orderStatus
            ? this.props?.orderViewData?.singleOrder.orderStatus
            : "",
          paymentStatus: this.props?.orderViewData?.singleOrder?.paymentStatus
            ? this.props?.orderViewData?.singleOrder?.paymentStatus
            : "",
          netDifference: this.props?.orderViewData?.singleOrder?.netDifference,
          payLinkId: this.props?.orderViewData?.singleOrder?.payLinkId
            ? this.props?.orderViewData?.singleOrder?.payLinkId
            : "",
          rates: this.props?.orderViewData?.singleOrder?.rates
            ? this.props?.orderViewData?.singleOrder?.rates
            : "",
        });
      });
    }
  };
  componentDidUpdate() {
    if (
      this.props?.orderViewData?.singleOrder?._id &&
      this.state.id !== this.props?.orderViewData?.singleOrder?._id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.OrderInfo();
      });
    }
  }
  cancelOrder = (status) => {
    let orderActions;
    if (status === "Cancel") {
      orderActions = "adminCancelsOrder";
    } else if (status === "Finalised") {
      orderActions = "adminFinalizesOrder";
    } else {
      if (status === "Transport") {
        orderActions = "transportDetailsAdded";
      }
    }
    let data = {
      addedProducts: [],
      deletedProducts: [],
      modifiedProducts: [],
    };
    this.props.orderUpdate(data, this.state.id, orderActions, (res) => {
      if (status !== "Transport") {
        if (res.message === "Order has been updated") {
          NotificationManager.success(res.message, "Order Updated");
          if (status === "Cancel") {
            this.props.history.push("/Orders");
          } else {
            this.OrderInfo();
          }
        } else {
          NotificationManager.error(res.message, "Error");
        }
      } else {
        this.OrderInfo();
      }
    });
  };
  hideConfirmModalToggle = () => {
    this.setState({
      showModal: !this.state.showModal,
      vehicleNumber: "",
      mobileNumber: "",
      liveTrackingLink: "",
      driverName: "",
      isVehicleValid: false,
      isMobileValid: false,
    });
  };
  hideConfirmDiscountModalToggle = () => {
    this.setState({
      showDiscountModal: !this.state.showDiscountModal,
      amount: "",
    });
  };
  hideConfirmModalToggleProduct = () => {
    this.setState({ addProducts: !this.state.addProducts });
  };
  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };
  editPrice = (i, val) => {
    const dataup = [...this.state.orderedProductList];
    dataup[i][val + i] = false;
    this.setState({
      orderedProductList: dataup,
    });
  };
  orderUpdate = (i, name, value) => {
    const dataup = [...this.state.orderedProductList];
    dataup[i][name] = value;
    this.setState({
      orderedProductList: dataup,
    });
  };
  updateOrderRequest = () => {
    let orderActions =
      this.state.orderStatus === "Placed"
        ? "adminUpdatesOrder"
        : "adminUpdatesOrder";
    const modifiedOrders = this.state?.orderedProductList.map((ele) => {
      return {
        productId: ele.productId._id,
        quantity: ele.quantity,
        rate: ele.rate,
      };
    });
    let data = {
      addedProducts: [],
      deletedProducts: [],
      modifiedProducts: modifiedOrders,
    };
    this.props.orderUpdate(data, this.state.id, orderActions, (res) => {
      if (res && res.message === "Order has been updated") {
        NotificationManager.success(res.message, "Order Updated");
        this.OrderInfo();
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  };
  onDeleteProductRequest(id) {
    let orderActions = "adminUpdatesOrder";
    let data = {
      addedProducts: [],
      deletedProducts: [
        {
          productId: id,
        },
      ],
      modifiedProducts: [],
    };
    this.props.orderUpdate(data, this.state.id, orderActions, (res) => {
      if (res && res.message === "Order has been updated") {
        NotificationManager.success(res.message, "Order Updated");
        this.OrderInfo();
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  }
  onChange = (name, val) => {
    this.setState({
      [`${name}`]: val,
    });
  };
  addTransport = () => {
    let data = {
      vehicleNumber: this.state.vehicleNumber ? this.state.vehicleNumber : "",
      driverName: this.state.driverName ? this.state.driverName : "",
      mobileNumber: this.state.mobileNumber ? this.state.mobileNumber : "",
      liveTrackingLink: this.state.liveTrackingLink
        ? this.state.liveTrackingLink
        : "",
    };
    this.props.addTransport(this.state.id ? this.state.id : "", data, (res) => {
      if (res.data) {
        NotificationManager.success(
          "Transport Added Successfully",
          "Transport Added"
        );
        this.cancelOrder("Transport");
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
    this.hideConfirmModalToggle();
  };
  addDiscount = () => {
    let originalCost =
      this.props?.orderViewData?.singleOrder?.originalCost ?? 0;
    if (this.state.amount <= originalCost && this.state.amount > 0) {
      let payload = {
        amount: this.state.amount ? parseFloat(this.state.amount) : 0,
      };
      this.props.orderDiscount(payload, this.state.id, (res) => {
        if (res && res.message === "Discount Added Successfully") {
          NotificationManager.success(res.message, "Discount Added");
          this.OrderInfo();
        } else {
          NotificationManager.error(res.message, "Error");
        }
      });
      this.setState({ showDiscountModal: false, amount: "" });
    } else {
      NotificationManager.error("Please less than total amount", "Discount");
    }
  };

  paymentLink = (e) => {
    e.preventDefault();
    if (this.state.payLinkId) {
      let payload = {
        orderId: this.state.id,
      };
      this.props.resendPayment(payload, (res) => {
        if (res && res.message === "Payment Link has been sent successfully") {
          NotificationManager.success(res.message, "Resend Payment");
          this.props.history.push("/Orders");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      });
    } else {
      this.props.issuePaymentLink(this.state.id, (res) => {
        if (res && res.message === "Payment has been created successfully") {
          NotificationManager.success(res.message, "Payment Link successfully");
          this.props.history.push("/Orders");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      });
    }
  };
  paymentRefund = (e) => {
    e.preventDefault();
    const payload = {
      orderId: this.state.id ? this.state.id : "",
    };
    this.props.intiateRefund(payload, (res) => {
      if (res && res.message === "Refund has been created successfully") {
        NotificationManager.success(res.message, "Payment Refund successfully");
        this.props.history.push("/Orders");
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });
  };
  cancelOrderToggle = () => {
    this.setState({
      orderCancelPopUp: !this.state.orderCancelPopUp,
    });
  };
  percentageCommission = (commisionName) => {
    let commisionValue = "x";
    if (commisionName && this.state.rates.length > 0) {
      const filtercommisionValue = this.state.rates.filter(
        (ele) => ele.EnumType === commisionName
      )[0]?.EnumValue;

      commisionValue = filtercommisionValue ? filtercommisionValue : "x"
    }
    return commisionValue;
  };
  render() {
    const { orderStatus, paymentStatus } = this.state;
    return (
      <div className="mainParentSectionDiv">
        <div className="cancelOrderwarning">
          <Modal
            isOpen={this.state.orderCancelPopUp}
            toggle={this.cancelOrderToggle}
            className={this.props.className}
            size="md"
          >
            <ModalHeader toggle={this.cancelOrderToggle}>Confirm</ModalHeader>
            <ModalBody>Are you sure you want to cancel the order?</ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  // this.onDeleteCustomerRequest(this.state.customerToDeleteId);
                  this.cancelOrder("Cancel");
                  this.cancelOrderToggle();
                }}
              >
                OK
              </Button>{" "}
              <Button color="secondary" onClick={this.cancelOrderToggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="modalDriver">
          <Modal
            isOpen={this.state.showModal}
            toggle={this.hideConfirmModalToggle}
            className={"modalDriverInner"}
            size="md"
          >
            <ModalHeader toggle={this.hideConfirmModalToggle}>
              Add Transport
            </ModalHeader>
            <div className="popupFormParentDivCss">
              <div className="popupFormDivsCss">
                <label>Vehicle Number</label>
                &nbsp; <span>*</span>
                <input
                  type="text"
                  value={
                    this.state.vehicleNumber ? this.state.vehicleNumber : ""
                  }
                  onChange={(e) => {
                    const vehicleRegex1 = /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/;
                    const vehicleRegex2 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/;
                    if (
                      vehicleRegex1.test(e.target.value) ||
                      vehicleRegex2.test(e.target.value)
                    ) {
                      this.setState({
                        isVehicleValid: false,
                        vehicleNumber: e.target.value,
                      });
                    } else {
                      this.setState({
                        isVehicleValid: true,
                        vehicleNumber: e.target.value,
                      });
                    }
                  }}
                />
                {this.state.isVehicleValid && (
                  <p className="novehicleValid">Vehicle Number is not Valid</p>
                )}
              </div>
              <div className="popupFormDivsCss">
                <label>Driver Name</label>
                &nbsp; <span>*</span>
                <input
                  type="text"
                  value={this.state.driverName ? this.state.driverName : ""}
                  onChange={(e) => this.onChange("driverName", e.target.value)}
                />
              </div>
              <div className="popupFormDivsCss">
                <label>Mobile Number</label>
                &nbsp; <span>*</span>
                <input
                  type="text"
                  value={this.state.mobileNumber ? this.state.mobileNumber : ""}
                  maxLength={10}
                  onChange={(e) => {
                    let regularExpressionNumber = /^[0-9]*$/;
                    if (regularExpressionNumber.test(e.target.value)) {
                      this.setState({
                        isMobileValid: false,
                        mobileNumber: e.target.value,
                      });
                    } else {
                      this.setState({
                        isMobileValid: true,
                        mobileNumber: e.target.value,
                      });
                    }
                  }}
                />
                {this.state.isMobileValid && (
                  <p className="novehicleValid">Mobile Number is not Valid</p>
                )}
              </div>
              <div className="popupFormDivsCss">
                <label>Live location Tracking Link</label>
                <input
                  type="text"
                  value={
                    this.state.liveTrackingLink
                      ? this.state.liveTrackingLink
                      : ""
                  }
                  onChange={(e) =>
                    this.onChange("liveTrackingLink", e.target.value)
                  }
                />
              </div>
            </div>
            {/* <ModalFooter> */}
            <div className="">
              <Button
                className="btn darkBtn locationbtn"
                disabled={
                  this.state.isEditMode === "false"
                    ? true
                    : !(
                      orderStatus === "Finalized" &&
                      this.state.driverName &&
                      this.state.mobileNumber &&
                      this.state.vehicleNumber &&
                      !this.state.isVehicleValid &&
                      !this.state.isMobileValid &&
                      this.state.mobileNumber.length === 10 &&
                      this.state.netDifference === 0
                    )
                }
                onClick={(e) => {
                  e.preventDefault();
                  // this.checkVehicleRegex(this.state.vehicleNumber);
                  this.addTransport();
                }}
              >
                Submit
              </Button>{" "}
            </div>
            {/* </ModalFooter> */}
          </Modal>
          <Modal
            isOpen={this.state.showDiscountModal}
            toggle={this.hideConfirmDiscountModalToggle}
            className={"modalDriverInner"}
            size="md"
          >
            <ModalHeader toggle={this.hideConfirmDiscountModalToggle}>
              Add Discount
            </ModalHeader>
            <div className="popupFormParentDivCss">
              <div className="popupFormDivsCss">
                <label>Amount</label>
                <input
                  type="number"
                  onChange={(e) => {
                    if (e.target.value) {
                      this.onChange("amount", e.target.value);
                    }
                  }}
                />
              </div>
            </div>
            {/* <ModalFooter> */}
            <div className="">
              <Button
                className="btn darkBtn locationbtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.addDiscount();
                }}
              >
                Submit
              </Button>{" "}
            </div>
            {/* </ModalFooter> */}
          </Modal>
        </div>
        {this.state.addProducts && (
          <AddProducts
            onToggle={this.hideConfirmModalToggleProduct}
            orderModal={this.state.addProducts}
            orderId={this.state.id}
            OrderInfo={this.OrderInfo}
          />
        )}
        <div>
          <Modal
            isOpen={this.state.deleteConfirmModal}
            toggle={this.deleteConfirmModalToggle}
            className={this.props.className}
            size="md"
          >
            <ModalHeader toggle={this.deleteConfirmModalToggle}>
              Confirm
            </ModalHeader>
            <ModalBody>Are sure you want to delete the product?</ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  this.onDeleteProductRequest(this.state.productToDeleteId);
                  this.deleteConfirmModalToggle();
                }}
              >
                Delete
              </Button>{" "}
              <Button color="secondary" onClick={this.deleteConfirmModalToggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Order Details</h4>
            <div>&nbsp;</div>
          </div>
          <div className="dashDownContent">
            <hr />
            <ul className="d-flex align-items-center justify-content-between">
              <img src={bagIcons} alt="imageLoading" />

              <li>{this.state.createdAt ? this.state.createdAt : ""}</li>
              <li>Order ID:{this.state.orderId ? this.state.orderId : ""}</li>
              <li>
                Amount:
                {this.props?.orderViewData?.singleOrder?.originalCost ?? ""} Rs
              </li>
              <li>
                Order Status:
                {this.state.orderStatus ? this.state.orderStatus : ""}
              </li>
              <li className="borderRightNone paddingPayment">
                Payment Status:
                {this.state.paymentStatus ? this.state.paymentStatus : ""}
              </li>
              <li className="borderRightNone">
                <button
                  disabled={
                    this.state.isEditMode === "false"
                      ? true
                      : !(orderStatus === "Placed" || orderStatus === "Updated")
                  }
                  onClick={() => {
                    this.setState({
                      addProducts: true,
                    });
                  }}
                  className="btn lightBtn"
                >
                  Add Product
                </button>
              </li>
            </ul>
            <hr />
          </div>
          <div className="formDivCss">
            <Form>
              <Row>
                {this.state.orderedProductList.map((ele, i) => (
                  <>
                    <Col xs={12} sm={4} md={4} className="mb-15 d-grid">
                      <div className="d-flex align-items-center justify-content-start colBorder">
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="orderPaddingImg">
                            <img
                              src={
                                ele?.productId?.images[0]
                                  ? ele?.productId?.images[0]
                                  : ele?.productId?.images[1]
                                    ? ele?.productId?.images[1]
                                    : detailsslidesmallimage
                              }
                              alt="image loading"
                              className="orderImg"
                            />
                          </div>

                          <div className="orderLeftColStyle">
                            <h4 className="mb-0">{ele.skuName || "N/A"}</h4>
                            <p className="mb-0">Product ID:#1234</p>
                            <h4 className="mb-0">Rs {ele.rate || "N/A"}/kg</h4>
                          </div>
                        </div>
                        <div
                          onClick={
                            () => {
                              if (
                                this.state.isEditMode !== "false" &&
                                this.state.orderedProductList.length !== 1
                              ) {
                                if (
                                  orderStatus === "Placed" ||
                                  orderStatus === "Updated"
                                ) {
                                  this.setState(
                                    { productToDeleteId: ele.productId._id },
                                    () => {
                                      this.deleteConfirmModalToggle();
                                    }
                                  );
                                }
                              }
                            }
                            //  this.deleteConfirmModalToggle
                          }
                        >
                          <img
                            src={deleteIcons}
                            alt="image loading"
                            className="orderDelete"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} sm={8} md={8} className="mb-15 d-grid">
                      <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Particular</h6>
                        <p>Order Details</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Weight(kg):</h6>
                        <div className="innerOrderContentDiv">
                          <div className="d-flex align-items-center justify-content-end orderColInnerDivStyle">
                            {/* <p>100</p> */}
                            <input
                              type="number"
                              value={ele.quantity ? ele.quantity : ""}
                              disabled={
                                this.state.isEditMode === "false"
                                  ? true
                                  : ele["weight" + i]
                              }
                              onChange={(e) => {
                                this.orderUpdate(i, "quantity", e.target.value);
                              }}
                            />
                            <img
                              src={vector}
                              alt="image loading"
                              onClick={(e) => {
                                this.editPrice(i, "weight");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Price (Rs):</h6>
                        <div className="innerOrderContentDiv">
                          <div className="d-flex align-items-center justify-content-end orderColInnerDivStyle">
                            {/* <p>4500 Rs @{ele.rate}/kg</p> */}
                            <input
                              type="number"
                              value={ele.rate ? ele.rate : ""}
                              disabled={
                                this.state.isEditMode === "false"
                                  ? true
                                  : ele["rate" + i]
                              }
                              onChange={(e) => {
                                this.orderUpdate(i, "rate", e.target.value);
                              }}
                            />
                            <img
                              src={vector}
                              alt="image loading"
                              onClick={(e) => {
                                this.editPrice(i, "rate");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Delivery Date:</h6>
                        <p>{this.state.deliveryDate} </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between colOrder colLastOrder">
                        <h6>Product Total</h6>
                        <p>
                          {parseInt(ele.quantity) * parseInt(ele.rate) || 0} Rs
                        </p>
                      </div>
                    </Col>
                  </>
                ))}
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <div className="colordercostDiv">
                    <div className="d-flex justify-content-between align-items-center ">
                      <div>Packaging Cost :</div>
                      <p className="mb-0">
                        {this.state.packagingCost
                          ? this.state.packagingCost
                          : ""}{" "}
                        Rs
                      </p>
                    </div>
                    <p className="commision">
                      (@ {this.percentageCommission("PackagingCost")} %)
                    </p>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15 ">
                  <div className="colordercostDiv">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>Transport Cost :</div>
                      <p className="mb-0">
                        {this.state.transportCost
                          ? this.state.transportCost
                          : ""}{" "}
                        Rs
                      </p>
                    </div>
                    <p className="commision">
                      (@ Rs {this.percentageCommission("TransportCost")} per km)
                    </p>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15 ">
                  <div className="colordercostDiv">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>Freshr Commission :</div>
                      <p className="mb-0">
                        {this.state.commission ? this.state.commission : ""} Rs
                      </p>
                    </div>
                    <p className="commision">
                      (@ {this.percentageCommission("Commission")} %)
                    </p>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15">
                  <div className="colordercostDiv amountDiv">
                    <div className="d-flex justify-content-between align-items-center ">
                      <div>Amount Paid :</div>
                      <p className="mb-0">
                        {this.props?.orderViewData?.singleOrder?.amountPaid ??
                          ""}{" "}
                        Rs
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15 ">
                  <div className="colordercostDiv amountDiv">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>Amount Refund : </div>
                      <p className="mb-0">
                        {this.props?.orderViewData?.singleOrder?.amountRefund ??
                          ""}{" "}
                        Rs
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15 ">
                  <div className="colordercostDiv amountDiv">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>Net Difference :</div>
                      <p className="mb-0">
                        {this.props?.orderViewData?.singleOrder
                          ?.netDifference ?? ""}{" "}
                        Rs
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={4} md={4} className="mb-15 ">
                  <div className="colordercostDiv amountDiv">
                    <div className="d-flex  justify-content-between align-items-center">
                      <div>Discount :</div>
                      <p className="mb-0">
                        {this.props?.orderViewData?.singleOrder?.discount ?? ""}{" "}
                        Rs
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={10} sm={10} md={10} className="mb-15 ">
                  <div className="d-flex  justify-content-between align-items-center colcostLastOrder">
                    <div>Total</div>
                    <p className="mb-0">
                      {this.state.totalCost ? this.state.totalCost : ""} Rs
                    </p>
                  </div>
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  className="mb-15"
                  style={{ paddingLeft: "8px" }}
                >
                  <button
                    className="btn lightBtn discountOrderButton"
                    disabled={
                      this.state.isEditMode === "false"
                        ? true
                        : !(
                          orderStatus === "Placed" ||
                          orderStatus === "Updated"
                        )
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showDiscountModal: true,
                      });
                    }}
                  >
                    Discount
                  </button>
                </Col>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-start">
                    <button
                      className="btn lightBtn"
                      disabled={
                        this.state.isEditMode === "false"
                          ? true
                          : !(
                            this.state.netDifference > 0 &&
                            orderStatus === "Finalized"
                          )
                      }
                      onClick={(e) => {
                        this.paymentLink(e);
                      }}
                    >
                      Issue payment link
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn lightBtn"
                      disabled={
                        this.state.isEditMode === "false"
                          ? true
                          : !(
                            this.state.netDifference < 0 &&
                            orderStatus === "Finalized"
                          )
                      }
                      onClick={(e) => {
                        this.paymentRefund(e);
                      }}
                    >
                      Initiate Refund
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn lightBtn"
                      disabled={
                        this.state.isEditMode === "false"
                          ? true
                          : !(
                            orderStatus === "Placed" ||
                            orderStatus === "Updated"
                          )
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          orderCancelPopUp: true,
                        });
                      }}
                    >
                      Cancel
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn lightBtn"
                      disabled={
                        this.state.isEditMode === "false"
                          ? true
                          : !(
                            orderStatus === "Finalized" &&
                            this.state.netDifference === 0
                          )
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showModal: true,
                        });
                      }}
                    >
                      Proceed to Transport
                    </button>
                  </div>
                  <div>
                    <div className="d-flex align-items-center justify-content-start">
                      <button
                        className="btn darkBtn btn-right"
                        disabled={
                          this.state.isEditMode === "false"
                            ? true
                            : !(
                              orderStatus === "Placed" ||
                              orderStatus === "Updated"
                            )
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.updateOrderRequest();
                        }}
                      >
                        Update
                      </button>
                      <button
                        className="btn darkBtn"
                        disabled={
                          this.state.isEditMode === "false"
                            ? true
                            : !(
                              orderStatus === "Placed" ||
                              orderStatus === "Updated"
                            )
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.cancelOrder("Finalised");
                        }}
                      >
                        Finalise
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <GenerateQr data={this.state.id} />
                </div>
              </Row>
            </Form>
          </div >
        </div >
      </div >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orderViewData: state.rfo.orderView,
  };
};
export default connect(mapStateToProps, {
  orderCancel,
  fetchOrderViewData,
  orderUpdate,
  addTransport,
  issuePaymentLink,
  intiateRefund,
  resendPayment,
  orderDiscount,
})(Orderdetails);
