import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { categoryViewData, categoryEditData } from "../../../store/actions";
class SkuInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      id: "",
      trackNumber: 0,
      categoryName: "",
      categoryRating: "",
    };
  }

  componentDidMount() {
    this.categoryViewDataEdit();
  }
  categoryViewDataEdit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
    });
    var queryParams = this.props.computedMatch.params.catid;
    if (queryParams) {
      this.props.categoryViewData(queryParams, (res) => {
        this.setState({
          categoryRating: res.categoryRating,
          id: res._id,
          categoryName: res.categoryName,
        });
      });
    }
  };
  categoryEditData = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("categoryName", this.state.categoryName);
    formData.append("categoryRating", this.state.categoryRating);
    let payload = formData;
    this.props.categoryEditData(
      this.props.computedMatch.params.catid,
      payload,
      (res) => {
        if (res && res.message === "Category has been successfully updated") {
          NotificationManager.success(res.message, "Category Updated!!");
          this.props.history.push("/Category");
        } else {
          NotificationManager.error(res.message, "Error");
        }
      }
    );
  };
  componentDidUpdate() {
    if (
      this.props.categoryData._id !== this.state.id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.categoryViewDataEdit();
      });
    }
  }
  render() {
    return (
      <div>
        <div className="pd-15 bg-white borderedDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Category Information</h4>
            <div>&nbsp;</div>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="sku">Category Name</Label>
                        <Input
                          id="sku"
                          name="sku"
                          placeholder="#4 of 10 in ROHU"
                          type="text"
                          value={this.state.categoryName}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.setState({
                              categoryName: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="sku">Category Rating</Label>
                        <Input
                          id="sku"
                          name="sku"
                          placeholder="#4 of 10 in ROHU"
                          type="text"
                          value={this.state.categoryRating}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                          onChange={(e) =>
                            this.setState({
                              categoryRating: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="sku">Category Image</Label>
                        <Input
                          id="sku"
                          name="sku"
                          placeholder="#4 of 10 in ROHU"
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          multiple
                          onChange={(e) => {
                            if (e.target && e.target.files[0]) {
                              this.setState({
                                categoryImage: e.target.files[0],
                              });
                            }
                          }}
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <div className="d-flex align-items-center justify-content-between">
                  <button
                      className="btn lightBtn"
                      onClick={() => this.props.history.push("/Category")}
                    >
                      Back
                    </button>
                    <div>
                      <button
                        className="btn darkBtn"
                        onClick={(e) => this.categoryEditData(e)}
                        disabled={
                          this.state.isEditMode === "false" ? true : false
                        }
                      >
                        Save
                      </button>
                    </div>
                   
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    categoryData: state.rfs.categoryView,
  };
};
export default connect(mapStateToProps, {
  categoryViewData,
  categoryEditData,
})(SkuInformation);
