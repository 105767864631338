import React, { Component } from "react";
import "./brain.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { appRoutePages, publicPages } from "./routes/";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/verticalLayout/";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import NonAuthLayout from "./components/NonAuthLayout";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import store from "./store";
import { Provider } from "react-redux";

class App extends Component {
  constructor(props) {
    super(props);
  }
  getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };
  render() {
    const Layout = this.getLayout();

    return (
      // <div className="App">
      //    <Header/>
      //    <SubHeader/>
      //    {/* <Home/> */}
      //    {/* <Products/> */}
      //    <Productdetails/>
      //    <Footer/>
      // </div>
      <>
        <React.Fragment>
          <Provider store={store}>
            <Router>
              <Switch>
                {publicPages.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                  />
                ))}
                {appRoutePages.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}
              </Switch>
            </Router>
          </Provider>
        </React.Fragment>
        <NotificationContainer />
      </>
    );
  }
}

export default App;
