import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label } from "reactstrap";
import { connect } from "react-redux";
import {
  getSosRequestData,
  UpdateSosRequestData,
  buyerSearch,
} from "../../../store/actions";
import Pagination from "@material-ui/lab/Pagination";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { NotificationManager } from "react-notifications";

class SosRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sosId: "",
      buyer: "",
      SosData: [],
      pagiCount: "",
      pagiSelectedCount: 0,
      showValue: 10,
      sort: "",
      resolveId: "",
      deleteConfirmModal: false,
      sosQuery: "",
    };
  }
  fetchSosData = () => {
    let DataToSent = {
      offset: this.state.pagiSelectedCount * this.state.showValue,
      limit: this.state.showValue,
      sosId: this.state.sosId,
      buyer: this.state.buyer,
      sort: this.state.sort,
    };
    this.props.getSosRequestData(DataToSent, (data) => {
      this.setState({ SosData: data.value });
      this.updatePagiCount();
    });
  };
  componentDidMount() {
    this.fetchSosData();
  }
  updatePagiCount = () => {
    this.setState({
      pagiCount: Math.ceil(
        this.state.SosData?.length / parseInt(this.state.showValue)
      ),
    });
  };
  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
      sosQuery: "",
    });
  };

  render() {
    var data = {};
    data = {
      columns: [
        {
          label: <span>Sos Id</span>,
          field: "sosid",
          sort: "asc",
          width: 200,
        },
        {
          label: <span>Order Id</span>,
          field: "orderid",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Buyer</span>,
          field: "buyer",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Contact</span>,
          field: "contact",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Sos Type</span>,
          field: "sosType",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Query</span>,
          field: "query",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Status</span>,
          field: "status",
          sort: "asc",
          width: 300,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.state.SosData?.docs?.map((el) => {
      data.rows.push({
        sosid: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.sosId ? el.sosId : ""}</span>
          </div>
        ),
        orderid: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.orderId ? el.orderId.orderId : ""}</span>
          </div>
        ),
        buyer: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.userId ? el.userId.fullName : ""}</span>
          </div>
        ),
        contact: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.userId ? el.userId.phone : ""}</span>
          </div>
        ),
        sosType: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.sosType ? el.sosType : ""}</span>
          </div>
        ),
        query: (
          <div className="d-flex align-itemms-center">
            &nbsp;
            <Tippy
              content={el.query ? el.query : ""}
              disabled={el.query ? false : true}
            >
              <span className="queryToolTip">{el.query ? el.query : ""}</span>
            </Tippy>
          </div>
        ),
        status: (
          <div className="d-flex align-itemms-center">
            &nbsp;<span>{el.status ? el.status : ""}</span>
          </div>
        ),
        actions: (
          <div className="d-flex align-items-center tableBtns ">
            <Button
              onClick={
                () =>
                  this.setState({ resolveId: el._id }, () => {
                    this.deleteConfirmModalToggle();
                  })
                // this.props.deleteSosRequestData({ id: el._id }, (data) => {
                //   this.fetchSosData();
                // })
              }
              className={
                `btn lightBtn` + el.status !== "Pending"
                  ? "markResolvedBtn"
                  : ""
              }
              disabled={el.status === "Pending" ? false : true}
            >
              Mark As Resolved
            </Button>
          </div>
        ),
      });
    });

    let debounceThis;
    const debouncing = (e, stateName) => {
      let obj1 = {};
      obj1[stateName] = e.target.value.trim();
      if (debounceThis) {
        clearTimeout(debounceThis);
        debounceThis = null;
      }
      debounceThis = setTimeout(() => {
        this.setState(obj1, () => this.fetchSosData());
      }, 1000);
    };

    return (
      <div className="dashInnerMainDiv custCreationDivSec sosDiv">
        <div className="sosModalInnerDiv">
          <Modal
            isOpen={this.state.deleteConfirmModal}
            toggle={this.deleteConfirmModalToggle}
            className="sosModalQuery"
            size="md"
          >
            <ModalHeader toggle={this.deleteConfirmModalToggle}>
              Add Comment
            </ModalHeader>
            <ModalBody>
              <textarea
                id="w3review"
                name="w3review"
                rows="7"
                cols="50"
                style={{ resize: "none", backgroundColor: "#F9F9F9" }}
                placeholder="Type"
                value={this.state.sosQuery}
                onChange={(e) => {
                  this.setState({ sosQuery: e.target.value });
                }}
              ></textarea>
              <br />
              <Button
                color="secondary sosModalDownDiv"
                onClick={() => {
                  // this.onDeleteCustomerRequest(this.state.resolveId);
                  let payload = {
                    status: "Resolved",
                    comment: this.state.sosQuery ? this.state.sosQuery : "",
                  };
                  this.props.UpdateSosRequestData(
                    this.state.resolveId,
                    payload,
                    (res) => {
                      if (
                        res &&
                        res.message === "Sos has been updated successfully"
                      ) {
                        NotificationManager.success(
                          res.message,
                          "SOS Updated!!"
                        );
                      } else {
                        NotificationManager.error(res.message, "Error");
                      }
                      this.fetchSosData();
                    }
                  );
                  this.deleteConfirmModalToggle();
                }}
                disabled={this.state.sosQuery.length > 0 ? false : true}
              >
                Submit
              </Button>{" "}
            </ModalBody>
          </Modal>
        </div>
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>SOS Requests List</h4>
            <div>
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv sosIdSearch">
                  <Input
                    name="search"
                    placeholder="SOS ID"
                    type="text"
                    className="mb-0"
                    onChange={(e) => debouncing(e, "sosId")}
                    autoComplete="off"
                  />
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                      stroke="#5E5E5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </FormGroup>
                <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv buyerIdSearch">
                  <Input
                    name="search"
                    placeholder="Buyer"
                    type="text"
                    className="mb-0"
                    onChange={(e) => debouncing(e, "buyer")}
                    autoComplete="off"
                  />
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                      stroke="#5E5E5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv">
                  <Label>Sort by</Label>&nbsp;&nbsp;
                  <Input
                    onChange={(e) =>
                      this.setState({ sort: e.target.value }, () =>
                        this.fetchSosData()
                      )
                    }
                    name="select"
                    type="select"
                  >
                    <option value="sosId desc">Sos Id Descending</option>
                    <option value="sosId asc">Sos Id Ascending</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label>Show</Label>&nbsp;&nbsp;
                  <Input
                    onChange={(e) => {
                      this.setState({ pagiSelectedCount: 0 });
                      this.setState(
                        { showValue: parseInt(e.target.value) },
                        () => {
                          this.fetchSosData();
                        }
                      );
                    }}
                    name="select"
                    type="select"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
            displayEntries={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              {this.state.SosData?.docs?.length > 0
                ? this.state.pagiSelectedCount * this.state.showValue + 1
                : 0}{" "}
              to{" "}
              {(this.state.pagiSelectedCount + 1) * this.state.showValue <=
              this.state.SosData?.length
                ? (this.state.pagiSelectedCount + 1) * this.state.showValue
                : this.state.SosData?.length}{" "}
              entries of {this.state.SosData?.length}
            </p>
          </div>
          <div className="custPaginationDiv noRightBorderPagination">
            <Pagination
              count={this.state.pagiCount}
              page={this.state.pagiSelectedCount + 1}
              shape="rounded"
              color="primary"
              onChange={(e, val) => {
                this.setState({ pagiSelectedCount: val - 1 }, () => {
                  this.fetchSosData();
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  // SosData: state.rfso.SosData,
  // SosDeleteRes: state.rfso.sosDelete
});
export default connect(mapStateToProps, {
  getSosRequestData,
  UpdateSosRequestData,
  buyerSearch,
})(SosRequests);
