import React, { Component } from "react";
import MenuPic from "../../images/profile.png";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import ProfileImage from "../../images/myprofileimage.png";
import LogoutImage from "../../images/logoutimage.png";
import Neworder from "../../images/newordericon.png";
import Completedorder from "../../images/ordercompletedicon.png";
import { connect } from "react-redux";
import { logoutUser } from "../../store/actions";

class MainHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      toggleDiv: false,
      logoutDiv: false,
    };
  }

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
      logoutDiv: false,
    });
  };

  toggleLogout = () => {
    this.setState({
      logoutDiv: !this.state.logoutDiv,
      toggleDiv: false,
    });
  };

  // toggleLogoutDiv=()=>{
  //     this.setState({
  //         logoutDiv:!this.state.logoutDiv
  //     })
  // }

  logoutUser = () => {
    console.log("logged out");
    this.props.logoutUser();
  };

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  render() {
    let user = JSON.parse(localStorage.getItem("authUser"));
    return (
      <div className="d-flex align-items-center justify-content-between rightSideNavbar">
        <div className="d-flex align-items-center rightSideNavbarLeftDiv">
          <h4>{this.props.pageTitle}</h4>
        </div>
        <div className="d-flex align-items-center rightSideNavbarRightDiv">
          {/* <div className="pos-rel">
            <div
              className="svgStyleDiv"
              id="UncontrolledTooltipExample"
              onClick={() => this.toggleFilter()}
            >
              <svg
                width="12"
                height="17"
                viewBox="0 0 12 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.71008 1.54095L2.79187 8.45892L9.71008 15.3769"
                  stroke="#980033"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
              <UncontrolledTooltip
                placement="left"
                target="UncontrolledTooltipExample"
              >
                New Requests
              </UncontrolledTooltip>
            </div>
            <div
              className={`notificationDropdown ${
                this.state.toggleDiv ? "displayBlock" : "displayNone"
              }`}
            >
              <div class="arrow-up"></div>
              <ul className="mb-0">
                <a href="/profile">
                  <li>
                    <div className="d-flex align-items-start innerNotificationDiv">
                      <img src={Neworder} alt="neworderIcon" />
                      <div>
                        <p className="d-flex align-items-center justify-content-between">
                          New order recieved <span>02/09/2021</span>
                        </p>
                        <p>
                          Order from Anand Mhatva recieved for Katla with 20
                          quanity.
                        </p>
                      </div>
                    </div>
                  </li>
                </a>
                <a href="/profile">
                  <li>
                    <div className="d-flex align-items-start innerNotificationDiv">
                      <img src={Completedorder} alt="completedorderIcon" />
                      <div>
                        <p className="d-flex align-items-center justify-content-between">
                          Order Completed<span>02/09/2021</span>
                        </p>
                        <p>
                          Order from Anand Mhatva recieved for Katla with 20
                          quanity.
                        </p>
                      </div>
                    </div>
                  </li>
                </a>
                <a href="/profile">
                  <li>
                    <div className="d-flex align-items-start innerNotificationDiv">
                      <img src={Neworder} alt="neworderIcon" />
                      <div>
                        <p className="d-flex align-items-center justify-content-between">
                          New order recieved <span>02/09/2021</span>
                        </p>
                        <p>
                          Order from Anand Mhatva recieved for Katla with 20
                          quanity.
                        </p>
                      </div>
                    </div>
                  </li>
                </a>
              </ul>
            </div>
          </div> */}
          <div className="d-flex align-items-center menuProfileStyleDiv">
            <img
              style={{ height: "50px", width: "50px", overflow: "hidden" }}
              src={MenuPic}
              alt="menuProfilePicture"
            />
            <p>
              <span>Welcome</span>,<br />
              {user.fullName}
            </p>
          </div>
          <div className="pos-rel">
            <div className="svgStyleDiv" onClick={() => this.toggleLogout()}>
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2.00391L8.91797 8.92212L15.8359 2.00391"
                  stroke="#212529"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div
              className={`logoutDropdown ${
                this.state.logoutDiv ? "displayBlock" : "displayNone"
              }`}
            >
              <div class="arrow-up"></div>
              <ul className="mb-0">
                <Link to={"/profile"}>
                  <li>
                    <img src={ProfileImage} alt="profileImageIcon" />
                    <span>My Profile</span>
                  </li>
                </Link>
                <Link onClick={this.logoutUser} to={"/login"}>
                  <li>
                    <img src={LogoutImage} alt="logoutImageIcon" />
                    <span>Logout</span>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    UserData: state.rfu.loggedInUser,
  };
};

export default connect(mapStateToProps, { logoutUser })(MainHeader);
