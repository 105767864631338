import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label } from "reactstrap";
import TableProfilePic from "../../../images/tableprofilepic.png";
import userprofile from "../../../images/userprofile.png";
import { connect } from "react-redux";
import {
  // customerSearchdata,
  sortCustomerData,
  customerDeleteData,
} from "../../../store/actions";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { debounce, throttle } from "lodash";
class Customercreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCustomer: "",
      limit: 10,
      currentPage: 1,
      status: "",
      source: "",
      customerToDeleteId: "",
      deleteConfirmModal: false,
      userName: "",
      sort: "",
    };
  }
  onDeleteCustomerRequest(id) {
    this.props.customerDeleteData(id, (res) => {
      if (res) {
        if (res && res.message === "Customer has been deleted successfully") {
          NotificationManager.success(res.message, "Delete Successful");
        } else {
          NotificationManager.error(res.message, "Error");
        }
        if (res) {
          this.getAllData((this.state.currentPage - 1) * this.state.limit);
        }
      }
    });
  }
  componentDidMount() {
    this.getAllData();
  }

  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };

  getAllData = (offset = 0) => {
    this.props.sortCustomerData(
      offset,
      this.state.limit,
      this.state.searchCustomer,
      this.state.status,
      this.state.source,
      this.state.userName,
      this.state.sort
    );
  };
  delayedHandleChange = debounce(() => {
    this.getAllData();
  }, 1000);
  getCustomerSearch(name, val) {
    this.setState({
      [`${name}`]: val,
      currentPage: 1,
    });
    this.delayedHandleChange();
  }
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.getAllData(offset);
  };
  render() {
    const totPages = Math.ceil(
      this.props.customerdata.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>Name</span>,
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Contact</span>,
          field: "contact",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Member Since</span>,
          field: "member",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>User Type</span>,
          field: "type",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Source of Interest</span>,
          field: "source",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Status</span>,
          field: "status",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props.customerdata.docs &&
      this.props.customerdata.docs.length > 0
    ) {
      this.props.customerdata.docs.forEach((ele) => {
        data.rows.push({
          name: (
            <div className="d-flex align-items-center">
              <img src={userprofile}></img>&nbsp;&nbsp;&nbsp;
              <p className="mb-0">{ele.fullName}</p>
            </div>
          ),
          contact: ele.phone,
          member: (
            <div>
              {moment(ele.memberSince).format("DD/MM/YYYY")}
              <br />
              {moment(ele.memberSince).format("LT")}
            </div>
          ),
          type: ele.userType,
          source: ele.userAccountSource,
          status: ele.userAccountStatus,
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link to={`/Customerdetails/${ele._id}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/Customerdetails/${ele._id}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              <Button
                className="btn lightBtn"
                onClick={() => {
                  this.setState({ customerToDeleteId: ele._id }, () => {
                    this.deleteConfirmModalToggle();
                  });
                }}
              >
                Delete
              </Button>
            </div>
          ),
        });
      });
    }
    return (
      <div className="dashInnerMainDiv noBorderedDiv custCreationDivSec">
        <Modal
          isOpen={this.state.deleteConfirmModal}
          toggle={this.deleteConfirmModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.deleteConfirmModalToggle}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want to delete the customer?</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                this.onDeleteCustomerRequest(this.state.customerToDeleteId);
                this.deleteConfirmModalToggle();
              }}
            >
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.deleteConfirmModalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Customer List</h4>
            <div>
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                  <Input
                    name="search"
                    placeholder="Search By Phone"
                    type="text"
                    className="mb-0 searchPhoneInput"
                    value={this.state.customerSearch}
                    onChange={(e) => {
                      this.getCustomerSearch(
                        "searchCustomer",
                        e.target.value.trim()
                      );
                    }}
                    autoComplete="off"
                  />
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                      stroke="#5E5E5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </FormGroup>
                <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                  <div>
                    <Input
                      name="search"
                      placeholder="Search By Name"
                      type="text"
                      className="mb-0 searchPhoneInput"
                      value={this.state.customerSearch}
                      onChange={(e) => {
                        this.getCustomerSearch(
                          "userName",
                          e.target.value.trim()
                        );
                      }}
                      autoComplete="off"
                    />
                  </div>

                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                      stroke="#5E5E5E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Source</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      let value;
                      if (e.target.value === "Web") value = "web";
                      else if (e.target.value === "Mobile") value = "mobile";
                      else if (e.target.value === "AddedByAdmin")
                        value = "AddedByAdmin";
                      else if (e.target.value === "All") value = "";
                      this.setState(
                        {
                          source: value,
                          currentPage: 1,
                        },
                        () => {
                          this.getAllData();
                        }
                      );
                    }}
                  >
                    <option>All</option>
                    <option>Web</option>
                    <option>Mobile</option>
                    <option>AddedByAdmin</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv debounceInput customerDowncontent">
                  <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      this.setState(
                        {
                          sort: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.getAllData();
                        }
                      );
                    }}
                  >
                    <option>Latest</option>
                    <option>Oldest</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Status</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      let value;
                      if (e.target.value === "New") value = "New";
                      else if (e.target.value === "Awaiting User Confirmation")
                        value = "Awaiting User Confirmation";
                      else if (e.target.value === "KYC Verified")
                        value = "KYC Verified";
                      else if (e.target.value === "All") value = "";
                      this.setState(
                        {
                          status: value,
                          currentPage: 1,
                        },
                        () => {
                          this.getAllData();
                        }
                      );
                    }}
                  >
                    <option>All</option>
                    <option>New</option>
                    <option>Awaiting User Confirmation</option>
                    <option>KYC Verified</option>
                  </Input>
                </FormGroup>
                <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      this.setState(
                        {
                          limit: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.getAllData();
                        }
                      );
                      // setTimeout(() => {
                      //   this.getAllData();
                      // }, 500);
                    }}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <div className="dataTableStyles">
          <MDBDataTable
            className="text-center text-capitalize"
            responsive
            hover
            data={data}
            paging={false}
            searching={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props.customerdata?.length != 0
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props.customerdata.docs?.length
                  ? this.props.customerdata.docs.length
                  : "")}{" "}
              {} entries of {this.props.customerdata?.length}
            </p>
          </div>
          <div className="custPaginationDiv noRightBorderPagination">
            <Pagination
              shape="rounded"
              count={totPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerdata: state.rfc.customer,
  };
};
export default connect(mapStateToProps, {
  sortCustomerData,
  customerDeleteData,
  // customerSearchdata
})(Customercreation);
