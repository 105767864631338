import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import {
  fetchSellerData,
  fetchProductData,
  fetchSkusById,
  AddProductApi,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import SelectComponent from "./SelectComponent";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import userprofile from "../../../images/userprofile.png";
// import "../../../images/bigheadcarpdesktop1.png";

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "decscription",
      category: "",
      sku: "",
      size: "",
      totalqty: "",
      moq: "",
      rate: "",
      pickupPin: "",
      // availableDeliveryDate: [new Date(""), new Date("")],
      deliveryDate: "",
      rating: "",
      stateRanking: "",
      skuRanking: "",
      seeding: "",
      netting: "",
      harvest: "",
      remark: "",
      highlights: "",
      sellerOptions: [],
      seller: "",
      supplierRating: "",
      productImg: "",
      productImageBlob: [],
    };
  }

  componentDidMount = () => {
    let sellerData = [];
    this.props.fetchSellerData((res) => {
      res.sellerList.forEach((item) => {
        sellerData.push({ label: item.fullName, value: item._id });
      });
      this.setState({ sellerOptions: sellerData });
    });
    this.props.fetchProductData();
  };
  // componentDidUpdate = () => {};
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  sendTheData = (e) => {
    e.preventDefault();
    let valueOfSku = document.getElementById("exampleSelect_sku").value;
    //Here we gonna send the data
    // let deliveryDate = {
    //   from: moment(this.state.availableDeliveryDate[0]).format("YYYY-MM-DD"),
    //   to: moment(this.state.availableDeliveryDate[1]).format("YYYY-MM-DD"),
    // };
    let deliveryDate =  moment(this.state.deliveryDate).format("YYYY-MM-DD");
    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("category", this.state.category);
    formData.append("sku", valueOfSku);
    formData.append("size", this.state.size);
    formData.append("availableQuantity", this.state.totalqty);
    formData.append("totalqty", this.state.totalqty);
    formData.append("moq", this.state.moq);
    formData.append("rate", this.state.rate);
    formData.append("pinCode", this.state.pickupPin);
    // formData.append("availableDeliveryDate", JSON.stringify(deliveryDate));
    formData.append("deliveryDate", JSON.stringify(deliveryDate));
    formData.append("rating", this.state.rating);
    formData.append("stateRanking", this.state.stateRanking);
    formData.append("skuRanking", this.state.skuRanking);
    formData.append("remark", this.state.remark);
    formData.append("highlights", this.state.highlights);
    formData.append("seller", this.state.seller);
    formData.append("supplierrating", this.state.supplierRating);
    let allFiles = Object.values(this.state.productImg);
    for (let x of allFiles) {
      formData.append("images", x);
    }
    this.props.AddProductApi(formData, (res) => {
      if (res.message === "Product has been created successfully") {
        NotificationManager.success(res.message, "Product Created");
        this.props.history.push("/product");
      } else {
        NotificationManager.error(res.message, "Error");
      }
    });

    // if (this.props?.dataUpdationInfo?.message) {
    //   alert(this.props.dataUpdationInfo.message);
    // }
    // var objToSent = {
    //   name: this.state.name,
    //   category: this.state.category,
    //   sku: valueOfSku,
    //   size: this.state.size,
    //   availableQuantity: this.state.totalqty,
    //   moq: this.state.moq,
    //   rate: this.state.rate,
    //   pinCode: this.state.pickupPin,
    //   availableDeliveryDate:
    // {
    // from: moment(availableDeliveryDate[0]).format("YYYY-MM-DD"),
    // to:
    // moment(availableDeliveryDate[1]).format("YYYY-MM-DD"),
    // }
    // rating: this.state.rating,
    // stateRanking: this.state.stateRanking,
    // skuRanking: this.state.skuRanking,
    // seeding: this.state.seeding,
    // netting: this.state.netting,
    // harvest: this.state.harvest,
    // remark: this.state.remark,
    // highlights: this.state.highlights,
    // seller: this.state.seller,
    // supplierrating: this.state.supplierRating,
    // images: this.state.img,
    // };
  };
  render() {
    const getSelectValueCategory = (e) => {
      this.props.fetchSkusById(e?.target?.value);
    };
    return (
      <div className="mainParentSectionDiv">
        <div className="pd-15 bg-white borderedDiv">
          <br />
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>Add Basic Details</h4>
            <div>&nbsp;</div>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="exampleSelect">
                          Assign Product category
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        &nbsp;&nbsp;
                        <Input
                          id="exampleSelect"
                          name="Select category..."
                          type="select"
                          onChange={(e) => {
                            getSelectValueCategory(e);
                            this.onChange("category", e.target.value);
                          }}
                        >
                          <option value=""></option>
                          {this.props?.productData.docs?.map((data, index) => {
                            return (
                              <option key={index} value={data?._id}>
                                {data?.categoryName}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="exampleSelect">
                          Assign Product type / SKU
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        &nbsp;&nbsp;
                        <Input
                          id="exampleSelect_sku"
                          name="Select type..."
                          type="select"
                          onChange={(e) => {
                            this.onChange("sku", e.target.value);
                          }}
                        >
                          <option selected={true} value=""></option>
                          {this.props?.skusDataByIds?.skus?.map((data) => {
                            return (
                              <option value={data?._id}>{data?.skuName}</option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="size">Size (in Kgs/Count)</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          id="size"
                          name="size"
                          placeholder="Type..."
                          type="number"
                          onChange={(e) => {
                            this.onChange("size", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="quantity">
                          Total quantity available (Kgs)
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          id="quantity"
                          name="quantity"
                          placeholder="Type..."
                          type="number"
                          onChange={(e) => {
                            this.onChange("totalqty", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="moq">MOQ (in Kgs)</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          id="moq"
                          name="moq"
                          placeholder="Type..."
                          type="number"
                          onChange={(e) => {
                            this.onChange("moq", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="price">Price (per Kgs)</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          id="price"
                          name="price"
                          placeholder="Type..."
                          type="number"
                          onChange={(e) => {
                            this.onChange("rate", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="pin">Pick up PIN</Label>
                        <span style={{ color: "red" }}>*</span>
                        <Input
                          id="pin"
                          name="pin"
                          placeholder="Type...."
                          type="text"
                          maxlength="6"
                          value={this.state.pickupPin}
                          onChange={(e) => {
                            let regularExpressionNumber = /^[0-9]*$/;
                            if (regularExpressionNumber.test(e.target.value)) {
                              this.onChange("pickupPin", e.target.value);
                            } else {
                              this.onChange("pickupPin", "");
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup className="dateRangePickerDiv">
                        <Label for="delieverydate">
                          Available for delivery
                        </Label>
                        <span style={{ color: "red" }}>*</span>
                        {/* <DateRangePickerComponent
                          placeholder="Enter Date Range"
                          onChange={(val) => {
                            this.onChange(
                              "availableDeliveryDate",
                              val.nativeEvent.text
                            );
                          }}
                          value={this.state.availableDeliveryDate}
                          // strictMode={true}
                          // maxDays={30}
                          format="dd-MM-yyyy"
                        ></DateRangePickerComponent> */}
                         <Input
                      id="date"
                      name="date"
                      type="date"
                      value={this.state.deliveryDate}
                      onChange={(e) => {
                        console.log(e.target.value,"e.target.value");
                        this.setState({
                          deliveryDate: 
                            e.target.value,
                        });
                      }}
                      disabled={false}
                    />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="pin">Add Image</Label>
                        <Input
                          id="image"
                          name="image"
                          type="file"
                          multiple
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => {
                            this.onChange("productImg", e.target.files);
                            const productImageBase64 = [];
                            const allFiles = Object.values(e.target.files);
                            for (let ele of allFiles) {
                              productImageBase64.push(URL.createObjectURL(ele));
                            }
                            this.setState({
                              productImageBlob: productImageBase64,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Row>
                      {this.state.productImageBlob.map((ele) => {
                        return (
                          <Col xs={12} sm={2} md={2} className="mb-15">
                            <div className="productImageDivStyle">
                            <img src={ele} alt="productImage" />
                            {/* style={{ width: "60%" }} */}
                            </div>
                            {/* <img src={ele} style={{ width: "60%" }} /> */}
                          </Col>
                        );
                      })}
                    </Row>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          <br />
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <h4>List Under Seller</h4>
            <div>&nbsp;</div>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={8} md={8} className="mb-15">
                      <FormGroup>
                        <Label for="exampleSelect">Select seller</Label>
                        <span style={{ color: "red" }}>*</span>
                        &nbsp;&nbsp;
                        <SelectComponent
                          disabled={false}
                          options={this.state.sellerOptions}
                          onChange={(e) => {
                            this.onChange("seller", e.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="rating">Seller Rating</Label>
                        <Input
                          id="rating"
                          name="rating"
                          type="select"
                          value={this.state.supplierRating}
                          disabled={false}
                          onChange={(e) =>
                            this.onChange("supplierRating", e.target.value)
                          }
                        >
                          <option value=""></option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} className="pd-15">
              <div className="formDivCss">
                <Form>
                  <Row>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="exampleSelect">State ranking</Label>
                        &nbsp;&nbsp;
                        <Input
                          id="exampleSelect"
                          name="Select..."
                          type="text"
                          value={this.state.stateRanking}
                          onChange={(e) => {
                            let regularExpressionNumber = /^[0-9]*$/;
                            if (regularExpressionNumber.test(e.target.value)) {
                              this.onChange("stateRanking", e.target.value);
                            } else {
                              this.onChange("stateRanking", "");
                            }
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="mb-15">
                      <FormGroup>
                        <Label for="exampleSelect">SKU ranking</Label>
                        &nbsp;&nbsp;
                        <Input
                          id="exampleSelect"
                          name="Type..."
                          type="text"
                          value={this.state.skuRanking}
                          onChange={(e) => {
                            let regularExpressionNumber = /^[0-9]*$/;
                            if (regularExpressionNumber.test(e.target.value)) {
                              this.onChange("skuRanking", e.target.value);
                            } else {
                              this.onChange("skuRanking", "");
                            }
                            // this.onChange("skuRanking", e.target.value);
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    {/* <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="seeding">Seeding</Label>
                        <Input
                          id="seeding"
                          name="seeding"
                          placeholder="Select multiple dates..."
                          type="date"
                          onChange={(e) => {
                            this.setState({ seeding: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="netting">Netting</Label>
                        <Input
                          id="netting"
                          name="netting"
                          placeholder="Select multiple dates..."
                          type="date"
                          onChange={(e) => {
                            this.setState({ netting: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="harvest">Harvest</Label>
                        <Input
                          id="harvest"
                          name="harvest"
                          placeholder="Select multiple dates..."
                          type="date"
                          onChange={(e) => {
                            this.setState({ harvest: e.target.value });
                          }}
                        />
                      </FormGroup>
                    </Col> */}
                    <Col xs={12} sm={5} md={5} className="mb-15">
                      <FormGroup>
                        <Label for="remarks">Addition Remarks</Label>
                        <Input
                          id="remarks"
                          name="remarks"
                          placeholder="Type..."
                          type="text"
                          onChange={(e) => {
                            this.onChange("remark", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={5} md={5} className="mb-15">
                      <FormGroup>
                        <Label for="highlight">Highlights</Label>
                        {/* <span style={{ color: "red" }}>*</span> */}
                        <Input
                          id="highlight"
                          name="highlight"
                          placeholder="Type..."
                          type="text"
                          onChange={(e) => {
                            this.onChange("highlights", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={2} md={2} className="mb-15">
                      <div className="d-flex align-items-center h-100 pdt-15">
                        <button
                          onClick={() => this.props.history.push("/Dashboard")}
                          className="btn darkBtn"
                        >
                          Back
                        </button>
                      </div>
                    </Col>
                    <Col xs={12} sm={2} md={2} className="mb-15">
                      <div className="d-flex align-items-center h-100 pdt-15">
                        {/* <button onClick={() => sendTheData()} className="btn darkBtn">Submit</button> */}
                        <button
                          onClick={(e) => this.sendTheData(e)}
                          className="btn darkBtn"
                          disabled={
                            !(
                              this.state.category &&
                              this.state.sku &&
                              this.state.size &&
                              this.state.totalqty &&
                              this.state.moq &&
                              this.state.rate &&
                              this.state.pickupPin &&
                              this.state.pickupPin.length === 6 &&
                              this.state.deliveryDate &&
                              this.state.seller
                            )
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    sellerData: state.rfun.sellerData,
    productData: state.rfun.productCategoryData,
    skusDataByIds: state.rfun.skusDataById,
    dataUpdationInfo: state.rfun.productCreatedInfo,
  };
};

export default connect(mapStateToProps, {
  fetchSellerData,
  fetchProductData,
  fetchSkusById,
  AddProductApi,
})(AddProduct);
